export default {
    sidebar: {
        home: '首页',
        order_management: '订单管理',
        device_management: '设备管理',
        model_management: '型号管理',
        add_model: '添加型号',
        goods_management: '商品管理',
        goods_list: '商品列表',
        goods_classify: '商品分类',
        coupon_management: '优惠券',
        coupon: '优惠券',
        advert_management: '广告管理',
        advert_examine: '广告审核',
        home_advert: '首页广告',
        standby_advert: '待机广告',
        standbyGroup: '待机广告组',
        Subordinate_agents: '下级代理',
        site_management: '场地管理',
        user_list: '用户列表',
        commission_management: '佣金管理',
        agent_reviewe: '代理提现',
        staff: '运营人员',
        Feedback:'用户反馈',
        set:'设置',
        Lottery_List:'抽奖列表',
        Dealer_backend_system:'经销商后台系统',
        login_password:'登录密码',
        logout:'退出登录',
    },
    name:'姓名',
    account:'账号',
    login:'登录',
    Welcome_to_login:'欢迎登录',
    Dealer_backend:'经销商后台',
    new_password:'新密码',
    plz_password:'请输入密码',
    plz_newPassword:'请输入新密码',
    plz_name:'请输入姓名',
    plz_phone:'请输入手机号码',
    password_modify:'密码留空则不修改运营人员密码',
    password:'密码',
    cancel:'取 消',
    save:'保 存',
    save:'保 存',
    phone:'手机号码',
    date:'日期',
    to:'至',
    income:'收入',
    device:'设备',
    device_name:'设备名称',
    device_num:'设备总数',
    device_online:'在线设备',
    device_not_online:'离线设备',
    device_id:'设备ID',
    add_device:'添加设备',
    refund:'退款',
    Withdrawal:'提现',
    Venue:'所属场地',
    select:'选择',
    submit:'提 交',
    Commission_sharing_method:'分佣方式',
    commission:'手续费',
    commission1:'佣金',
    Retained:'留存',
    start_date:'开始日期',
    end_date:'结束日期',
    edit:'编辑',
    Replenishment_records:'补货记录',
    add_staff:'添加运营人员',
    edit_staff:'编辑运营人员',
    Please_enter_keywords_for_search:'请输入关键字搜索',
    delete:'删除',
    Default_this_month:'默认本月',
    number:'数量',
    detail:'明细',
    Replenishment_date:'补货时间',
    Revenue_statistics:'收入统计',
    statistics:'统计',
    Revenue_detail:'收入明细',
    type:'类型',
    describe:'描述',
    Equipment_in_operation:'运营中的设备',
    operate:'操作',
    Distribution_Equipment:'分配设备',
    Parameter_settings:'参数设置',
    Proportional_sharing:'比例分成',
    commission_rate:'佣金比例',
    Fixed_amount:'固定金额',
    sell:'售卖',
    Platform_home:'平台首页',
    today_order:'今日订单',
    month_order:'本月订单',
    order_all:'订单总数',
    net_income:"实收入",
    Subordinate_agents: '下级代理',
    AddAgent:'添加代理商',
    Name:'名称',
    PleaseEnterKeywords:'请输入关键字',
    Agent:'代理商',
    Level:'等级',
    NumberOfDevices:'设备数量',
    '抽成':'抽成',

}