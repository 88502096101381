<template>
  <div class="content-view" v-title :data-title="$t('Platform_home')">
    <div class="top-view">
      <!-- <span class="title">设备：</span>
      <el-select v-model="deviceid" placeholder="请选择" filterable clearable @change="deviceChange">
        <el-option v-for="item in devices" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select> -->
      <span class="title" style="margin-left: 20px;">{{ $t('date') }}：</span>
      <el-date-picker v-model="dateArray" type="daterange" :range-separator="$t('to')" :start-placeholder="$t('start_date')"
        :end-placeholder="$t('end_date')" value-format="yyyy-MM-dd" :picker-options="dateOptions" unlink-panels
        @change="dateChange"></el-date-picker>
    </div>
    <div class="device-view" v-if="deviceStats && orderStats">
      <div class="device-item" style="background-color:#409EFF;">
        <span class="num">{{ deviceStats.all }}</span>
        <span class="title">{{ $t('device_num') }}</span>
      </div>
      <div class="device-item" style="background-color:#67C23A;margin-left:20px;">
        <span class="num">{{ deviceStats.on }}</span>
        <span class="title">{{ $t('device_online') }}</span>
      </div>
      <div class="device-item" style="background-color:#bbb;margin-left:20px;"> 
        <span class="num">{{ deviceStats.off }}</span>
        <span class="title">{{ $t('device_not_online') }}</span>
      </div>
      <div class="device-item" style="background-color:#E6A23C;margin-left:20px;">
        <span class="num">{{ orderStats.today.n }}</span>
        <span class="title">{{ $t('today_order') }}</span>
      </div>
      <div class="device-item" style="background-color:#9370DB;margin-left:20px;">
        <span class="num">{{ orderStats.month.n }}</span>
        <span class="title">{{ $t('month_order') }}</span>
      </div>
      <div class="device-item" style="background-color:#F56C6C;margin-left:20px;">
        <span class="num">{{ orderStats.all.n }}</span>
        <span class="title">{{ $t('order_all') }}</span>
      </div>
    </div>
    <el-table :data="statsData" border style="margin-top:20px;" :header-cell-style="{ 'background-color': '#eef1f6' }"
      v-loading="loading">
      <el-table-column prop="day" :label="$t('date')" align="center"></el-table-column>

      <!-- <el-table-column prop="wx_income" label="微信收入" align="center"></el-table-column>
      <el-table-column prop="ali_income" label="支付宝收入" align="center"></el-table-column> -->
      <el-table-column :label="$t('income')" align="center">
        <template slot-scope="scope">
          <span style="font-weight:bolder;">{{ scope.row.income }}</span>
        </template>
      </el-table-column>
      
      <el-table-column :label="$t('refund')" align="center">
        <template slot-scope="scope">
          <span style="font-weight:bolder;">{{ scope.row.refund }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="wx_refund" label="微信退款" align="center"></el-table-column>
      <el-table-column prop="ali_refund" label="支付宝退款" align="center"></el-table-column> -->
      
      <el-table-column :label="$t('net_income')" align="center">
        <template slot-scope="scope">
          <span style="font-weight:bolder;">{{ scope.row.real_income }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="wx_receipt" label="微信实收" align="center"></el-table-column>
      <el-table-column prop="ali_receipt" label="支付宝实收" align="center"></el-table-column> -->
      <el-table-column label="提现" align="center">
        <template slot-scope="scope">
          <span style="font-weight:bolder;">{{ scope.row.withdraw }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-table
      :data="statsList"
      style="margin-top:20px;"
      :header-cell-style="{'background-color':'#eef1f6'}"
      v-loading="loading"
    >
      <el-table-column prop="date" label="日期" align="center"></el-table-column>
      <el-table-column prop="wx_income" label="微信收入" align="center"></el-table-column>
      <el-table-column prop="ali_income" label="支付宝收入" align="center"></el-table-column>
      <el-table-column label="总收入" align="center">
        <template slot-scope="scope">
          <span style="font-weight:bolder;">{{scope.row.income}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="wx_refund" label="微信退款" align="center"></el-table-column>
      <el-table-column prop="ali_refund" label="支付宝退款" align="center"></el-table-column>
      <el-table-column label="总退款" align="center">
        <template slot-scope="scope">
          <span style="font-weight:bolder;">{{scope.row.refund}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="wx_receipt" label="微信实收" align="center"></el-table-column>
      <el-table-column prop="ali_receipt" label="支付宝实收" align="center"></el-table-column>
      <el-table-column label="总实收" align="center">
        <template slot-scope="scope">
          <span style="font-weight:bolder;">{{scope.row.receipt}}</span>
        </template>
      </el-table-column>
    </el-table> -->
  </div>
</template>

<script>
export default {
  name: "stats",
  data() {
    return {
      devices: [],
      deviceid: null,
      dateArray: null,

      dateOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      deviceStats: null,
      orderStats: null,
      statsData: [],
      statsList: [],
      loading: false
    };
  },
  created() {
    // this.getHomepageDefault();
    this.getHomepageOrderStat();
  },
  methods: {
    getHomepageDefault() {
      let params = {
        op: "HomepageDefault"
      };
      this.$api.stats.getHomepageDefault(params).then(res => {
        if (res.data.status) {
          this.deviceStats = res.data.data.device_stat;
          this.orderStats = res.data.data.data;
        } else {
          this.$alert(res.data.data.msg, "提示");
        }
      });
    },
    getHomepageOrderStat() {
      // this.agent.visible = true;
      this.$get('/api/order/dataReport').then(res => {
        console.log("🚀 ~ this.$get ~ res:", res)
        this.statsData = res.list;
        this.agent.imei = row.imei;
      }).catch(error => {

      });
    },
    deviceChange() {
      this.getHomepageOrderStat();
    },
    // dateChange() {
    //   this.getHomepageOrderStat();
    // }
    dateChange(value) {
      if(value==null){
        this.getHomepageOrderStat();
      }
      var data = {
        start_date: value[0],
        end_date: value[1]
      }
      this.$get('/api/order/dataReport', data).then(res => {
        this.statsData = res.list
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.top-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    font-size: 14px;
  }
}

.device-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;

  .device-item {
    width: calc((100% - 100px) / 6);
    padding: 20px 0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;

    .num {
      font-size: 40px;
      font-weight: 500;
    }

    .title {
      font-size: 14px;
    }
  }
}
</style>