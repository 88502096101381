<template>
  <div class="container" style="background:#fff;">
    <div style="padding:20px;display:flex;">
      <!-- <el-button type="success" @click="createClick">添加设备</el-button> -->
      <el-button v-if="userInfo.superior_id == 0" type="success" @click="importEqui.visible = true">{{$t('导入设备')}}</el-button>
      <el-badge :value="low.total">
        <el-input v-model="listQuery.keyword" clearable :placeholder="$t('请输入关键字')" style="width:300px;margin-left:20px;">
          <el-button slot="append" @click="getList" icon="el-icon-search"></el-button>
        </el-input>

        <el-button type="warning" style="margin-left:20px;" @click="lowDeviceClick">{{$t('缺货设备')}}</el-button>
      </el-badge>
      <el-button type="danger" style="margin-left:20px;" @click="breakdownDeviceClick">{{$t('故障设备')}}</el-button>
      <el-button type="primary" @click="exportExcel">{{$t('导出补货单')}}</el-button>
    </div>
    <!-- <div style="background:#fff;"> -->
    <!-- <el-form :model="form" :inline="true" style="text-align:left;">
          <el-form-item label="设备:">
            <el-input v-model="form.keyword" placeholder="请输入关键字"></el-input>
          </el-form-item>
          <el-button type="" style="align-item:center;">
            查询<i class="el-icon-search"></i>
            </el-button>
        </el-form> -->
    <!-- </div> -->


    <el-table :data="data">
      <el-table-column label="#" type="index"></el-table-column>
      <!-- <el-table-column width="100">
        <template slot-scope="scope">
          <el-popover placement="right" trigger="hover">
            <img :src="scope.row.qrcode" width="150" />
            <i slot="reference" class="iconfont icon-erweima1"></i>
          </el-popover>
        </template>
</el-table-column> -->
      <el-table-column :label="$t('设备名称')" min-width="160">
        <template slot-scope="scope">
          <span>{{ scope.row?.name }}</span>
          <!-- <el-tooltip effect="dark" content="蓝牙" placement="right">
            <i class="iconfont icon-lanya" style="margin-left:10px;color:#1870d2"></i>
          </el-tooltip> -->
          <!-- <el-tooltip effect="dark" :content="scope.row?.status?.qoe <= 25?'电量过低':'电量充足'" placement="right" v-if="scope.row?.status?.qoe">
                <i :class="{'iconfont':true,'icon-Battery2':scope.row?.status?.qoe == 100,'icon-Battery1':scope.row?.status?.qoe < 25}"></i>
            </el-tooltip> -->
        </template>
      </el-table-column>
      <el-table-column prop="imei" :label="$t('设备imei')" min-width="160"></el-table-column>
      <el-table-column prop="agent_name" :label="$t('所属')" min-width="160"></el-table-column>

      <!-- <el-table-column label="所属区域" min-width="200">
        <template slot-scope="scope">
            <span v-if="scope.row?.extra?.location?.area">{{scope.row?.extra?.location?.area}}</span>
            <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="分组" min-width="200">
        <template slot-scope="scope">
            <div v-if="scope.row?.group">
                <span class="group-text" :style="{'background-color':scope.row.group.clr}">{{scope.row.group.title}}</span>
            </div>
            <span v-else>-</span>
        </template>
      </el-table-column> -->
      <el-table-column :label="$t('状态')" min-width="100">
        <template slot-scope="scope">

          <el-tooltip :content="scope.row.is_online === 1 ? $t('在线') : $t('未在线')" placement="bottom-start">
            <div
              :class="['online-point', scope.row.is_online === 1 ? 'online' : '', scope.row.is_online === 0 ? 'offline' : '']">
            </div>
          </el-tooltip>
          <!-- <span v-if="scope.row.status == 1" style="color:#67c23a;">启用</span> -->
          <!-- <span v-else style="color:#ccc;">禁用</span> -->
        </template>
      </el-table-column>
      <el-table-column :label="$t('库存')">
        <template slot-scope="scope">
          {{ scope.row.stock_num }} / {{ scope.row.capacity_num }}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" :label="$t('创建时间')" min-width="200" />
      <el-table-column :label="$t('操作')" min-width="150">
        <template slot-scope="scope">
          <el-dropdown split-button type="primary" size="small" @click="detailClick(scope.row.id)">
            {{$t('详情')}}
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="fillClick(scope.row.id)">{{$t('补货')}}</el-dropdown-item>
              <el-dropdown-item divided @click.native="orderLogClick(scope.row.id)">{{$t('记录')}}</el-dropdown-item>
              <el-dropdown-item @click.native="editClick(scope.row.id)">{{$t('编辑')}}</el-dropdown-item>
              <el-dropdown-item v-if="userInfo.superior_id == 0"
                @click.native="$router.push({ path: `/equiEdit?id=${scope.row.id}` })">{{$t('型号')}}</el-dropdown-item>
              <!-- <el-dropdown-item @click.native="unbind(scope.row)">解绑</el-dropdown-item> -->
              <el-dropdown-item @click.native="transferClick(scope.row)">{{$t('转让')}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination style="text-align:right;padding:10px 160px 10px 0;" background layout="prev, pager, next"
      hide-on-single-page :current-page.sync="listQuery.page" :page-size="listQuery.page_size" :total="listQuery.total"
      @current-change="devicePageChange"></el-pagination>




    <!-- //! 导入设备 -->
    <el-dialog :visible.sync="importEqui.visible" :title="$t('导入设备')">
      <div class="app-container">
        <el-upload class="upload-demo" ref="upload" accept=".xlsx,.xls" :on-change="handleChange"
          :on-preview="handlePreview" :on-remove="handleRemove" :file-list="fileList" :auto-upload="false">
          <el-button slot="trigger" size="small" type="primary">{{$t('选取文件')}}</el-button>
          <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">{{$t('提交到服务器')}}</el-button>
          <div slot="tip" class="el-upload__tip">
            <a :href="baseUrl + 'template.xls'">{{$t('下载导入模板')}}</a>{{$t('只能上传xlsx/xls文件')}}
          </div>
        </el-upload>
        <!-- <el-button type="warning" style="float: right; margin: 10px 0" @click="doImport" :loading="importEqui.loading">
          确认导入
        </el-button> -->
        <!-- 解析出来的数据 -->
        <div class="tableBox">
          <h3>
            <i class="el-icon-info">{{$t('请您检查无误后，再点击“提交到服务器”按钮')}}</i>
          </h3>
          <el-table :data="excelData" border style="width: 100%" :height="height">
            <el-table-column v-for="(col, index) in headers" :key="index" :prop="col.prop" :label="col.label"
              :min-width="col.minWidth" />
          </el-table>
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogPvVisible = false">Confirm</el-button>
      </span> -->
    </el-dialog>










    <!-- <div> -->


    <!-- 缺货设备 -->
    <el-dialog :title="$t('缺货设备')" :visible.sync="low.visible">
      <el-table :data="low.data" v-loading="low.loading">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column :label="$t('名称')" prop="name"></el-table-column>
        <el-table-column :label="$t('设备imei')" prop="imei"></el-table-column>
        <!-- <el-table-column label="预警数量" prop="warn_num"></el-table-column> -->
        <el-table-column :label="$t('状态')" prop="status">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1" style="color:#67c23a">{{$t('启用')}}</span>
            <span v-else style="color:#ccc">{{$t('禁用')}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="操作">
          <template slot-scope="scope">
            <el-button type="warning" size="mini" @click="lowHandleClick(scope.row)">{{$t('补货')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog :title="create.action == 'create' ? $t('添加设备') : $t('编辑设备')" width="500px" :visible.sync="create.visible"
      :close-on-click-modal="false">
      <el-form :model="create" :key="create.key" label-width="auto" v-loading="create.loading">
        <el-form-item :label="$t('设备编号')" :label-width="create.formLabelWidth">
          <div style="display:flex;flex-direction:row;">

            <el-input v-model="create.data.imei" :placeholder="$t('请输入设备编号')" clearable
              :disabled="create?.data?.id != ''"></el-input>
            <el-button type="primary" icon="el-icon-search" style="margin-left:20px;" @click="bindClick"
              :loading="create.loading" :disabled="create.data.id != ''" v-show="create.data.id == ''"></el-button>
          </div>
        </el-form-item>

        <span v-if="create.data.id">
          <el-form-item :label="$t('设备名称')" label-width="100">
            <el-input v-model="create.data.name" :placeholder="$t('请输入设备名称')" clearable style="width:316px"></el-input>
          </el-form-item>
          <!-- <el-form-item label="设备型号" :label-width="create.formLabelWidth">
            {{ create.data?.device_type_id_txt }}
          </el-form-item> -->

          <el-form-item :label="$t('设备场地')" :label-width="create.formLabelWidth">
            <el-select v-model="create.data.zone_id" placeholder="">
              <el-option v-for="(item, index) in zoneList" :label="item.name" :value="item.id" :key="index"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="设备状态" :label-width="create.formLabelWidth">
            <el-switch v-model="create.data.status" active-color="#13ce66" inactive-color="#ff4949">
            </el-switch>
          </el-form-item> -->
          <el-form-item :label="$t('设备密码')" :label-width="create.formLabelWidth">
            <div style="display:flex;flex-direction:row;">
              <!-- <span v-if="create.action == 'update'">
                <el-tooltip content="密码留空则不修改运营人员密码" placement="right">
                  <i class="el-icon-info" style="color:#E6A23C"></i>
                </el-tooltip>
              </span> -->
              <el-input style="width:316px" v-model="create.data.password"
                :placeholder="create.action == 'create' ? $t('请输入密码') : $t('密码留空则不修改运营人员密码')" clearable></el-input>
            </div>
          </el-form-item>
        </span>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="create.visible = false">{{$t('取 消')}}</el-button>
        <el-button type="primary" :disabled="create.disabled" @click="createSubmit">{{$t('确 定')}}</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="$t('补货') + '【' + fill.name + '】'" :visible.sync="fill.visible">
      <el-table :data="fill.data" v-loading="fill.loading">
        <el-table-column :label="$t('货道')" prop="lane" width="50"></el-table-column>
        <!-- <el-table-column label="图片" prop="goods_img">
            <template slot-scope="scope">
                <el-image :src="scope.row?.goods?.goods_img"></el-image>
            </template>
          </el-table-column> -->
        <el-table-column :label="$t('名称')" prop="goods_name" align="center" min-width="200">
          <template slot-scope="scope">
            <!-- {{scope.row?.goods?.name}} -->
            <!-- {{scope.row}} -->

            <span v-if="!scope.row.edit">
              {{ scope.row?.goods?.name }}
            </span>
            <el-select v-else v-model="scope.row.goods_id" @change="handleSelect($event, scope.$index)">
              <el-option v-for="(item, index) in goodsList" :label="item.name" :value="item.id"
                :key="index"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column :label="$t('价格')" prop="price" align="center" min-width="200">
          <template slot-scope="scope">
            {{ scope.row?.goods?.price }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('容量')" prop="max_num"></el-table-column>
        <el-table-column :label="$t('当前数量')" min-width="120" prop="num">
          <template slot-scope="scope">
            <span v-if="!scope.row.edit">{{ scope.row.num }}</span>
            <el-input-number v-else style="width:100px" v-model="scope.row.num" controls-position="right" :min="0"
              :max="scope.row.capacity" step-strictly size="small"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column :label="$t('操作')" min-width="210">
          <template slot-scope="scope">
            <el-button :type="scope.row.edit ? 'danger' : 'warning'" size="mini" @click="goodsFill(scope.row)">{{
        scope.row.edit ? $t('保存') : $t('补货') }}</el-button>
            <el-button type="success" size="mini" @click="goodsFill(scope.row, 2)">{{$t('补满')}}</el-button>
            <!-- <el-button type="primary" size="mini" :icon="(scope.row.testing || fill.testing) ? 'el-icon-loading' : ''"
              @click="testClick(scope.row, scope.$index)" :disabled="scope.row.testing || fill.testing">测试</el-button> -->
          </template>
          <!-- <div style="display:flex;">
                
            </div> -->

        </el-table-column>
      </el-table>
      <div slot="footer">
        <el-button type="danger" @click="fillUpClick">{{$t('一键补满')}}</el-button>
        <!-- <el-button type="primary">一键测试</el-button> -->
      </div>

    </el-dialog>

    <el-dialog :title="$t('设备转让')" :visible.sync="agent.visible" width="1000px">
      <el-table :data="agent.list" v-loading="agent.loading">
        <el-table-column type="index"></el-table-column>
        <el-table-column :label="$t('代理商')" min-width="150">
          <template slot-scope="scope">
            <div class="agent-view">
              <!-- <div>
                <el-image class="avatar" :src="scope.row.avatar" fit="fill" lazy></el-image>
              </div> -->
              <span class="username">{{ scope.row.name }}</span>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="等级" min-width="100">
          <template slot-scope="scope">
            <span class="level" :style="{'background-color':scope.row.level.clr}">{{scope.row.level.title}}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="device_count" :label="$t('设备数量')" min-width="100"></el-table-column>
        <el-table-column prop="mobile" :label="$t('电话')" min-width="100"></el-table-column>
        <!-- <el-table-column prop="address" label="地址" min-width="150"></el-table-column> -->
        <el-table-column :label="$t('操作')" min-width="100">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="deviceTransferClick(scope.row.id)">{{$t('转让')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="agent.page"
        :page-size="agent.pagesize" :total="agent.total" @current-change="agentPageChange"></el-pagination>
    </el-dialog>

    <el-dialog :title="$t('故障设备')" :visible.sync="breakdown.visible">
      <el-table :data="breakdown.data">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column :label="$t('名称')" prop="name"></el-table-column>
        <el-table-column :label="$t('编号')" prop="imei"></el-table-column>
        <el-table-column :label="$t('故障描述')" prop="desc"></el-table-column>
        <el-table-column :label="$t('故障时间')" prop="create_time"></el-table-column>
        <!-- <el-table-column label="操作">
            <template slot-scope="scope">
                <el-button type="danger" size="mini" @click="errorHandleClick(scope.row)">处理</el-button>
            </template>
          </el-table-column> -->
      </el-table>
    </el-dialog>

    <el-dialog :title="$t('查看设备库存')" :visible="stock.visible">
      <el-table :data="stock.list">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column :label="$t('设备名称')"></el-table-column>
        <el-table-column :label="$t('总数')"></el-table-column>
        <el-table-column :label="$t('货道')"></el-table-column>
        <el-table-column :label="$t('操作')">
          <el-button type="danger">{{$t('一键补满')}}</el-button>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog :title="$t('详情')" width="500px" :visible.sync="detail.visible">
      <div class="detail-view" v-if="detail.data">
        <div class="row-view">
          <span class="title">{{$t('设备IMEI')}}</span>
          <span class="content">{{ detail.data.imei }}</span>
        </div>
        <!-- <div class="row-view" v-if="detail.data.extra.location && detail.data.extra.location.area">
          <span class="title">所属区域</span>
          <span class="content">{{detail.data.extra.location.area[0]}}-{{detail.data.extra.location.area[1]}}-{{detail.data.extra.location.area[2]}}</span>
        </div> -->
        <div class="row-view">
          <span class="title">{{$t('设备名称')}}</span>
          <span class="content">{{ detail.data?.name }}</span>
        </div>
        <div class="row-view" v-if="detail.data.device_type_id_txt">
          <span class="title">{{$t('设备型号')}}</span>
          <span class="content">{{ detail.data.device_type_id_txt }}</span>
        </div>
        <!-- <div class="row-view" v-if="detail.data.group">
          <span class="title">设备分组</span>
          <span class="content">{{detail.data.group.title}}</span>
        </div> -->
        <div class="row-view">
          <span class="title">{{$t('设备状态')}}</span>
          <span class="content" style="color:#67c23a" v-if="detail.data.status == 1">{{$t('正常')}}</span>
          <span class="content" style="color:#ccc" v-else>{{$t('禁用')}}</span>
        </div>
        <!-- <div class="row-view" v-if="detail.data.update_time">
          <span class="title">最近在线</span>
          <span class="content">{{detail.data.update_time}}</span>
        </div> -->
        <div class="row-view" v-if="detail.data.lanes" style="width:1000px;margin-left:100px;">
          <!-- <span class="title">&nbsp;</span> -->
          <!-- <span class="content">{{detail.data.update_time}}</span> -->
          <!-- <span> -->
          <div style="display:flex;flex-direction:column;">
            <div v-for="(item, index) in detail.data.lanes" :key="index"
              style="display:flex;align-items:center;margin-top:5px;">
              <div>{{$t('货道')}}{{ item?.lane }}</div>
              <!-- <el-image class="m-l-10" src="" style="width:50px;height:50px;"></el-image> -->
              <div class="m-l-10" style="width:80px">{{ item?.goods?.name }}</div>
              <div class="m-l-10" style="width:50px">{{$t('库存')}}{{ item?.num }}</div>
              <div class="m-l-10" style="width:50px">{{$t('容量')}}{{ item?.max_num }}</div>
            </div>
          </div>

        </div>
      </div>
    </el-dialog>

    <el-dialog :title="$t('出货记录')" :visible.sync="order.visible">
      <el-table :data="order.list" v-loading="order.loading">
        <el-table-column type="index" width="50"></el-table-column>
        <!-- <el-table-column label="用户" min-width="200">
          <template slot-scope="scope">
            <div class="order-user">
              <el-image class="avatar" :src="scope.row?.extra?.user?.avatar" fit="fill" lazy></el-image>
              <span class="username">{{ scope.row?.extra?.user?.openid }}</span>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column :label="$t('订单号')" min-width="200">
          <template slot-scope="scope">
            <div class="order-user">
              <span class="username">{{ scope.row.transaction_id }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('用户')" min-width="200">
          <template slot-scope="scope">
            <div class="order-user" v-if="scope.row.extra.user.openid">
              <span class="username">{{ scope.row.extra.user.openid }}</span>
            </div>
            <div class="order-user" v-else-if="scope.row.extra.user == '非会员支付'">
              <span class="username">{{ scope.row.extra.user }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('价格')" min-width="100">
          <template slot-scope="scope">
            <span>¥ {{ scope.row.amount }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('创建时间')" width="200">
          <template slot-scope="scope">
            {{ scope.row.create_time }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="退款" min-width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 2" style="color:#E6A23C;">已退款</span>
            <el-button v-else type="success" size="mini" plain @click="refundClick(scope.row)">退款</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="order.page"
        :page-size="order.pagesize" :total="order.total" @current-change="orderPageChange"></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
//解析XLSX
import { saveAs } from 'file-saver';
import xlsx from 'xlsx';
export default {
  data() {
    return {
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      fileList: [
        //   {
        //   name: 'food.jpeg',
        //   url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        // }
      ],
      submitData: [],
      height: 400, // 设置表格高度
      excelData: [], // 存放excel数据
      headers: [ // 默认表头设置
        { prop: "name", label: "名称(必填)", minWidth: "50%" },
        { prop: "imei", label: "IMEI(必填)", minWidth: "50%" },
        { prop: "agent", label: "代理商(选填)", minWidth: "80%" },
        { prop: "model", label: "型号(选填)", minWidth: "80%" }
      ],
      importEqui: {
        visible: false,
        loading: false
      },


      form: {

      },
      zoneList: [],
      data: [],
      listQuery: {
        page: 1,
        page_size: 20,
        total: 20,
        keyword: ''
      },
      low: { // 缺货设备
        visible: false,
        total: 0,
        data: [],
        loading: false
      },
      breakdown: { // 故障设备
        visible: false,
        data: [{
          name: "20240102182800",
          imei: "20240102182800",
          desc: "这是一个故障描述",
          address: "北京市-北京市-东城区",
          create_time: "2024-01-02"
        }]
      },
      stock: {
        visible: false,

      },
      fill: {
        visible: false,
        loading: false,
        name: '',
        data: []
      },
      detail: {
        visible: false,
        data: null
      },
      create: {
        visible: false,
        disabled: false,
        action: 'create',
        loading: false,
        data: {
          id: '',
          imei: '',
          name: ''
        },
        key: 0,
        formLabelWidth: '200'
      },
      edit: {
        visible: false,
        data: {}
      },
      model: [
        {
          id: 1,
          title: '123'
        },
      ],
      order: {
        visible: false,
        loading: false,
        total: 0,
        list: []
      },
      agent: {
        visible: false,
        loading: false,
        list: [],
        avatar: '',
        name: '',
      },
      goodsList: [],
      baseUrl:process.env.VUE_APP_BASE_URL
    }
  },
  mounted() {
    this.getList();
    this.getZoneList();
    this.getOutStockList();
    this.getGoodsList();
    // this.getAgentList();
  },
  methods: {


    exportExcel() {
      const XLSX = require("xlsx");

      this.$get('/api/device/DeviceGoodsSupply').then(res => {


        const chineseHeaders = {
          "device_id": "设备Id",
          "device_imei": "设备IMEI",
          "device_name": "设备名称",
          "replenish_data": "补货数据"
        };

        const dataWithChineseHeaders = res.map(item => {
          const newItem = {};
          for (const key in item) {
            newItem[chineseHeaders[key]] = item[key];
          }
          return newItem;
        });

        const ws = XLSX.utils.json_to_sheet(dataWithChineseHeaders);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, '补货单.xlsx', { bookType: 'xlsx', type: 'array' });
      }).catch(error => {
        console.error(error);
        this.$message.error('导出失败');
      });


    },


    // 导入设备 start

    async handleChange(file) {
      const xlsx = require("xlsx");
      const originData = file.raw;
      if (!originData) return;
      // 清空文件列表
      this.fileList = [];
      this.submitData = [];
      let dataBinary = await new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsBinaryString(file.raw);
        reader.onload = (ev) => {
          resolve(ev.target.result);
        };
      });
      let workBook = xlsx.read(dataBinary, { type: "binary", cellDates: true });
      let firstWorkSheet = workBook.Sheets[workBook.SheetNames[0]];
      const header = this.getHeaderRow(firstWorkSheet);
      console.log("🚀 ~ handleChange ~ header:", header)
      this.headers = header.map((item) => {
        return { prop: item, label: item, minWidth: "50%" };
      });
      console.log("🚀 ~ this.headers=header.map ~ this.headers:", this.headers)

      const data = xlsx.utils.sheet_to_json(firstWorkSheet);
      console.log("🚀 ~ handleChange ~ data:", data)
      // 定义键名映射关系
      const keyMap = {
        "名称(必填)": "name",
        "IMEI(必填)": "imei",
        "代理商\n(代理管理中查看代理商ID)\n(选填)": "agent_id",
        "型号\n(型号管理中查看型号ID)\n(选填)": "device_type_id"
      };

      this.submitData = data.map((item) => {
        let newItem = {};
        for (let oldKey in item) {
          if (keyMap[oldKey]) {
            newItem[keyMap[oldKey]] = item[oldKey];
          } else {
            newItem[oldKey] = item[oldKey];
          }
        }
        return newItem;
      });

      this.excelData = data; // 将excel数据存入excelData数组

      this.fileList.push(file); // 将新选择的文件添加到文件列表中
    },

    getHeaderRow(sheet) {
      const xlsx = require("xlsx");
      const headers = [];
      const range = xlsx.utils.decode_range(sheet["!ref"]);
      let C;
      const R = range.s.r;
      for (C = range.s.c; C <= range.e.c; ++C) {
        const cell = sheet[xlsx.utils.encode_cell({ c: C, r: R })];
        let hdr = "UNKNOWN " + C;
        if (cell && cell.v) hdr = cell.v;
        headers.push(hdr);
      }
      return headers;
    },

    submitUpload() {
      // this.$refs.upload.submit();
      this.$post('/api/device/add', this.submitData).then(res => {
        console.log("🚀 ~ this.$post ~ res:", res)
        this.$message({
          message: '操作成功',
          type: 'success'
        });
        this.getList();
        this.importEqui.visible = false;
      }).catch(error => {
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.excelData = [];
      this.headers = [];
    },
    handlePreview(file) {
      console.log(file);
    },




    // 导入设备 end













    transferClick(row) {
      // let imei = row.imei;
      this.agent.visible = true;
      this.$get('/api/agent/subList').then(res => {
        console.log('res', res.data);
        this.agent.list = res.data;
        this.agent.imei = row.imei;
      });
    },
    deviceTransferClick(id) {
      this.$confirm('确定要转让给该代理商吗？', '提示').then(res => {
        this.$post('/api/device/transfer', {
          imei: this.agent.imei,
          target_agent_id: id
        }).then(res => {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.agent.visible = false;
          this.getList();
        })
      })
    },
    getAgentList() {

    },
    getGoodsList() {
      this.$get('/api/goods/list', { page_size: 99 }).then(res => {
        console.log('res-----', res);
        this.goodsList = res.data;
      });
    },
    handleSelect(goodsId, index) {
      // console.log('event',event);
      // console.log('index',index);
      // return;

      let current = {};
      for (let i in this.goodsList) {
        if (this.goodsList[i].id == goodsId) {
          current = this.goodsList[i];
        }
      }
      console.log('current', current);
      this.fill.data[index].goods.price = current.price;
    },
    async bindClick() {
      if (this.create.data.imei == '') {
        this.$message({
          message: '设备编号不能为空',
          type: 'warning'
        });
        return;
      }
      this.create.loading = true;
      this.$post('/api/device/bind', {
        imei: this.create.data.imei
      }).then(async res => {
        console.log('res bindClick', res);

        if (res) {
          this.$message({ message: '绑定成功', type: 'success' });
          this.create.data.id = res.id;
          let info = await this.getInfo(res.id);
          info.status = info.status == 1 ? true : false;
          this.create.data = info;
          this.create.key++;
          this.create.loading = false;
        }
        this.create.loading = false;



        console.log('this.create.data', this.create.data);
      }).catch(res => {
        console.log('bind error');
        this.create.loading = false;

      })

    },
    orderLogClick(id) {
      this.order.visible = true;
      this.order.loading = true;
      this.$get('/api/order/orderListOnDevice', {
        device_id: id
      }).then(res => {
        this.order.list = res.data;

        this.order.loading = false;
      }).catch(err => {
        this.order.loading = false;
      })
    },
    lowDeviceClick() {
      // console.log('lowDeviceClick');
      this.low.visible = true;
      this.getOutStockList();


    },
    agentPageChange() {
      this.getAgentList();
    },
    orderPageChange() {
      this.getOutStockList();
    },
    devicePageChange() {
      console.log('devicePageChange');
      console.log('listQuery.page', this.listQuery.page);
      this.getList();
    },
    getOutStockList() {
      this.low.loading = true;

      this.$get('/api/device/outStockList').then(res => {
        this.low.loading = false;
        this.low.data = res.data;
        this.low.total = res.total;
        console.log('low--', res);
      }).catch(res => {
        this.low.loading = false;

      })
    },
    breakdownDeviceClick() {
      this.breakdown.visible = true;
      this.$get('/api/device/errorList').then(res => {
        this.breakdown.data = res.data;
        console.log('this.breakdown.data', this.breakdown.data);
      }).catch(error => {

      })
      // this.breakdown.data = ;
    },
    stockClick() {
      this.stock.visible = true;
    },
    async lowHandleClick(row) { // 点击补货按钮
      console.log('lowHandleClick', row);
      this.fill.visible = true;
      this.fill.loading = true;
      let info = await this.getInfo(row.id);
      this.fill.loading = false;
      console.log('lowHandleClick-', info);
      this.fill.device_id = info.id;
      this.fill.name = info.name;
      this.fill.data = info.lanes;
      this.fill.data = info.lanes.map(e => {
        return { ...e, edit: false };
      });
    },
    async fillClick(id) {
      this.fill.visible = true;
      this.fill.loading = true;

      let info = await this.getInfo(id);
      this.fill.loading = false;
      this.fill.name = info.name;
      this.fill.device_id = info.id;
      this.fill.data = info.lanes;
      this.fill.data = info.lanes.map(e => {
        return { ...e, edit: false };
      });
    },
    async detailClick(id) {
      let info = await this.getInfo(id);
      this.detail.data = info;
      this.detail.visible = true;
    },
    errorHandleClick(row) {
      console.log('errorHandleClick');

      this.$prompt("请输入处理结果", "提示", {
        confirmButtonText: "提交",
        cancelButtonText: "取消"
      }).then(({ value }) => {
        if (!value) {
          this.$alert('请输入处理结果', '提示');
        } else {
          let params = {
            'code': 1,
            'desc': ''
          };
          this.$post('/api/device/setErrorCode', params).then(res => {
            console.log('setErrorCode');
          })
        }
      })
    },
    getList() {
      this.$get('/api/device/devList', this.listQuery).then(res => {
        this.data = res.data;
        console.log('res+++', res);
        this.listQuery.total = res.total;
        // this.listQuery.page;
        console.log('res.data+++', res.data);
      });

    },
    createSubmit() {
      this.create.disabled = true;
      // console.log('create.data',this.create.data);
      // return;
      // if(this.create.action == 'create'){
      //     this.$post('/api/device/add',{
      //         imei:this.create.data.imei,
      //         name:this.create.data.name,
      //         status:this.create.data.status == true ? 1:0,
      //         zone_id:this.create.data.zone_id
      //     }).then(res => {
      //         this.create.disabled = false;
      //         this.create.visible = false;
      //         this.$message({
      //           message: '设置成功',
      //           type: 'success'
      //         });
      //         location.reload();
      //     })
      // }else{
      //     this.$post('/api/device/update',{
      //       id:this.create.data.id,
      //       name:this.create.data.name,
      //       status:this.create.data.status == true ? 1:0,
      //       zone_id:this.create.data.zone_id
      //     }).then(res=>{
      //         this.create.disabled = false;
      //         this.create.visible = false;
      //         this.$message({
      //           message: '设置成功',
      //           type: 'success'
      //         });
      //         location.reload();
      //     })
      // }
      this.$post('/api/device/update', {
        id: this.create.data.id,
        name: this.create.data.name,
        status: this.create.data.status == true ? 1 : 0,
        zone_id: this.create.data.zone_id,
        password: this.create.data.password,
      }).then(res => {
        this.create.disabled = false;
        this.create.visible = false;
        this.$message({
          message: '设置成功',
          type: 'success'
        });
        // location.reload();
        this.getList();
      })

    },
    unbind(row) {
      console.log('row imei', row.imei);
      this.$confirm('确定要解绑该设备吗?', '提示').then(res => {
        console.log('unbind res', res);
        this.$post('/api/device/unbind', {
          imei: row.imei
        }).then(res => {
          this.$message({
            message: '解绑成功',
            type: 'success'
          });
          location.reload();
        });
      }).catch(() => {

      });
    },
    async getInfo(id) {
      console.log('getInfo');
      // let info =  
      let info = await this.$get('/api/device/info', {
        id: id
      }).then(res => {
        // console.log('res info',res);
        // this.create.data = res.info;
        // console.log('res--',res);
        // console.log('getInfo res',res.info);
        return res.info;
      }).catch(res => {
        // console.log('getInfo error');
        return [];
      })
      console.log('info 123', info);
      // console.log('getInfo--',info);

      return info;
    },
    goodsFill(item, type = 1) {
      // console.log('item',item);
      // console.log('item.edit-',item.edit);
      // return;
      if (type == 1) { // 保存
        if (item.num === undefined) {
          this.$message({
            message: "当前数量不能为空",
            type: "warning",
            center: true
          });
          return;
        }
        item.edit = !item.edit;
      } else { // 补满
        item.num = item.max_num;
        item.edit = false;
      }

      if (!item.edit) {
        this.$post('/api/device/updateLane', {
          id: item.id,
          num: item.num,
          goods_id: item.goods_id
        }).then(async res => {
          this.$message({
            message: "补货成功",
            type: "success",
            center: true
          });
          // 刷新货道
          this.fill.loading = true;
          let info = await this.getInfo(this.fill.device_id);
          console.log('info-', info);
          this.fill.loading = false;
          this.fill.name = info.name;
          this.fill.data = info.lanes;
          this.fill.data = info.lanes.map(e => {
            return { ...e, edit: false };
          });
        });
      }
    },
    fillUpClick() {
      this.$post('/api/device/fillAllLane', {
        id: this.fill.device_id
      }).then(res => {
        this.$message({
          message: "补货成功",
          type: "success",
          center: true
        });
        this.fill.data = this.fill.data.map(e => {
          return { ...e, edit: false, num: e.max_num }
        });
        this.getList();
      });
    },
    testClick() {

    },
    createClick() {
      this.create.visible = true;
      this.create.disabled = false;
      this.create.action = 'create';
      this.create.key = 0;
      this.create.formLabelWidth = '200';
      this.create.data = {
        id: '',
        imei: '',
        name: ''
      };
    },
    async editClick(id) {
      this.create.visible = true;
      this.create.loading = true;
      this.create.action = 'update';

      let info = await this.getInfo(id);
      this.create.loading = false;

      // console.log('info-',info);
      this.create.data = info;
      this.create.data.status = info.status == 1 ? true : false;
      // console.log(' this.create.data', this.create.data);
    },
    getZoneList() {
      this.$get('/api/agentZone/list').then(res => {
        // console.log('Zone res',res);
        this.zoneList = res.data;
      });
    }
  }
}
</script>

<style lang="scss">
.group-text {
  padding: 5px 10px;
  color: #fff;
}

// .online-view {
//   display: flex;
//   flex-direction: row;
//   align-items: center;

.online-point {
  width: 10px;
  height: 10px;
}

.online-title {
  font-size: 14px;
  margin-left: 10px;
}

.online {
  background-color: #67c23a;
}

.offline {
  background-color: #ccc;
}

// }

.status-online {
  color: #67c23a;
}

.status-offline {
  color: #ccc;
}

.status-online {
  color: #67c23a;
}

.status-offline {
  color: #ccc;
}

.m-l-10 {
  margin-left: 10px;
}

.detail-view {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.row-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  padding: 10px 0;

  .title {
    width: 120px;
    text-align: right;
  }

  .content {
    width: calc(100% - 150px);
    margin-left: 30px;
    color: #888;

    .el-icon-document-copy {
      margin-left: 10px;
      cursor: pointer;
    }

    .el-icon-document-copy:hover {
      opacity: 0.7;
    }
  }

  .online {
    color: #67c23a;
  }

  .offline {
    color: #f56c6c;
  }
}
</style>