<template>
    <div>
        <el-form :inline="true" class="search-form">


            <!-- 根据代理商查询 -->
            <el-form-item :label="$t('代理商') + '：'">
                <el-select v-model="agent" :placeholder="$t('请选择代理商')">
                    <el-option v-for="option in agentOptions" :key="option.value" :label="option.label"
                        :value="option.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="searchByAgent">{{$t('查询')}}</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="searchByAgentAll">{{$t('展开所有')}}</el-button>
            </el-form-item>
        </el-form>

        <el-table class="ptd-5" id="list_table" ref="list_table" v-loading="listLoading" :data="agents"
            :max-height="tableMaxH" element-loading-text="Loading" stripe highlight-current-row>
            <el-table-column label="#" type="index"></el-table-column>
            <el-table-column label="代理" width="150">
                <template slot-scope="scope">
                    <div class="flex align-center">
                        <!-- <el-avatar size="small" :src="scope.row.avatar"></el-avatar> -->
                        <div class="flex1 ml-5">
                            {{ scope.row.extra.target.name }}
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('手机号码')">
                <template slot-scope="scope">
                    {{ scope.row.extra.target.mobile }}
                </template>
            </el-table-column>
            <el-table-column :label="$t('账户余额')">
                <template slot-scope="scope">
                    <!-- <span style=""></span> -->
                    {{ scope.row.extra.target.balance }}
                </template>
            </el-table-column>
            <el-table-column :label="$t('提现时间')" width="100" prop="create_time">
            </el-table-column>
            <el-table-column :label="$t('提现金额')">
                <template slot-scope="scope">
                    <div class="el-link">{{ scope.row.amount }}</div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('提现方式')" width="100">
                <template slot-scope="scope">
                    <!-- {{ config.withdraw_way[scope.row.withdraw_type] }} -->
                    {{$t('微信到账')}}
                </template>
            </el-table-column>
            <el-table-column :label="$t('状态')">
                <template slot-scope="scope">
                    <div v-if="scope.row.status == 0" style="color:#E6A23C">{{$t('申请中')}}</div>
                    <div v-if="scope.row.status == 1" style="color:#67C23A">{{$t('已支付')}}</div>
                    <div v-if="scope.row.status == 2" style="color:#909399">{{$t('驳回')}}</div>
                    <div v-if="scope.row.status == 3" style="color:#E6A23C">{{$t('申请等待')}}</div>
                    <div v-if="scope.row.status == 4" style="color:#F56C6C">{{$t('失败')}}</div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('操作')" width="150">
                <template slot-scope="scope">
                    <div v-if="scope.row.status == 0">
                        <el-button size="mini" type="primary" round @click="upApply(scope.row, 3)">
                            {{$t('通过')}}
                        </el-button>
                        <el-button size="mini" type="primary" round @click="upApply(scope.row, 2)">
                            {{$t('驳回')}}
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="page"
            :page-size="pagesize" :total="total" @current-change="agentPageChange"></el-pagination>
    </div>
</template>

<script>
export default {
    data() {
        return {
            agent: '',
            agentOptions: [],
            agents: [],
            page: 1,
            pagesize: 20,
            total: 0,
        };
    },
    created() {
        this.reflectList();
        this.searchName();
        // this.agent.loading = true;
    },

    methods: {
        reflectList(keyword) {
            this.$get('/api/withdraw/list', {
                page: this.page,
                page_size: this.page_size,
                keyword: this.agent,
            }).then(res => {
                this.listLoading = false;
                this.agents = res.data;
                this.total = res.total;
            }).catch(err => {

            });
        },
        searchByAgentAll() {
            this.agent = '';
            this.reflectList();
        },
        agentPageChange(val) {
            this.page = val;
            this.reflectList();
        },
        searchName() {
            this.$get('/api/withdraw/agentList').then(res => {
                console.log("🚀 ~ this.$get ~ res:", res)
                const agentOptions = [];
                const visitedIds = [];
                for (let i = 0; i < res.length; i++) {
                    const label = res[i].name;
                    const value = res[i].name;
                    // 如果 id 已经存在于 visitedIds 中，则跳过当前对象
                    if (visitedIds.includes(value)) {
                        continue;
                    }
                    // 将 label 和 value 存入临时数组 agentOptions
                    agentOptions.push({ label, value });

                    // 将当前 id 添加到 visitedIds 中
                    visitedIds.push(value);
                }

                this.agentOptions = agentOptions;

            }).catch(error => {

            })
        },
        searchByAgent() {
            // 根据代理商查询逻辑
            if (this.agent) {
                this.reflectList(this.agent);
            } else {
                console.log('请选择代理商进行查询');
            }
        },
        upApply(row, agree) {
            if (agree == 3) {
                this.$alert(this.$tt.t('确定该笔提现申请通过审核吗？'), this.$tt.t('提现审核'), {
                    confirmButtonText: '确定',
                    callback: action => {
                        if (action == 'confirm') {
                            this.postApply(row, agree)
                        }
                    }
                })
            } else if (agree == 2) {
                this.$alert(this.$tt.t('确定驳回该笔提现申请吗？'), this.$tt.t('提现审核'), {
                    confirmButtonText: this.$tt.t('确定'),
                    callback: action => {
                        if (action == 'confirm') {
                            this.postApply(row, agree)
                        }
                    }
                })
            }
        },
        postApply(row, agree) {
            let url = '/api/withdraw/update'
            this.$post(url, {
                id: row.id,
                status: agree
            }).then(res => {
                this.$message({
                    message: this.$tt.t('提交成功'),
                    type: 'success'
                })
                this.reflectList();
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.btns-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
}

.breadcrumb-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #eee;
    padding: 10px;

    .breadcrumb-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        .name {
            font-size: 14px;
            cursor: pointer;
        }

        .name:hover {
            text-decoration: underline;
        }

        .el-icon-arrow-right {
            font-size: 14px;
            color: #9d9d9d;
            margin-left: 5px;
        }
    }

    .breadcrumb-item:not(:first-child) {
        margin-left: 5px;
    }

    .breadcrumb-item:first-child .name {
        font-weight: 500;
    }
}

.table-row:hover {
    .el-icon-edit-outline {
        display: block;
    }
}

.row-view {
    display: flex;
    flex-direction: row;
    align-items: center;

    .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    .username {
        margin-left: 10px;
    }

    .el-icon-edit-outline {
        margin-left: 10px;
        display: none;
        color: #9d9d9d;
        cursor: pointer;
    }
}

.level {
    padding: 5px 10px;
    color: #fff;
}

.qrcode {
    width: 150px;
    cursor: pointer;
}

.qrcode:active {
    opacity: 0.7;
}

.fa-qrcode:hover {
    color: #ccc;
}

.fa-bluetooth {
    margin-left: 10px;
    color: #1870d2;
}

.fa-bluetooth:hover {
    opacity: 0.7;
}

.group-text {
    padding: 5px 10px;
    color: #fff;
}

.circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-left: 0.5em;
}

.circle:hover {
    opacity: 0.7;
}

.el-table {
    .online {
        background-color: #67c23a;
    }

    .offline {
        background-color: #f56c6c;
    }
}

.detail-view {
    width: 100%;
    display: flex;
    flex-direction: column;

    .row-view {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        padding: 10px 0;

        .title {
            width: 120px;
            text-align: right;
        }

        .content {
            width: calc(100% - 150px);
            margin-left: 30px;
            color: #888;
            display: flex;
            flex-direction: row;
            align-items: center;

            .goods-img {
                width: 50px;
                height: 50px;
            }

            .goods-name {
                margin-left: 20px;
            }

            .goods-price {
                color: #f56c6c;
                margin-left: 20px;
            }
        }

        .online {
            color: #67c23a;
        }

        .offline {
            color: #f56c6c;
        }
    }
}

.top-view {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
        font-size: 14px;
    }
}

.online-view {
    display: flex;
    flex-direction: row;
    align-items: center;

    .online-point {
        width: 10px;
        height: 10px;
    }

    .online-title {
        font-size: 14px;
        margin-left: 10px;
    }

    .online {
        background-color: #67c23a;
    }

    .offline {
        background-color: #ccc;
    }
}

.status-online {
    color: #67c23a;
}

.status-offline {
    color: #ccc;
}
</style>