<template>
  <div class="content-view" v-title :data-title="$t('下级代理')" style="background:#fff;">
    <div class="top-view" style="padding:10px;">

      <el-button type="success" @click="createAdvert">{{$t('添加广告')}}</el-button>
      <el-input style="width:350px;margin-left: 20px;" :placeholder="$t('请输入关键字搜索')" v-model="searchName" clearable
        @clear="getStaffList" @keyup.enter.native="getStaffList">
        <el-button slot="append" icon="el-icon-search" @click="getStaffList"></el-button>
      </el-input>
      <!-- <el-button type="success" @click="searchDevice">搜索设备</el-button>
      <el-button type="warning" @click="searchAgent">搜索下级</el-button> -->
      <!-- <el-button type="success" @click="createClick">添加会员</el-button> -->
    </div>
    <!-- {{levelArray}} -->
    <div class="breadcrumb-view" v-if="levelArray.length > 1">
      <i class="el-icon-user-solid"></i>

      <div class="breadcrumb-item" v-for="(item, index) in levelArray" :key="index">
        <span class="name" @click="breadcrumbClick(item, index)">{{ item.title }}</span>
        <i class="el-icon-arrow-right" v-if="index != levelArray.length - 1"></i>
      </div>
    </div>

    <el-table :data="list" v-loading="agent.loading" row-class-name="table-row">
      <el-table-column type="index" label="#"></el-table-column>

      <el-table-column prop="name" :label="$t('广告名称')">
      </el-table-column>
      <el-table-column prop="file_type_text" :label="$t('广告类型')">
      </el-table-column>
      <el-table-column :label="$t('图片组')">
        <template slot-scope="scope">
          <el-image style="width: 100px;height:100px" v-for="item, index in scope.row.cover_img_url" :key="index"
            :src="item.url" :preview-src-list="scope.row.cover_img_url.map(item => item.url)">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="video_name" :label="$t('广告视频')" align="center">
        <template slot-scope="scope">
          <i class="el-icon-video-play iCon" v-if="scope.row.video_url" @click="openPlay(scope.row)"></i>
        </template>
      </el-table-column>
      <el-table-column :label="$t('视频封面')" align="center">
        <template slot-scope="scope">
          <el-image style="width: 100px;height:100px" v-if="scope.row.video_img_url" :src="scope.row.video_img_url"
            :preview-src-list="[scope.row.video_img_url]">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column  label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 1">{{$t('通过审核')}}</span>
          <span v-if="scope.row.status === 0">{{$t('待审核')}}</span>
          <span v-else-if="scope.row.status === 2">{{$t('被驳回')}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('操作')" width="400" align="center">
        <template slot-scope="scope">
          <el-button  v-if="scope.row.status === 1"  type="primary" @click="editClick(scope.row)" size="mini">{{$t('编辑')}}</el-button>
          <el-button  v-if="scope.row.status === 1"  type="danger" @click="deleteClick(scope.row)" size="mini">{{$t('删除')}}</el-button>
          <el-button  v-if="scope.row.status === 0"  type="primary" @click="editClick(scope.row)" size="mini">{{$t('重新编辑')}}</el-button>
          <el-button  v-if="scope.row.status === 0"  type="danger" @click="deleteClick(scope.row)" size="mini">{{$t('删除')}}</el-button>
        </template>
      </el-table-column>
    </el-table>




    <el-dialog :title="form.action == 'create' ? $t('添加广告') : $t('编辑广告')" :visible.sync="devShow" @close="form = {}">
      <el-form :model="form" ref="form" label-width="auto">
        <div class="list">
          <el-form-item :label="$t('广告名称')" prop="name">
            <el-input v-model="form.name" style="width:350px" :placeholder="$t('请输入广告名称')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('广告类型')" prop="file_type">
            <el-radio-group v-model="form.file_type" @change="handleType">
              <el-radio :label="1">{{$t('图片')}}</el-radio>
              <el-radio :label="2">{{$t('视频')}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('图片组')" v-if="form.file_type == 1">
            <div class="upload">
              <el-upload :action="'/api/advert/upload'" list-type="picture" :headers="headers" name="image"
                :data="{ type: 0 }" :limit="4" :accept="'.jpg, .png'" :file-list="cover_img_url"
                :on-remove="handleRemove1" :before-remove="beforeRemove1" :on-success="handleSuccess1">
                <el-button size="small" type="primary">{{$t('点击上传')}}</el-button>
                <div slot="tip" class="el-upload__tip">{{$t('只能上传jpg/png文件')}}</div>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item :label="$t('广告视频')" v-if="form.file_type == 2">
            <div class="upload">
              <el-upload :action="'/api/advert/upload'" :headers="headers" :data="{ type: 1 }" name="video"
                :limit="1" :file-list="video" :accept="'.mp4'" :before-remove="beforeVideoRemove1"
                :on-success="handleVideoSuccess1">
                <div slot="tip" class="el-upload__tip">{{$t('点击上传')}}</div>
                <el-button size="small" type="primary">{{$t('只能上传mp4文件')}}</el-button>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item :label="$t('视频封面')" v-if="form.file_type == 2">
            <div class="upload">
              <el-upload class="avatar-uploader" :action="'/api/advert/upload'" :headers="headers"
                list-type="picture" name="image" :data="{ type: 0 }" :accept="'.jpg, .png'" :show-file-list="false"
                :before-upload="beforeVideoImgUpload" :on-success="handleVideoImgSuccess">
                <el-image v-if="imageUrl" :src="imageUrl" style="width: 180px;height:180px" class="avatar"></el-image>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <div slot="tip" class="el-upload__tip">{{$t('只能上传jpg/png文件')}}</div>
              </el-upload>
            </div>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickFalse">{{$t('取 消')}}</el-button>
        <el-button type="primary" :disabled="form.disabled" :loading="form.disabled" @click="createSubmit('form')">{{$t('确 定')}}</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="videoTitle" :visible.sync="playShow" :close-on-click-modal="false" top="10vh" width="30%"
      @close="videoClose()" custom-class="dialog">
      <div class="content">

        <video-player ref="videoPlayer" :playsinline="true" class="vjs-big-play-centered" :options="playerOptions">
        </video-player>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { deepClone } from '@/utils/index'
import _ from 'lodash'
export default {
  name: "agent",
  data() {
    return {
      cover_img_url: [],
      searchName:'',
      playShow: false,
      video_img: [],
      video: [],
      videoTitle: '',
      video_url: null,
      list: [],
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      imageUrl: '',
      videoTitle: '',
      index_id: null,
      devShow: false,
      form: {},
      agent: {
        list: [
          {
            avatar: '',
            name: '',
            level: {
              clr: 1
            }
          }
        ],
        page: 1,
        pagesize: 20,
        total: 0,
        loading: false,
        guid: null,
        remove: false
      },
      levelArray: [
        {
          guid: "",
          title: this.$tt.t("我的")
        }
      ],

      search: {
        device: {
          list: [],
          page: 1,
          pagesize: 20,
          total: 0,
          loading: false,
          keyword: '',
          visible: false
        },

      },



      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0, 3.0], // 可选的播放速度
        autoplay: false, // 是否自动播放
        muted: false, // 是否静音
        loop: true, // 是否开启循环播放
        preload: "auto", // 自动预加载
        language: "zh-CN", // 语言，'en', 'zh-cn', 'zh-tw'
        aspectRatio: "16:9", // 播放器高宽占比（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 类型
            src: "", // url地址
          },
        ],
        poster: "", // 封面地址
        notSupportedMessage: this.$tt.t("此视频暂无法播放，请稍后再试"), // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 是否显示当前时间和持续时间的分隔符，"/"
          durationDisplay: true, // 是否显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
    };
  },
  created() {
    this.getList();
    // this.getDevList();
  },
  methods: {
    getStaffList() {
      this.getList();
    },
    openPlay(row) {
      // console.log(row)
      this.videoTitle = row.video_name
      this.playerOptions.sources[0].src = row.video_url
      this.playerOptions.poster = row.video_img_url
      this.playShow = true
    },
    videoClose() {

      this.$refs.videoPlayer.player.pause() // 暂停
      this.$refs.videoPlayer.player.src(this.playerOptions.sources[0].src) // 重置进度条

    },
    handleVideoSuccess1(response, file, fileList) {
      // console.log(response, file, fileList)
      this.form.video_name = response.data.name
    },
    createAdvert() {
      this.form = {
        action: 'create',
        name: '',
        file_type: 1,
        cover_img: [],
        video_name: null,
        video_img: null,
        id: null,
      };
      this.imageUrl = '';
      this.video = null;
      this.cover_img_url = [];

      // this.showUpload = true
      this.devShow = true
    },

    async beforeRemove(file, fileList) {
      await this.$post('/api/file/del', {
        name: file.response.data.name,
        file_type: 'image',
        module: 'advert'
      }).then(res => {
        if (!res) {
          this.$message.error(res.msg)
          return Promise.reject(res)
        }
      }).catch(err => {
        this.$message.error(err.message)
        return Promise.reject(err)
      })
    },
    handleRemove1(file, fileList) {
      // console.log(file, fileList)
      this.form.cover_img = this.form.cover_img.filter(item => item !== file.name)
    },
    handleSuccess1(response, file, fileList) {
      // console.log(response, file, fileList)
      this.form.cover_img.push(response.data.name)
      // console.log(this.newform.cover_img)
    },
    async beforeVideoRemove1(file, fileList) {
      // console.log(file, fileList)
      await this.$post('/api/file/del', {
        name: this.video[0].name,
        file_type: 'video',
        module: 'advert'
      }).then(res => {
        if (!res) {
          this.$message.error(res.msg)
          return Promise.reject(res)
        }
        this.form.video_name = null
        this.video = []
      }).catch(err => {
        this.$message.error(err.message)
        return Promise.reject(err)
      })
    },
    handleChange(file) {
      if (this.$refs["uploader"].uploadFiles.length > 1) {
        this.$refs["uploader"].uploadFiles.shift()
      }
      this.imageUrl = file.url
    },
    handleAvatarError(err) {
      this.$message.error(err.msg)
    },
    async createSubmit() {

      let form
      if (this.form.action == 'create') {
        var { name, file_type, cover_img, video_name, video_img } = this.form
      } else {
        var { id, name, file_type, cover_img, video_name, video_img } = this.form
      }
      if (this.form.file_type == 1) {
        form = {
          id,
          name,
          file_type,
          cover_img,
          video_name: null,
          video_img: null,
        }
      } else if (this.form.file_type == 2) {
        form = {
          id,
          name,
          file_type,
          cover_img: [],
          video_name,
          video_img,
        }
      }
      if (this.form.action == 'create') {
        // console.log(file_type);
        const res = await this.$post('/api/advert/add', form)
        if (!res) { return this.$message.error(res.msg) }
        this.devShow = false
        this.getList()
      } else {
        const res = await this.$post('/api/advert/update', form)
        if (!res) { return this.$message.error(res.msg) }
        this.devShow = false
        this.getList()
      }


    },

    async beforeVideoImgUpload(file) {
      // console.log(file)
      if (this.form.video_img) {
        await this.$post('/api/file/del', {
          name: this.form.video_img,
          file_type: 'image',
          module: 'advert'
        }).then(res => {
          console.log(res);
          if (!res) {
            this.$message.error(res.msg)
            return Promise.reject(res)
          }
        }).catch(err => {
          this.$message.error(err.message)
          return Promise.reject(err)
        })
      }
    },
    handleVideoImgSuccess(response, file, fileList) {
      // console.log(response, file, fileList)
      if (response.code !== 200) { return this.$message.error(res.msg) }
      this.form.video_img = response.data.name
      this.imageUrl = response.data.url
      // this.form.cover_img.push(response.data.name)
      // console.log(this.form.cover_img)
    },

    async editClick(row) {
      console.log(row);
      let { id, name, file_type, type, cover_img_url, video_name, video_img } = row
      this.form = { id, name, file_type, type, cover_img: [], video_name, video_img }
      this.cover_img_url = cover_img_url
      // console.log(cover_img_url);
      this.cover_img_url.forEach(item => {
        this.form.cover_img.push(item.name)
      });
      this.imageUrl = row.video_img_url
      if (row.video_name) {
        this.video = [{ name: row.video_name, url: row.video_url }]
      }
      this.video_img = [{ name: row.video_img, url: row.video_img_url }]
      // this.showUpload = true
      // console.log('this.form-', this.form);
      this.devShow = true;

    },
    handleSuccess(response, file, fileList) {
      console.log(response, file, fileList)
      this.form.cover_img.push(response.data.name)
      // console.log(this.form.cover_img)
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList)
      this.form.cover_img = this.form.cover_img.filter(item => item !== file.response.data.name)
    },




    handleType(value) {
      console.log(value)
      // if(value === 1){
      //     this.form.video_name = null
      //     this.form.video_img = null
      // }else if(value === 2){
      //     this.form.cover_img = []
      // }
    },




    clickFalse() {
      this.devShow = false;
    },

    addZone() {
      this.form.visbile = true;
    },

    wifiPageChange(val) {
      this.listQuery.page = val;
      this.getWifiList();
    },

    getList() {
      console.log(this.form);
      this.$get('/api/advert/list', {
        page: this.page,
        page_size: this.page_size,
        type: 1,
        name: this.searchName,
      }).then(res => {
        console.log(res);
        this.agent.loading = false;
        this.list = res.data;
      }).catch(res => {
        this.loading = false;
      })
    },



    deleteClick(row) {
      // if (confirm('是否确认删除？')) {
      //   console.log(12312312);
      // }
      this.$confirm(this.$tt.t('是否删除此广告'), this.$tt.t('提示'), {
        confirmButtonText: this.$tt.t("确定"),
        cancelButtonText: this.$tt.t("取消"),
        type: "warning"
      }).then(() => {
        this.$post('/api/advert/del', {
          ids: row.id
        }).then(res => {
          this.$message({
            message: this.$tt.t("删除成功"),
            type: "success",
            center: true
          });
          this.getList()
        })
      }).catch(() => {
        // console.log("取消")
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });          
      });
    },


  }
};
</script>

<style lang="scss" scoped>
.bigImg {
  width: 200px;
  height: 200px;
}

.iCon::before {
  font-size: 30px;
  color: #1870d2;
}

.avatar-uploader .el-upload .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px solid #d9d9d9;
}

.btns-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
}

.breadcrumb-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #eee;
  padding: 10px;

  .breadcrumb-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    .name {
      font-size: 14px;
      cursor: pointer;
    }

    .name:hover {
      text-decoration: underline;
    }

    .el-icon-arrow-right {
      font-size: 14px;
      color: #9d9d9d;
      margin-left: 5px;
    }
  }

  .breadcrumb-item:not(:first-child) {
    margin-left: 5px;
  }

  .breadcrumb-item:first-child .name {
    font-weight: 500;
  }
}

.table-row:hover {
  .el-icon-edit-outline {
    display: block;
  }
}

.row-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;

  }

  .username {
    margin-left: 10px;
  }

  .el-icon-edit-outline {
    margin-left: 10px;
    display: none;
    color: #9d9d9d;
    cursor: pointer;
  }
}

.level {
  padding: 5px 10px;
  color: #fff;
}

.qrcode {
  width: 150px;
  cursor: pointer;
}

.qrcode:active {
  opacity: 0.7;
}

.fa-qrcode:hover {
  color: #ccc;
}

.fa-bluetooth {
  margin-left: 10px;
  color: #1870d2;
}

.fa-bluetooth:hover {
  opacity: 0.7;
}

.group-text {
  padding: 5px 10px;
  color: #fff;
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: 0.5em;
}

.circle:hover {
  opacity: 0.7;
}

.el-table {
  .online {
    background-color: #67c23a;
  }

  .offline {
    background-color: #f56c6c;
  }
}

.detail-view {
  width: 100%;
  display: flex;
  flex-direction: column;

  .row-view {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    padding: 10px 0;

    .title {
      width: 120px;
      text-align: right;
    }

    .content {
      width: calc(100% - 150px);
      margin-left: 30px;
      color: #888;
      display: flex;
      flex-direction: row;
      align-items: center;

      .goods-img {
        width: 50px;
        height: 50px;
      }

      .goods-name {
        margin-left: 20px;
      }

      .goods-price {
        color: #f56c6c;
        margin-left: 20px;
      }
    }

    .online {
      color: #67c23a;
    }

    .offline {
      color: #f56c6c;
    }
  }
}

.top-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    font-size: 14px;
  }
}

.online-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .online-point {
    width: 10px;
    height: 10px;
  }

  .online-title {
    font-size: 14px;
    margin-left: 10px;
  }

  .online {
    background-color: #67c23a;
  }

  .offline {
    background-color: #ccc;
  }
}

.status-online {
  color: #67c23a;
}

.status-offline {
  color: #ccc;
}
</style>