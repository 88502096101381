<template>
  <div class="content-view" v-title data-title="Wifi管理">
    <el-button type="success" @click="createGoodsClick">添加Wifi</el-button>
    <el-table :data="goods.list" style="margin-top:10px;" v-loading="goods.loading">
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column label="商品" min-width="150">
        <template slot-scope="scope">
          <div style="display:flex;flex-direction:row;align-items:center;">
            <el-image style="width:50px;height:50px;" :src="scope.row.cover_img_url" fit="fill" lazy></el-image>
            <span style="margin-left:10px;">{{scope.row.name}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="单价"></el-table-column>
      <el-table-column prop="create_time" label="创建时间"></el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="primary" size="mini"  @click="editGoodsClick(scope.row)">编辑</el-button>
          <el-button type="danger" size="mini"  @click="deleteGoodsClick(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="goods.page" :page-size="goods.pagesize" :total="goods.total" @current-change="goodsPageChange"></el-pagination>
    <el-dialog :title="goodsForm.action == 'create' ? '添加商品' : '编辑商品'" :key="goodsForm.key" width="500px" :visible.sync="goodsForm.visible" @closed="goodsFormClosed" :close-on-click-modal="false">
      <el-form :model="goodsForm" >
        <el-form-item label="商品名称" :label-width="goodsForm.labelWidth">
          <el-input v-model="goodsForm.name" placeholder="请输入商品名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="价格（元）" :label-width="goodsForm.labelWidth">
          <el-input v-model="goodsForm.price" type="number" placeholder="请输入价格" clearable></el-input>
        </el-form-item>
        <el-form-item label="成本（元）" :label-width="goodsForm.labelWidth" >
          <el-input v-model="goodsForm.cost_price" type="number" placeholder="请输入成本（成本不参与分佣金）" clearable></el-input>
        </el-form-item>
        <el-form-item label="商品分类" :label-width="goodsForm.labelWidth" >
          <el-select v-model="goodsForm.category" placeholder="请选择商品分类">
            <el-option label="饮料" value="1"></el-option>
            <el-option label="零食" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图片" :label-width="goodsForm.labelWidth">
          <el-upload class="pic-uploader" :action="action.url" :headers="action.headers" :name="action.name" :data="action.data" :show-file-list="false" :on-success="coverUploadSuccess">
            <el-image class="pic" v-if="goodsForm.cover_img_url" :src="goodsForm.cover_img_url" fit="contain" lazy></el-image>
            <i v-else class="el-icon-plus pic-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="轮播图" :label-width="goodsForm.labelWidth">
          <!-- {{goodsForm}}
          --{{goodsForm.url}} -->
          <el-upload
            list-type="picture-card"
            :file-list="goodsForm.fileList"
            :action="action.url" 
            :headers="action.headers" 
            :name="action.name" 
            :data="action.data" 
            :show-file-list="true" 
            :on-success="picUploadSuccess"
            >
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{file}">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url" alt=""
                >
                <!-- <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleDownload(file)"
                  >
                    <i class="el-icon-download"></i>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span> -->
              </div>
          </el-upload>

        </el-form-item>
        <el-form-item label="详情图片" :label-width="goodsForm.labelWidth">
          <el-upload class="pic-uploader" :action="action.url" :headers="action.headers" :name="action.name" :data="action.data" :show-file-list="false" :on-success="detailImgUploadSuccess">
            <el-image class="pic" v-if="goodsForm.detail_img_url" :src="goodsForm.detail_img_url" fit="contain" lazy></el-image>
            <i v-else class="el-icon-plus pic-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="goodsForm.visible = false">取 消</el-button>
        <el-button type="primary" :loading="goodsForm.disabled" :disabled="goodsForm.disabled" @click="goodsFormConfirmClick">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: "goods",
  data () {
    return {
      action: {
        url:'/api/goods/upload',
        headers:{
          token:sessionStorage.getItem('token')
        },
        name:'image',
      },
      goods: {
        list: [],
        page: 1,
        pagesize: 20,
        total: 0,
        loading: false
      },
      goodsForm: {
        
      },
      baseGoodsForm:{
        disabled:false,
        key:0,
        dialogTitle: "",
        visible: false,
        labelWidth: "120px",
        goodsId: "-1",
        name: "",
        price: "",
        cost_price: "",
        cover_img:'',
        detail_img:'',
        cover_img_url: "",
        detail_img_url: "",
        slider_img:[],
        fileList:[],
      },
      goods_edit: false,
      listQuery:{

      }
    };
  },
  created () {
    this.getGoodsList();
  },
  methods: {
    getGoodsList () {
      this.goods.loading = true;
      this.$get('/api/goods/list').then(res=>{
          console.log('res--',res);
          this.goods.list = res.data;
          this.goods.loading = false;
      }).catch(res=>{
        this.goods.loading = false;
      })
    },
    beforeUpload(item){
      console.log('item--',item);
    },
    goodsPageChange (val) {
      this.goods.page = val;
      this.getGoodsList();
    },
    createGoodsClick () {
      // this.goodsForm = {
      //   disabled:false,
      //   action:'create',
      //   key:0,
      //   dialogTitle: "",
      //   visible: true,
      //   labelWidth: "120px",
      //   goodsId: "-1",
      //   name: "",
      //   price: "",
      //   cover_img_url: "",
      //   detail_img_url: ""
      // };
      this.goodsForm = _.cloneDeep(this.baseGoodsForm);
      this.goodsForm.visible = true;
      this.goodsForm.action = 'create';
    },

    editGoodsClick (item) {
      this.goodsForm = {
        disabled:false,
        visible:false,
        labelWidth:'120px',
        action:"update",
        name:item.name,
        price:item.price,
        cost_price:item.cost_price,
        cover_img:item.cover_img,
        cover_img_url:item.cover_img_url,
        detail_img:item.detail_img,
        detail_img_url:item.detail_img_url,
        id:item.id,
        slider_img:item.slider_img,
        filtList:item.slider_img_urls
      };
      
      this.goodsForm.visible = true;
      this.goodsForm.key = 0;
      this.goodsForm.disabled = false;
      this.goodsForm.action = "update";
      this.goodsForm.labelWidth = '120px';
      console.log('this.goodsForm',this.goodsForm);
    },
    goodsFormConfirmClick () {
      let goodsForm = this.goodsForm;
      if (goodsForm.name == "") {
        this.$message({
          message: "请输入商品名称",
          type: "warning",
          center: true
        });
        return;
      } else if (goodsForm.price == "") {
        this.$message({
          message: "请输入价格",
          type: "warning",
          center: true
        });
        return;
      } else if (goodsForm.pic == "") {
        this.$message({
          message: "请选择图片",
          type: "warning",
          center: true
        });
        return;
      } else if (goodsForm.detailImg == "") {
        this.$message({
          message: "请选择详情图",
          type: "warning",
          center: true
        });
        return;
      }
      let params = {
        name:goodsForm.name,
        price:goodsForm.price,
        cost_price:goodsForm.cost_price,
        cover_img:goodsForm.cover_img,
        slider_img:goodsForm.slider_img.length>0?goodsForm.slider_img.join(','):'',
        detail_img:goodsForm.detail_img,
      };

      this.goodsForm.disabled = true;
      if(this.goodsForm.action == 'create'){
        this.$post('/api/goods/add',params).then(res=>{
          this.goodsForm.visible = false;
          this.goodsForm.disabled = false;
          location.reload();
        }).catch(res=>{
          this.goodsForm.disabled = false;
        });
      }else{
        params.id = this.goodsForm.id;
        this.$post('/api/goods/update',params).then(res=>{
          this.goodsForm.disabled = false;
          this.goodsForm.visible = false;
          location.reload();
        }).catch(res=>{
          this.goodsForm.disabled = false;
        });
      }
      
    },
    picUploadSuccess (res, file) {
      // console.log('picUploadSuccess',res);

      
      this.goodsForm.slider_img.push(res.data.name);
      // console.log('goodsForm.fileList--',this.goodsForm.fileList);

    },
    coverUploadSuccess(res, file){
      console.log('coverUploadSuccess',res.data);
      this.goodsForm.cover_img_url = res.data.url;
      this.goodsForm.cover_img = res.data.name;
      console.log('cover_img',res.data.name);
      // console.log('this.goodsForm.cover_img_url',this.goodsForm.cover_img_url);
    },
    detailImgUploadSuccess (res, file) {
      // console.log('detailImgUploadSuccess',res);

      this.goodsForm.detail_img_url = res.data.url;
      this.goodsForm.detail_img = res.data.name;
      // console.log('this.goodsForm.detail_img_url',this.goodsForm.detail_img_url);

    },
    goodsFormClosed () {
      this.goodsForm.goodsId = "-1";
      this.goodsForm.name = "";
      this.goodsForm.price = "";
      this.goodsForm.cost = "";
      this.goodsForm.unit_title = "";
      this.goodsForm.pic = "";
      this.goodsForm.detailImg = "";
      this.goodsForm.allowFree.checked = false;
      this.goodsForm.allowPay.checked = false;
    },
    deleteGoodsClick (id) {
      this.$confirm("是否删除该商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$post('/api/goods/del',{
            ids:id
          }).then(res=>{
            this.$message({
              message: "删除成功",
              type: "success",
              center: true
            });
            location.reload();
          })
        })
        .catch(() => { });
    },
    
  }
};
</script>

<style lang="scss" scoped>
.pic-uploader {
  width: 178px;
  height: 178px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  overflow: hidden;
}

.pic-uploader:hover {
  border-color: #409eff;
}

.pic-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
}

.pic {
  width: 100%;
  height: 100%;
}
</style>