<template>
  <div class="index">
    <el-container style="height:100vh;">
      <el-aside width="200px">
        <el-menu :default-active="active" class="menu" background-color="#00152a" text-color="#eee"
          active-text-color="#0578FF" style="text-align:left;" router>

          <el-menu-item index="stats">
            <!-- <template slot="title">
                <i class="iconfont icon-shebei-diannao"></i>
                首页
            </template> -->
            <div class="icon-view">
              <i class="iconfont icon-shebei-diannao"></i>
            </div>
            <span>{{ $t('sidebar.home') }}</span>
          </el-menu-item>
          <el-menu-item index="order">
            {{ $t('sidebar.order_management') }}
          </el-menu-item>
          <el-menu-item index="device">{{ $t('sidebar.device_management') }}</el-menu-item>
          <el-submenu index='4' v-if="agent.info.superior_id == 0">
            <template slot="title">{{ $t('sidebar.model_management') }}</template>
            <el-menu-item index="TypeIndex">{{ $t('sidebar.model_management') }}</el-menu-item>
            <el-menu-item index="TypeEdit">{{ $t('sidebar.add_model') }}</el-menu-item>
          </el-submenu>
          <el-submenu index='2'>
            <template slot="title">{{ $t('sidebar.goods_management') }}</template>
            <el-menu-item index="goods">{{ $t('sidebar.goods_list') }}</el-menu-item>
            <el-menu-item index="category">{{ $t('sidebar.goods_classify') }}</el-menu-item>

          </el-submenu>
          <el-submenu index='3'>
            <template slot="title">{{ $t('sidebar.advert_management') }}</template>
            <el-menu-item index="Examine" v-if="agent.info.superior_id == 0">{{ $t('sidebar.advert_examine')
              }}</el-menu-item>
            <el-menu-item index="HomeAD">{{ $t('sidebar.home_advert') }}</el-menu-item>
            <el-menu-item index="Standby">{{ $t('sidebar.standby_advert') }}</el-menu-item>
            <el-menu-item index="StandbyGroup">{{ $t('sidebar.standbyGroup') }}</el-menu-item>
            <!-- <el-menu-item index="Member">会员</el-menu-item> -->
          </el-submenu>
          <!-- <el-submenu index='1'>
            <template slot="title">{{ $t('sidebar.coupon_management') }}</template>
            <el-menu-item index="DiscountCoupon">{{ $t('sidebar.coupon') }}</el-menu-item> -->
            <!-- <el-menu-item index="PrizeCoupon">{{ $t('sidebar.Lottery_List') }}</el-menu-item> -->

          <!-- </el-submenu> -->
          <el-menu-item index="DiscountCoupon">
            <span>{{ $t('sidebar.coupon') }}</span>
          </el-menu-item>

          

          <!-- <el-submenu index="2">
            <template slot="title">
              <i class="iconfont icon-a-xinpian_chip1"></i>
                设备管理
            </template>
            <el-menu-item index="mdevice">我的设备</el-menu-item>
            <el-menu-item index="cdevice">下级设备</el-menu-item>
          </el-submenu> -->
          <el-menu-item index="agent" v-if="agent.info.superior_id == 0">
            <span>{{ $t('sidebar.Subordinate_agents') }}</span>
          </el-menu-item>

          <el-menu-item index="zone">
            <span>{{ $t('sidebar.site_management') }}</span>
          </el-menu-item>
          <!-- <el-menu-item index="wifi">
                <span>Wifi管理</span>
              </el-menu-item> -->
          <el-menu-item index="Member" v-if="agent.info.superior_id == 0">
            <span>{{ $t('sidebar.user_list') }}</span>
          </el-menu-item>


          <el-menu-item index="commission">
            <span>{{ $t('sidebar.commission_management') }}</span>
          </el-menu-item>
          <el-menu-item index="Reviewe" v-if="agent.info.superior_id == 0">
            <span>{{ $t('sidebar.agent_reviewe') }}</span>
          </el-menu-item>
          <el-menu-item index="staff">
            <span>{{ $t('sidebar.staff') }}</span>
          </el-menu-item>
          <el-menu-item index="Feedback">
            <span>{{ $t('sidebar.Feedback') }}</span>
          </el-menu-item>
          <el-menu-item index="SetHeadImg">
            <span>{{ $t('sidebar.set') }}</span>
          </el-menu-item>
        </el-menu>
      </el-aside>

      <el-container>

        <el-header height="80px">

          <span class="web-name">{{ $t('sidebar.Dealer_backend_system') }}</span>

          <el-popover placement="bottom" trigger="hover" class="mobile-popover">
            <!-- <img style="width:150px;" :src="qrcode" /> -->
            <!-- <div class="mobile-view" slot="reference">
              <i class="fa fa-mobile"></i>
              <span class="title">手机端</span>
            </div> -->
          </el-popover>
          
          <!-- <el-dropdown @command="changeLanguage" style="display: flex;margin-right:8%">
            <div class="el-dropdown-link avatar">
              {{ language }}
              <i class="el-icon-caret-bottom" style="font-size: 20px;"></i>

            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="zh">简体中文</el-dropdown-item>
              <el-dropdown-item command="en">English</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->

          <el-dropdown style="display: flex;">
            <!-- <div class="marquee-container" style="width: 500px;">
              <div class="marquee" style=" font-size: 15px;color:red;">
                提示： 接上级管理部门通知，信息发布系统需依法采取"先审后发"安全管理措施， 平台已启用"先审后发"机制,
                新上传编辑的素材及广告节目需等待审核通过后才能使用、发布。因业务量级关系，常规审核预计10分钟左右时间完成，部分大的音视频相应需更长等待时间，感谢理解！
              </div>
            </div> -->
            <div class="avatar">
              <el-avatar :src="avatar" :size="30" v-if="avatar"></el-avatar>
              <el-avatar icon="el-icon-user-solid" :size="30" v-else></el-avatar>
              <div class="agent-name">{{ agent.info.name }}</div>
              <i class="el-icon-caret-bottom" style="margin-left:5px;"></i>

            </div>

            <el-dropdown-menu slot="dropdown">

              <el-dropdown-item @click.native="resetPassword">{{ $t('sidebar.login_password') }}</el-dropdown-item>
              <el-dropdown-item @click.native="logout">{{ $t('sidebar.logout') }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

        </el-header>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
    <el-dialog :title="$t('sidebar.login_password')" :visible.sync="agent.visible" width="500px">
      <el-form :model="agent.data" label-width="auto">
        <el-form-item :label="$t('name')">
          {{ agent.data.name }}
        </el-form-item>
        <el-form-item :label="$t('account')">
          <el-input v-model="agent.data.mobile" readonly></el-input>
        </el-form-item>
        <el-form-item :label="$t('new_password')">
          <el-input type="password" v-model="agent.data.password" :placeholder="$t('plz_newPassword')"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="" @click="agent.visible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="doSubmit">{{ $t('save') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  name: "index",
  data() {
    return {
      language: localStorage.getItem('lang') == 'zh' ? '简体中文' : 'English',
      lang: localStorage.getItem('lang'),
      active: "stats",
      agent: {
        visible: false,
        info: {},
        data: {}
      },
      editDialogFormVisible: false,
      name: "",
      editForm: {
        mobile: "",
        email: "",
        company: "",
        address: "",
        password: ""
      },
      formLabelWidth: "120px",
      avatar: null
    };
  },
  computed: {
    menu() {
      return [
        {
          title: "平台首页",
          icon: "iconfont icon-bijibendiannaolaptop",
          name: "stats"
        },
        {
          title: "收入统计",
          icon: "iconfont icon-jisuanqi",
          name: "income"
        },
        {
          title: "订单管理",
          icon: "iconfont icon-31liebiao",
          name: "order",
          child: [
            { title: "我的订单", icon: "", name: "" },
            { title: "下级订单", icon: "", name: "" }
          ]
        },
        {
          title: "设备管理",
          icon: "iconfont icon-a-xinpian_chip1",
          name: "device",
          child: [
            { title: "我的设备", icon: "", name: "" },
            { title: "下级设备", icon: "", name: "" }
          ]
        },
        {
          title: "缺货统计",
          icon: "iconfont icon-biaoqian",
          name: "low_statistics"
        },
      ];
    }
  },
  watch: {
    $route(to, from) {
      console.log('route', to.name);
      this.active = to.name;

    }
  },
  created() {
    // console.log('this.$route.name',this.$route.name);
    if (this.$route.name == "index") {
      this.$router.replace("stats");
    } else {
      this.active = this.$route.name;
    }
    this.avatar = sessionStorage.getItem("avatar");
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    console.log('userInfo', userInfo);
    this.agent.info = userInfo;
    console.log('this.agent', this.agent);

  },
  methods: {
    changeLanguage(lang) {
      if (lang == 'zh') {
        this.language = '简体中文';
      } else {
        this.language = 'English';
      }
      // 更新 VueI18n 实例的 locale 属性
      this.$i18n.locale = lang;
      // 同时将语言标识存储到 localStorage 中以保持用户选择的语言状态
      localStorage.setItem('lang', lang);
    },
    logout() {
      this.$router.replace({ name: "login" });
      sessionStorage.removeItem("token");
    },
    resetPassword() {
      this.agent.visible = true;
      console.log('this.agent.info', this.agent.info);
      this.agent.data = {
        name: this.agent.info.name,
        mobile: this.agent.info.mobile,
        old_password: '',
        password: '',
      };
    },
    doSubmit() {
      this.$post('/api/agent/update', {
        mobile: this.agent.data.mobile,
        password: this.agent.data.password
      }).then(res => {
        this.$message({ 'message': '修改成功', 'type': 'success' });
        this.agent.visible = false;
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.marquee-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.marquee {
  display: inline-block;
  animation: marquee 15s linear infinite;
  /* 将动画持续时间改为20秒，调整速度 */
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.el-header {
  background-color: #fff;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  .web-name {
    font-size: 28px;
    font-weight: bold;
  }

  .mobile-popover {
    position: absolute;
    right: 170px;

    .mobile-view {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #888;
      cursor: pointer;

      .fa-mobile {
        font-size: 26px;
      }

      .title {
        font-size: 12px;
      }
    }

    .mobile-view:hover {
      opacity: 0.7;
    }
  }

  .el-dropdown {
    position: absolute;
    right: 50px;
  }

  .avatar {
    display: flex;
    flex-direction: row;
    align-items: center;

    .agent-name {
      max-width: 70px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.el-aside {
  background-color: #00152a;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;

  .menu {
    width: 100%;

    .el-menu-item {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .icon-view {
      font-size: 16px;
      width: 20px;
      height: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0 5px 0 0px;
    }
  }
}

.el-main {
  background-color: #f5f7f9;
}
</style>
