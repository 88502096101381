<template>
  <div class="content-view" v-title :data-title="$t('佣金管理')">
    <div  v-if="userInfo.superior_id == 0" class="top-view">
      <div style="font-size:16px;" v-loading="balance.loading">
        {{$t('收入明细')}}：
        <span style="font-size: 30px;font-weight: bloder;">{{ balance.value }}</span>{{$t('元')}}
      </div>
      <!-- <el-button type="success" style="margin-left:30px;" size="medium" @click="withdraw.visible = true">代理体现</el-button>
      <el-button type="" @click="showRecord" size="medium">记录</el-button> -->
      <!-- <el-button type="primary" size="medium" @click="bankClick">银行卡</el-button>
      <el-button type="warning" size="medium" @click="collectionClick">收款码</el-button> -->
    </div>
    <div v-else-if="userInfo.superior_id !== 0" class="top-view">
      <div style="font-size:16px;" v-loading="balance.loading">
        {{$t('账户余额')}}：
        <span style="font-size: 30px;font-weight: bloder;">{{ balance.value }}</span>{{$t('元')}}
      </div>
      <el-button type="success" style="margin-left:30px;" size="medium" @click="withdraw.visible = true">{{$t('提现')}}</el-button>
      <el-button type="" @click="showRecord" size="medium">{{$t('记录')}}</el-button>
      <!-- <el-button type="primary" size="medium" @click="bankClick">银行卡</el-button>
      <el-button type="warning" size="medium" @click="collectionClick">收款码</el-button> -->
    </div>

    <el-dialog :title="$t('提现记录')" :visible.sync="record.visible">
      <el-table :data="withdrawalList">
        <el-table-column type="index"></el-table-column>
        <el-table-column :label="$t('状态')">
          <template slot-scope="scope">
            <!-- 佣金提现 -->
            {{ scope.row.status_txt }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('申请人')" prop="target_mobile">
        </el-table-column>
        <el-table-column :label="$t('创建时间')" prop="create_time">
        </el-table-column>
        <el-table-column :label="$t('金额')">
          <template slot-scope="scope">
            <span style="color:#f35150">{{ scope.row.amount }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- <el-tabs
      type="border-card"
      v-model="record.type"
      style="margin-top:20px;"
      @tab-click="tabsClick"
    > -->
    <!-- <el-tab-pane label="收入" name="incr"></el-tab-pane>
      <el-tab-pane label="支出" name="decr"></el-tab-pane> -->
    <el-table :data="record.list" style="width: 100%" v-loading="record.loading">
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column prop="title" :label="$t('类型')" min-width="150">
        <template slot-scope="scope">
          {{ scope.row.type == 0 ? $t('收入') : $t('支出') }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('金额')" min-width="100">
        <template slot-scope="scope">
          <span :class="{ 'incr': scope.row.x_val > 0, 'decr': scope.row.x_val < 0 }">{{ scope.row.x_val }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" :label="$t('资金状态')" min-width="200">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1" style="color:#67C23A">{{$t('到账')}}</span>
          <span v-else style="color:#909399">{{$t('冻结')}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" :label="$t('创建时间')" min-width="200">
        <template slot-scope="scope">
          {{ parseTime(scope.row.create_time) }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="record.page"
      :page-size="record.pagesize" :total="record.total" @current-change="recordPageChange"></el-pagination>
    <!-- </el-tabs> -->
    <el-dialog :title="$t('余额提现')" :visible.sync="withdraw.visible" width="500px" @closed="withdrawClosed">
      <el-form :model="withdraw">
        <el-form-item :label="$t('账户余额')" :label-width="withdraw.formLabelWidth">
          <span style="font-size: 30px;font-weight: bloder;">{{ balance.value }}</span>
          <span>{{$t('元')}}</span>
        </el-form-item>
        <el-form-item :label="$t('提现金额')" :label-width="withdraw.formLabelWidth">
          <div class="row-view">
            <el-input v-model="withdraw.amount" :placeholder="$t('请输入提现金额（单位：元）')" maxlength="8" clearable
              @input="amountInput"></el-input>
            <el-button type="text" size="mini" style="margin-left: 20px;" @click="withdrawAllClick">{{$t('全部提现')}}</el-button>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="withdraw.visible = false">{{$t('取消')}}</el-button>
        <el-button type="success" @click="withdrawClick">{{$t('提现')}}</el-button>
      </div>
    </el-dialog>
    <!-- <el-dialog title="我的银行卡" :visible.sync="bank.visible" width="500px">
      <el-form :model="bank" v-loading="bank.loading">
        <el-form-item label="真实姓名" :label-width="bank.formLabelWidth">
          <el-input v-model="bank.realname" placeholder="请输入真实姓名" clearable></el-input>
        </el-form-item>
        <el-form-item label="开户行" :label-width="bank.formLabelWidth">
          <el-input v-model="bank.bank" placeholder="请输入开户行" clearable></el-input>
        </el-form-item>
        <el-form-item label="开户省份" :label-width="bank.formLabelWidth">
          <el-input v-model="bank.province" placeholder="请输入开户省份" clearable></el-input>
        </el-form-item>
        <el-form-item label="开户城市" :label-width="bank.formLabelWidth">
          <el-input v-model="bank.city" placeholder="请输入开户城市" clearable></el-input>
        </el-form-item>
        <el-form-item label="开户支行" :label-width="bank.formLabelWidth">
          <el-input v-model="bank.branch" placeholder="请输入开户支行" clearable></el-input>
        </el-form-item>
        <el-form-item label="银行卡号" :label-width="bank.formLabelWidth">
          <el-input v-model="bank.account" placeholder="请输入银行卡号" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="bank.visible = false">取 消</el-button>
        <el-button type="primary" @click="setAgentBank">确 定</el-button>
      </div>
    </el-dialog> -->
    <!-- <el-dialog title="我的收款码" :visible.sync="collection.visible" width="400px">
      <div class="collection-view">
        <span class="title">微信收款码：</span>
        <el-upload
          class="pic-uploader"
          :action="action.url"
          :headers="action.headers"
          :name="action.name"
          :data="action.wxData"
          :show-file-list="false"
          :on-success="picUploadSuccess"
        >
          <el-image class="pic" v-if="collection.wx" :src="collection.wx" fit="contain" lazy></el-image>
          <i v-else class="el-icon-plus pic-uploader-icon"></i>
        </el-upload>
      </div>
      <div class="collection-view" style="margin-top:20px;">
        <span class="title">支付宝收款码：</span>
        <el-upload
          class="pic-uploader"
          :action="action.url"
          :headers="action.headers"
          :name="action.name"
          :data="action.aliData"
          :show-file-list="false"
          :on-success="picUploadSuccess"
        >
          <el-image class="pic" v-if="collection.ali" :src="collection.ali" fit="contain" lazy></el-image>
          <i v-else class="el-icon-plus pic-uploader-icon"></i>
        </el-upload>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
// import base from "../request/base.js";
export default {
  name: "commission",
  data() {
    return {
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      // action: {
      //   url: base.url,
      //   headers: {
      //     "LLT-API": "v1"
      //   },
      //   name: "pic",
      //   wxData: {
      //     op: "UpdateUserQRcode",
      //     token: sessionStorage.getItem("token"),
      //     type: "wx"
      //   },
      //   aliData: {
      //     op: "UpdateUserQRcode",
      //     token: sessionStorage.getItem("token"),
      //     type: "ali"
      //   }
      // },
      withdrawalList: [],
      balance: {
        value: "0.00",
        loading: false
      },
      record: {
        list: [],
        page: 1,
        pagesize: 20,
        total: 0,
        type: "incr",
        loading: false
      },
      withdraw: {
        visible: false,
        formLabelWidth: "120px",
        amount: ""
      },
      bank: {
        visible: false,
        formLabelWidth: "120px",
        loading: false,
        realname: "",
        bank: "",
        province: "",
        city: "",
        branch: "",
        account: ""
      },
      collection: {
        visible: false,
        wx: null,
        ali: null
      },
      withdraw: {
        visible: false,
        formLabelWidth: "120px",
        amount: ""
      },
      record: {
        visible: false,
        list: []
      }
    };
  },
  created() {
    // this.getBalanceBrief();
    // this.getRecordList();
    this.getAgentInfo();
    this.getBalanceList();
  },
  methods: {
    showRecord() {
      this.record.visible = true;
      this.getRecord();
    },
    getRecord() {
      this.$get('/api/agent/withdrawList').then(res => {
        this.withdrawalList = res.data;
      })
    },
    getBalanceBrief() {
      this.balance.loading = true;
      let params = {
        op: "balanceBrief"
      };
      // this.$api.commission
      //   .getBalanceBrief(params)
      //   .then(res => {
      //     this.balance.loading = false;
      //     if (res.data.status) {
      //       this.balance.value = res.data.data.balance.total;
      //     } else {
      //       this.$alert(res.data.data.msg, "提示");
      //     }
      //   })
      //   .catch(() => {
      //     this.balance.loading = false;
      //   });
    },
    getBalanceList() { // 佣金明细列表
      this.$get('/api/order/balanceList').then(res => {
        this.record.list = res;
      })
    },
    getAgentInfo() {
      console.log('token--', sessionStorage.getItem('token'));
      this.$get('/api/agent/info', { token: sessionStorage.getItem('token') }).then(res => {
        this.balance.value = res.info.balance;
      });
    },
    getRecordList() {
      // this.record.loading = true;

      // let params = {
      //   op: "balanceLog",
      //   page: this.record.page,
      //   pagesize: this.record.pagesize,
      //   type: this.record.type
      // };
      // this.$api.commission
      //   .getRecordList(params)
      //   .then(res => {
      //     this.record.loading = false;
      //     if (res.data.status) {
      //       this.record.list = res.data.data.list;
      //       this.record.total = res.data.data.total;
      //     } else {
      //       this.$alert(res.data.data.msg, "提示");
      //     }
      //   })
      //   .catch(() => {
      //     this.record.loading = false;
      //   });
    },
    tabsClick() {
      this.record.page = 1;
      this.getRecordList();
    },
    recordPageChange(val) {
      this.record.page = val;
      this.getRecordList();
    },
    withdrawAllClick() {
      this.withdraw.amount = this.balance.value;
    },
    withdrawClick() {
      // let params = {
      //   op: "balanceWithdraw",
      //   amount: this.withdraw.amount
      // };
      // this.$api.commission.balanceWithdraw(params).then(res => {
      //   if (res.data.status) {
      //     this.$message({
      //       message: res.data.data.msg,
      //       type: "success",
      //       center: true
      //     });
      //     this.withdraw.visible = false;
      //     this.getBalanceBrief();
      //   } else {
      //     this.$alert(res.data.data.msg, "提示");
      //   }
      // });
      this.$post('/api/agent/withdraw', {
        amount: this.withdraw.amount
      }).then(res => {
        this.$message({ message: '提交成功', type: 'success' });
        this.withdraw.visible = false;
        this.getBalanceList();
      }).catch(err => { });
    },
    amountInput(val) {
      this.withdraw.amount = this.inputCheck(val).formatted;
    },
    withdrawClosed() {
      this.withdraw.amount = "";
    },
    bankClick() {
      this.bank.visible = true;
      this.getAgentBank();
    },
    getAgentBank() {
      this.bank.loading = true;
      let params = {
        op: "getAgentBank"
      };
      // this.$api.commission
      //   .getAgentBank(params)
      //   .then(res => {
      //     this.bank.loading = false;
      //     if (res.data.status) {
      //       this.bank.realname = res.data.data.realname;
      //       this.bank.bank = res.data.data.bank;
      //       this.bank.province = res.data.data.address.province;
      //       this.bank.city = res.data.data.address.city;
      //       this.bank.branch = res.data.data.branch;
      //       this.bank.account = res.data.data.account;
      //     } else {
      //       this.$alert(res.data.data.msg, "提示");
      //     }
      //   })
      //   .catch(() => {
      //     this.bank.loading = false;
      //   });
    },
    setAgentBank() {
      if (this.bank.realname == "") {
        this.$message({
          message: "请输入真实姓名",
          type: "warning",
          center: true
        });
        return;
      } else if (this.bank.bank == "") {
        this.$message({
          message: "请输入开户行",
          type: "warning",
          center: true
        });
        return;
      } else if (this.bank.province == "") {
        this.$message({
          message: "请输入开户省份",
          type: "warning",
          center: true
        });
        return;
      } else if (this.bank.city == "") {
        this.$message({
          message: "请输入开户城市",
          type: "warning",
          center: true
        });
        return;
      } else if (this.bank.branch == "") {
        this.$message({
          message: "请输入开户支行",
          type: "warning",
          center: true
        });
        return;
      } else if (this.bank.account == "") {
        this.$message({
          message: "请输入银行卡号",
          type: "warning",
          center: true
        });
        return;
      }
      let params = {
        op: "setAgentBank",
        realname: this.bank.realname,
        bank: this.bank.bank,
        province: this.bank.province,
        city: this.bank.city,
        branch: this.bank.branch,
        account: this.bank.account
      };
      // this.$api.commission.setAgentBank(params).then(res => {
      //   if (res.data.status) {
      //     this.$message({
      //       message: res.data.data.msg,
      //       type: "success",
      //       center: true
      //     });
      //     this.bank.visible = false;
      //   } else {
      //     this.$alert(res.data.data.msg, "提示");
      //   }
      // });
    },
    /**
     * 带小数点输入框的监听处理
     */
    inputCheck: function (m) {
      const res = {
        org: m,
        formatted: "",
        limit: 8
      };
      if (m.length) {
        const p = m.split(".", 2);
        if (p[0] == "") {
          res.formatted = 0;
        } else {
          let h = parseInt(p[0]);
          if (!isNaN(h)) {
            res.formatted = h + "";
          } else {
            return res;
          }
        }
        if (p.length > 1) {
          res.formatted = res.formatted + ".";
          for (let i = 0; i < p[1].length && i < 2; i++) {
            if (!isNaN(p[1][i])) {
              res.formatted = res.formatted + p[1][i];
            } else {
              break;
            }
          }
          const pos = res.formatted.indexOf(".");
          if (pos != -1) {
            res.limit = Math.min(res.limit, pos + 3);
          }
        }
      }
      return res;
    },
    collectionClick() {
      this.collection.visible = true;
      this.getUserQRcode();
    },
    getUserQRcode() {
      let params = {
        op: "getUserQRcode"
      };
      // this.$api.commission.getUserQRcode(params).then(res => {
      //   if (res.data.status) {
      //     this.collection.wx = res.data.data.wx || null;
      //     this.collection.ali = res.data.data.ali || null;
      //   } else {
      //     this.$alert(res.data.data.msg, "提示");
      //   }
      // });
    },
    picUploadSuccess(res) {
      this.$message({
        message: res.data.msg,
        type: "success",
        center: true
      });
      this.getUserQRcode();
    }
  }
};
</script>

<style lang="scss" scoped>
.incr {
  color: #67c23a;
}

.decr {
  color: #f56c6c;
}

.row-view {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.collection-view {
  width: 100%;
  display: flex;
  flex-direction: row;

  .title {
    width: 120px;
  }

  .pic-uploader {
    width: 178px;
    height: 178px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    overflow: hidden;
  }

  .pic-uploader:hover {
    border-color: #409eff;
  }

  .pic-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
  }

  .pic {
    width: 100%;
    height: 100%;
  }
}
</style>