<template>
  <div class="content-view" v-title :data-title="$t('商品管理')">
    <el-button type="success" @click="createGoodsClick">{{$t('添加商品')}}</el-button>
    <el-table :data="goods.list" style="margin-top:10px;" v-loading="goods.loading">
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column :label="$t('商品')" min-width="150">
        <template slot-scope="scope">
          <div style="display:flex;flex-direction:row;align-items:center;">
            <el-image style="width:50px;height:50px;" :src="scope.row.cover_img_url" fit="fill" lazy></el-image>
            <span style="margin-left:10px;">{{ scope.row.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="price" :label="$t('单价')"></el-table-column>
      <el-table-column prop="create_time" :label="$t('创建时间')"></el-table-column>
      <el-table-column :label="$t('操作')" width="200">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="editGoodsClick(scope.row)">{{$t('编辑')}}</el-button>
          <el-button type="danger" size="mini" @click="deleteGoodsClick(scope.row.id)">{{$t('删除')}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="goods.page"
      :page-size="goods.pagesize" :total="goods.total" @current-change="goodsPageChange"></el-pagination>
    <el-dialog :title="goodsForm.action == 'create' ? $t('添加商品') : $t('编辑商品')" :key="goodsForm.key" width="500px"
      :visible.sync="goodsForm.visible" @closed="goodsFormClosed" :close-on-click-modal="false">
      <el-form :model="goodsForm">
        <el-form-item :label="$t('商品名称')" :label-width="goodsForm.labelWidth">
          <el-input v-model="goodsForm.name" :placeholder="$t('请输入商品名称')" clearable></el-input>

        </el-form-item>
        <el-form-item :label="$t('价格（元）')" :label-width="goodsForm.labelWidth">
          <el-input v-model="goodsForm.price" type="number" :placeholder="$t('请输入价格')" clearable></el-input>
        </el-form-item>
        <el-form-item :label="$t('成本（元）')" :label-width="goodsForm.labelWidth">
          <el-input v-model="goodsForm.cost_price" type="number" :placeholder="$t('请输入成本（成本不参与分佣金）')" clearable></el-input>
        </el-form-item>
        <el-form-item :label="$t('单位')" :label-width="goodsForm.labelWidth">
          <el-input v-model="goodsForm.unit" :placeholder="$t('请输入商品单位')" maxlength="5" style="width:217px" clearable></el-input>
          <el-checkbox v-model="goodsForm.refrigeration">{{$t('冷藏')}}</el-checkbox>
        </el-form-item>
        <el-form-item v-if="goodsForm.refrigeration" :label="$t('推荐加热时长（分）')" :label-width="goodsForm.labelWidth">
          <el-input v-model="goodsForm.duration" type="number"  maxlength="3" style="width:217px"  placeholder="请输入加热时长" clearable></el-input>
        </el-form-item>
        <el-form-item :label="$t('商品分类')" :label-width="goodsForm.labelWidth">
          <el-select v-model="goodsForm.category_id" :placeholder="$t('请选择商品分类')" filterable>
            <el-option v-for="(item, index) in categoryList" :label="item.name" :value="item.id" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('图片')" :label-width="goodsForm.labelWidth">
          <el-upload class="pic-uploader" :action="action.url" :headers="action.headers"
            :name="action.name" :data="action.data" :show-file-list="false" :on-success="coverUploadSuccess">
            <el-image class="pic" v-if="goodsForm.cover_img_url" :src="goodsForm.cover_img_url" fit="contain"
              lazy></el-image>
            <i v-else class="el-icon-plus pic-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <!-- <el-form-item label="轮播图" :label-width="goodsForm.labelWidth">
          <div>
            <el-upload v-for="(item, index) in goodsForm.fileList" :key="index" class="pic-uploader"
              :action="action.url" :headers="action.headers" :name="action.name" :data="action.data"
              :show-file-list="false" :on-success="picUploadSuccess">
              <div style="position:relative">
                <div>
                  <el-image class="pic" :src="item.url" fit="contain" lazy></el-image>
                </div>
                <div style="position: absolute;z-index:999;">
                  <i class="el-icon-delete" @click="deleteImg(index)"></i>
                </div>
              </div>

            </el-upload>
          </div>
          <div>
            <el-upload class="pic-uploader" :action="action.url" :headers="action.headers"
              :name="action.name" :data="action.data" :show-file-list="false" :on-success="picUploadSuccess">
              <i class="el-icon-plus pic-uploader-icon"></i>
            </el-upload>
          </div> -->
        <!-- </el-form-item> -->
        <el-form-item :label="$t('详情图片')" :label-width="goodsForm.labelWidth">
          <el-upload class="pic-uploader" :action="action.url" :headers="action.headers"
            :name="action.name" :data="action.data" :show-file-list="false" :on-success="detailImgUploadSuccess">
            <el-image class="pic" v-if="goodsForm.detail_img_url" :src="goodsForm.detail_img_url" fit="contain"
              lazy></el-image>
            <i v-else class="el-icon-plus pic-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="goodsForm.visible = false">{{$t('取 消')}}</el-button>
        <el-button type="primary" :loading="goodsForm.disabled" :disabled="goodsForm.disabled"
          @click="goodsFormConfirmClick">{{$t('确 定')}}</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: "goods",
  data() {
    return {
      action: {
        url: process.env.VUE_APP_BASE_API + 'api/goods/upload',
        headers: {
          authorization: "Bearer " + sessionStorage.getItem('token')
        },
        name: 'image',
      },
      goods: {
        list: [],
        page: 1,
        pagesize: 20,
        total: 0,
        loading: false
      },
      goodsForm: {
        refrigeration: true
      },
      baseGoodsForm: {
        disabled: false,
        key: 0,
        dialogTitle: "",
        visible: false,
        labelWidth: "120px",
        goodsId: "-1",
        name: "",
        price: "",
        refrigeration: false,
        cost_price: "",
        cover_img: '',
        detail_img: '',
        duration: 0,
        cover_img_url: "",
        detail_img_url: "",
        slider_img: [],
        fileList: [],
      },
      goods_edit: false,
      listQuery: {

      },
      categoryList: []
    };
  },
  created() {
    this.getGoodsList();
    this.getCategory();
  },
  methods: {
    deleteImg(i) {
      console.log('');
      this.goodsForm.fileList.splice(i, 1);
      this.goodsForm.slider_img.splice(i, 1);
    },
    getGoodsList() {
      this.goods.loading = true;
      this.$get('/api/goods/list').then(res => {
        console.log('res--', res);
        this.goods.list = res.data;
        this.goods.loading = false;
      }).catch(res => {
        this.goods.loading = false;
      })
    },
    beforeUpload(item) {
      console.log('item--', item);
    },
    goodsPageChange(val) {
      this.goods.page = val;
      this.getGoodsList();
    },
    createGoodsClick() {
      // this.goodsForm = {
      //   disabled:false,
      //   action:'create',
      //   key:0,
      //   dialogTitle: "",
      //   visible: true,
      //   labelWidth: "120px",
      //   goodsId: "-1",
      //   name: "",
      //   price: "",
      //   cover_img_url: "",
      //   detail_img_url: ""
      // };
      this.goodsForm = _.cloneDeep(this.baseGoodsForm);
      this.goodsForm.visible = true;
      this.goodsForm.action = 'create';
    },
    getCategory() {
      this.$get('/api/category/list', { page: 1, page_size: 99 }).then(res => {
        this.categoryList = res.data;
      })
    },
    editGoodsClick(item) {
      console.log('editGoodsClick', item);
      this.goodsForm = {
        disabled: false,
        visible: false,
        labelWidth: '120px',
        action: "update",
        name: item.name,
        price: item.price,
        cost_price: item.cost_price,
        cover_img: item.cover_img,
        refrigeration: item.refrigeration == 1 ? true : false,
        duration:item.duration,
        cover_img_url: item.cover_img_url,
        detail_img: item.detail_img,
        detail_img_url: item.detail_img_url,
        category_id: item.category_id,
        id: item.id,
        slider_img: item.slider_img.split(","),
        fileList: item.slider_img_urls,
        unit: item.unit
      };

      this.goodsForm.visible = true;
      this.goodsForm.key = 0;
      this.goodsForm.disabled = false;
      this.goodsForm.action = "update";
      this.goodsForm.labelWidth = '120px';

      console.log('this.goodsForm', this.goodsForm);
    },
    goodsFormConfirmClick() {
      let goodsForm = this.goodsForm;
      // return console.log(goodsForm);

      if (goodsForm.name == "") {
        this.$message({
          message: "请输入商品名称",
          type: "warning",
          center: true
        });
        return;
      } else if (goodsForm.price == "") {
        this.$message({
          message: "请输入价格",
          type: "warning",
          center: true
        });
        return;
      } else if (goodsForm.pic == "") {
        this.$message({
          message: "请选择图片",
          type: "warning",
          center: true
        });
        return;
      } else if (goodsForm.detailImg == "") {
        this.$message({
          message: "请选择详情图",
          type: "warning",
          center: true
        });
        return;
      }
      console.log('goodsForm', goodsForm);
      let params = {
        name: goodsForm.name,
        price: goodsForm.price,
        cost_price: goodsForm.cost_price,
        cover_img: goodsForm.cover_img,
        refrigeration: goodsForm.refrigeration,
        slider_img: goodsForm.slider_img.length > 0 ? goodsForm.slider_img.join(',') : '',
        detail_img: goodsForm.detail_img,
        category_id: goodsForm.category_id,
        unit: goodsForm.unit,
        duration:goodsForm.duration
      };
      console.log('params', params);
      this.goodsForm.disabled = true;
      if (this.goodsForm.action == 'create') {
        this.$post('/api/goods/add', params).then(res => {
          this.goodsForm.visible = false;
          this.goodsForm.disabled = false;
          // location.reload();
          this.getGoodsList();
        }).catch(res => {
          this.goodsForm.disabled = false;
        });
      } else {
        params.id = this.goodsForm.id;
        this.$post('/api/goods/update', params).then(res => {
          this.goodsForm.disabled = false;
          this.goodsForm.visible = false;
          // location.reload();
          this.getGoodsList();
        }).catch(res => {
          this.goodsForm.disabled = false;
        });
      }

    },
    picUploadSuccess(res, file) {
      // console.log('picUploadSuccess',res);

      console.log('picUploadSuccess', res);
      this.goodsForm.slider_img.push(res.data.name);
      this.goodsForm.fileList.push(res.data);
      console.log('this.goodsForm.slider_img', this.goodsForm);

      // console.log('goodsForm.fileList--',this.goodsForm.fileList);

    },
    coverUploadSuccess(res, file) {
      console.log('coverUploadSuccess', res.data);
      this.goodsForm.cover_img_url = res.data.url;
      this.goodsForm.cover_img = res.data.name;
      console.log('cover_img', res.data.name);
      // console.log('this.goodsForm.cover_img_url',this.goodsForm.cover_img_url);
    },
    detailImgUploadSuccess(res, file) {
      console.log('detailImgUploadSuccess',res);

      this.goodsForm.detail_img_url = res.data.url;
      this.goodsForm.detail_img = res.data.name;
      // console.log('this.goodsForm.detail_img_url',this.goodsForm.detail_img_url);

    },
    goodsFormClosed() {
      // this.goodsForm.goodsId = "-1";
      // this.goodsForm.name = "";
      // this.goodsForm.price = "";
      // this.goodsForm.cost = "";
      // this.goodsForm.unit_title = "";
      // this.goodsForm.pic = "";
      // this.goodsForm.detailImg = "";
      // this.goodsForm.allowFree.checked = false;
      // this.goodsForm.allowPay.checked = false;
      this.goodsForm = {
        disabled: false,
        visible: false,
        labelWidth: '120px',
        action: "update",
        name: '',
        price: '',
        cost_price: '',
        cover_img: '',
        cover_img_url: '',
        detail_img: '',
        detail_img_url: '',
        category_id: '',
        id: '',
        slider_img: [],
        filtList: [],
        unit: ''
      };

    },
    deleteGoodsClick(id) {
      this.$confirm("是否删除该商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$post('/api/goods/del', {
            ids: id
          }).then(res => {
            this.$message({
              message: "删除成功",
              type: "success",
              center: true
            });
            location.reload();
          })
        })
        .catch(() => { });
    },

  }
};
</script>

<style lang="scss" scoped>
.pic-uploader {
  width: 178px;
  height: 178px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  overflow: hidden;
}

.pic-uploader:hover {
  border-color: #409eff;
}

.pic-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
}

.pic {
  width: 100%;
  height: 100%;
}
</style>