<template>
  <div class="content-view" v-title :data-title="$t('下级代理')" style="background:#fff;">
    <div class="top-view" style="padding:10px;">
      <el-button type="success" @click="createAdvert">{{$t('添加广告组')}}</el-button>
      <!-- <el-input style="width:350px;margin-left: 20px;" placeholder="请输入关键字搜索" v-model="search.keyword" clearable
        @clear="getStaffList" @keyup.enter.native="getStaffList">
        <el-button slot="append" icon="el-icon-search" @click="getStaffList"></el-button>
      </el-input> -->
      <!-- <el-button type="success" @click="searchDevice">搜索设备</el-button>
      <el-button type="warning" @click="searchAgent">搜索下级</el-button> -->
      <!-- <el-button type="success" @click="createClick">添加会员</el-button> -->
    </div>
    <!-- {{levelArray}} -->
    <div class="breadcrumb-view" v-if="levelArray.length > 1">
      <i class="el-icon-user-solid"></i>

      <div class="breadcrumb-item" v-for="(item, index) in levelArray" :key="index">
        <span class="name" @click="breadcrumbClick(item, index)">{{ item.title }}</span>
        <i class="el-icon-arrow-right" v-if="index != levelArray.length - 1"></i>
      </div>
    </div>

    <el-table :data="list" v-loading="agent.loading" row-class-name="table-row">
      <el-table-column type="index" width="300" label="#"></el-table-column>

      <el-table-column prop="name" :label="$t('广告组名称')">
      </el-table-column>

      <el-table-column prop="type" width="400" :label="$t('布局方式')">
        <template slot-scope="scope">
          <div v-if="scope.row.type === 1">{{$t('顶部+中间+底部')}}</div>
          <div v-else-if="scope.row.type === 2">{{$t('(顶部_中间)+底部')}}</div>
          <div v-else-if="scope.row.type === 3">{{$t('顶部+(中间_底部)')}}</div>
          <div v-else-if="scope.row.type === 4">{{$t('全屏')}}</div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('操作')" width="400" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="openEdit(scope.row)" size="mini">{{$t('编辑')}}</el-button>
          <el-button type="primary" @click="editClick(scope.row)" size="mini">{{$t('绑定设备')}}</el-button>
          <el-button type="danger" @click="deleteClick(scope.row)" size="mini">{{$t('删除')}}</el-button>
        </template>
      </el-table-column>
    </el-table>


    <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="agent.page"
      :page-size="agent.pagesize" :total="agent.total" @current-change="agentChange"></el-pagination>

    <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="agent.page"
      :page-size="agent.pagesize" :total="agent.total" @current-change="agentChange"></el-pagination>

    <el-dialog :title="form.action == 'create' ? $t('添加广告') : $t('编辑广告')" :visible.sync="formShow">
      <el-form :model="form" ref="form" label-width="auto">
        <el-form-item :label="$t('广告名称')" prop="name" :rules="[{ required: true, message: $t('请输入广告名称'), trigger: 'blur' }]">
          <el-input v-model="form.name" style="width:350px" :placeholder="$t('请输入广告名称')"></el-input>
        </el-form-item>

        <el-form-item :label="$t('布局方式')">
          <el-col :span="12">
            <el-select v-model="form.type">
              <!-- <el-option label="顶部+中间+底部" :value="1"></el-option>
              <el-option label="(顶部_中间)+底部" :value="2"></el-option>
              <el-option label="顶部+(中间_底部)" :value="3"></el-option> -->
              <el-option :label="$t('全屏')" :value="4"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item :label="$t('广告一')">
          <el-col :span="12">
            <el-select @change="ad1Change" v-model="ad1" :placeholder="$t('请选择广告')">
              <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item :label="$t('广告二')" v-if="form.type !== 4">
          <el-col :span="12">
            <el-select @change="ad2Change" v-model="ad2" :placeholder="$t('请选择广告')">
              <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item :label="$t('广告三')" v-if="form.type === 1">
          <el-col :span="12">
            <el-select @change="ad3Change" v-model="ad3" :placeholder="$t('请选择广告')">
              <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickFalse">{{$t('取 消')}}</el-button>
        <el-button type="primary" :disabled="form.disabled" :loading="form.disabled" @click="createSubmit('form')">{{$t('确 定')}}</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="$t('绑定设备')" :visible.sync="devShow">
      <el-form :model="devForm" ref="devForm" label-width="auto">
        <div class="head">
          <!-- <el-autocomplete v-model="searchDev" :fetch-suggestions="SearchDevNameAsync" value-key="name"
            :placeholder="$t('devList.searchDev')" @select="handleSearchDevName" :select-when-unmatched="true" clearable
            @clear="getDevList">
          </el-autocomplete>
          <el-autocomplete v-model="searchImei" :fetch-suggestions="SearchDevImeiAsync" value-key="value"
            :placeholder="$t('devList.searchId')" @select="handleSearchDevImei" :select-when-unmatched="true" clearable
            @clear="getDevList">
          </el-autocomplete> -->
          <div class="right">
            <el-button type="primary" @click="bind(true)">{{$t('绑定')}}</el-button>
            <el-button type="danger" @click="bind(false)">{{$t('解绑')}}</el-button>
          </div>
        </div>
        <div class="list">
          <el-table :data="devList" v-loading="agent.loading" row-class-name="table-row"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center">
            </el-table-column>
            <el-table-column prop="name" :label="$t('设备名称')">
            </el-table-column>
            <el-table-column prop="imei" label="IMEI" show-overflow-tooltip>
            </el-table-column>
            <el-table-column :label="$t('绑定状态')">
              <template slot-scope="scope">
                <div v-if="scope.row.groups && scope.row.groups.length > 0" style="color: rgb(0, 233, 0);">{{$t('已绑定')}}</div>
                <div v-else style="color: red;">{{$t('未绑定')}}</div>
                <div v-if="scope.row.groups && scope.row.groups.length > 0">{{ scope.row.groups[0].name }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="clickFalse">{{$t('取 消')}}</el-button>
        <!-- <el-button type="primary" :disabled="devForm.disabled" :loading="devForm.disabled"
          @click="createSubmit('devForm')">确
          定</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { deepClone } from '@/utils/index'
import _ from 'lodash'
export default {
  name: "agent",
  data() {
    return {
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      imageUrl: '',
      index_id: null,
      goods: [],
      group_id: null,
      devShow: false,
      devList: [],
      select: [],
      options: [],
      ad1: null,
      ad2: null,
      ad3: null,
      form: {
        id: '',
        action: 'create',
        name: '',
        sort: 0,
        cover_img: []
      },
      formShow: false,
      devForm: {},
      agent: {
        list: [
          {
            avatar: '',
            name: '',
            level: {
              clr: 1
            }
          }
        ],
        page: 1,
        pagesize: 20,
        total: 0,
        loading: false,
        guid: null,
        remove: false
      },
      level: 1,
      levelArray: [
        {
          guid: "",
          title: "我的"
        }
      ],
      device: {
        list: [],
        page: 1,
        pagesize: 20,
        total: 0,
        visible: false,
        loading: false,
        agent: null
      },
      detail: {
        data: null,
        visible: false
      },
      record: {
        visible: false,
        list: [],
        page: 1,
        pagesize: 20,
        total: 0,
        type: "30d",
        loading: false,
        guid: null
      },
      userIncome: {
        list: [],
        loading: false
      },
      search: {
        device: {
          list: [],
          page: 1,
          pagesize: 20,
          total: 0,
          loading: false,
          keyword: '',
          visible: false
        },
        agent: {
          list: [],
          loading: false,
          keyword: '',
          visible: false,
          remove: false
        }
      },
      listQuery: {
        device: '',
        keyword: ''
      },
      loading: false,
      list: [],
      goods: [],

    };
  },
  created() {
    this.getList();
    this.getOption();
  },
  methods: {
    async getOption() {
      const res = await this.$get('/api/advert/list', {
        page: 1,
        page_size: 999,
        type: 1,
        status:1,
      })
      if (!res) { return this.$message.error(res.msg) }
      this.options = res.data
    },
    ad1Change(val) {
      // console.log(typeof(val))
      this.form.advert[0] = val

    },
    ad2Change(val) {
      // console.log(val)
      this.form.advert[1] = val
    },
    ad3Change(val) {
      // console.log(val)
      this.form.advert[2] = val
    },
    createAdvert() {
      this.form = {
        action: 'create',
        agent_id: this.userInfo.id,
        name: '',
        type: 4,
        advert: [null, null, null],
      };
      this.formShow = true;
    },
    handleSelectionChange(val) {
      // console.log(val)
      this.select = []
      val.forEach(item => {
        // console.log(item.id)
        this.select.push(item.id)
      })
    },


    createSubmit() {
      if (this.form.id) {
        this.editGroup()
      } else {
        this.newGroup()
      }

    },
    // async editAD() {
    //   // console.log(this.form,"edit")
    //   const res = await this.$post('/api/advert/updateIndex', this.form)
    //   if (!res) { return this.$message.error(res.msg) }
    //   this.form.visbile = false
    //   this.getList()
    // },

    async editClick(item) {
      this.group_id = item.id
      this.devShow = true;
      this.getDevList()
      // console.log('this.form-', this.form);
    },

    async bind(type) {
      // console.log(type);
      // console.log(item);
      console.log(this.select.toString())
      const res = await this.$post('/api/advert/belongGroupDevice', {
        device_ids: this.select.toString(),
        group_id: this.group_id,
        operate: type ? 'bind' : 'unbind'
      })
      if (!res) { return this.$message.error(res.msg) }
      this.devShow = false;
      this.getDevList()
    },
    async getDevList(name, imei) {
      // if (!name && !imei) { this.devLoading = true }
      const res = await this.$get('/api/device/list', {

      })
      // this.devLoading = false
      // console.log(res)
      // if (!res) { return this.$message.error(res.msg) }
      // if (name || imei) {
      //   return res.data.data
      // } else {
      //   this.select = []
      this.devList = res.data
      //   this.devTotal = res.data.total
      // }
    },


    openEdit(row) {
      console.log(row)
      this.form.action = 'update';
      let { id, name, type, advert } = row
      this.form = { id, name, type, advert }
      this.ad1 = this.form.advert[0]
      this.ad2 = this.form.advert[1]
      this.ad3 = this.form.advert[2]
      this.formShow = true;
    },
    async editGroup() {
      if (!this.form.name) { return this.$message.error(this.$tt.t('组名称不能为空')) }
      if (!this.form.advert[0]) { return this.$message.error(this.$tt.t('请选择广告')) }
      if (this.form.type !== 4 && !this.form.advert[1]) { return this.$message.error(this.$tt.t('请选择广告')) }
      if (this.form.type === 1 && !this.form.advert[2]) { return this.$message.error(this.$tt.t('请选择广告')) }
      const res = await this.$post('/api/advert/updateGroup', this.form)
      if (!res) { return this.$message.error(res.msg) }
      this.formShow = false
      this.getList()
    },
    async newGroup() {
      // console.log(this.form)
      if (!this.form.name) { return this.$message.error(this.$tt.t('组名称不能为空')) }
      if (!this.form.advert[0]) { return this.$message.error(this.$tt.t('请选择广告')) }
      if (this.form.type !== 4 && !this.form.advert[1]) { return this.$message.error(this.$tt.t('请选择广告')) }
      if (this.form.type === 1 && !this.form.advert[2]) { return this.$message.error(this.$tt.t('请选择广告')) }
      const res = await this.$post('/api/advert/addGroup', this.form)
      if (!res) { return this.$message.error(res.msg) }
      console.log(123);
      this.formShow = false
      this.getList()
    },





    clickFalse() {
      this.devShow = false;
      this.formShow = false;
    },

    addZone() {
      this.formShow = true;
    },

    wifiPageChange(val) {
      this.listQuery.page = val;
      this.getWifiList();
    },

    getList() {
      this.$get('/api/advert/group', {
        page: this.page,
        page_size: this.page_size,
        type: 2,
        agent_id: this.userInfo.id,
        name: this.searchName,
      }).then(res => {
        // console.log(res);
        this.loading = false;
        this.list = res.data;
      }).catch(res => {
        this.loading = false;
      })
    },

    deleteClick(row) {
      // if (confirm('是否确认删除？')) {
      //   console.log(12312312);
      // }
      this.$confirm(this.$tt.t('是否删除此广告'), this.$tt.t('提示'), {
        confirmButtonText: this.$tt.t("确定"),
        cancelButtonText: this.$tt.t("取消"),
        type: "warning"
      }).then(() => {
        this.$post('/api/advert/delGroup', {
          ids: row.id
        }).then(res => {
          this.$message({
            message: this.$tt.t("删除成功"),
            type: "success",
            center: true
          });
          this.getList()
        }).catch(res => {

        })
      }).catch(res => {

        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });          
      });
    },

  }
};
</script>

<style lang="scss" scoped>
.bigImg {
  width: 200px;
  height: 200px;
}

.avatar-uploader .el-upload .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px solid #d9d9d9;
}

.btns-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
}

.breadcrumb-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #eee;
  padding: 10px;

  .breadcrumb-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    .name {
      font-size: 14px;
      cursor: pointer;
    }

    .name:hover {
      text-decoration: underline;
    }

    .el-icon-arrow-right {
      font-size: 14px;
      color: #9d9d9d;
      margin-left: 5px;
    }
  }

  .breadcrumb-item:not(:first-child) {
    margin-left: 5px;
  }

  .breadcrumb-item:first-child .name {
    font-weight: 500;
  }
}

.table-row:hover {
  .el-icon-edit-outline {
    display: block;
  }
}

.row-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .username {
    margin-left: 10px;
  }

  .el-icon-edit-outline {
    margin-left: 10px;
    display: none;
    color: #9d9d9d;
    cursor: pointer;
  }
}

.level {
  padding: 5px 10px;
  color: #fff;
}

.qrcode {
  width: 150px;
  cursor: pointer;
}

.qrcode:active {
  opacity: 0.7;
}

.fa-qrcode:hover {
  color: #ccc;
}

.fa-bluetooth {
  margin-left: 10px;
  color: #1870d2;
}

.fa-bluetooth:hover {
  opacity: 0.7;
}

.group-text {
  padding: 5px 10px;
  color: #fff;
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: 0.5em;
}

.circle:hover {
  opacity: 0.7;
}

.el-table {
  .online {
    background-color: #67c23a;
  }

  .offline {
    background-color: #f56c6c;
  }
}

.detail-view {
  width: 100%;
  display: flex;
  flex-direction: column;

  .row-view {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    padding: 10px 0;

    .title {
      width: 120px;
      text-align: right;
    }

    .content {
      width: calc(100% - 150px);
      margin-left: 30px;
      color: #888;
      display: flex;
      flex-direction: row;
      align-items: center;

      .goods-img {
        width: 50px;
        height: 50px;
      }

      .goods-name {
        margin-left: 20px;
      }

      .goods-price {
        color: #f56c6c;
        margin-left: 20px;
      }
    }

    .online {
      color: #67c23a;
    }

    .offline {
      color: #f56c6c;
    }
  }
}

.top-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    font-size: 14px;
  }
}

.online-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .online-point {
    width: 10px;
    height: 10px;
  }

  .online-title {
    font-size: 14px;
    margin-left: 10px;
  }

  .online {
    background-color: #67c23a;
  }

  .offline {
    background-color: #ccc;
  }
}

.status-online {
  color: #67c23a;
}

.status-offline {
  color: #ccc;
}
</style>