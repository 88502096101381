export default {
    sidebar: {
        home: 'Home',
        order_management: 'Order Management',
        device_management: 'Device Management',
        model_management: 'Model Management',
        add_model: 'Add Model',
        goods_management: 'Goods Management',
        goods_list: 'Goods List',
        goods_classify: 'Goods Classification',
        coupon_management: 'Coupon Management',
        coupon: 'Coupon',
        advert_management: 'Ad Management',
        advert_examine: 'Ad Review',
        home_advert: 'Homepage Ad',
        standby_advert: 'Standby Ad',
        standbyGroup: 'Standby Ad Group',
        Subordinate_agents: 'Subordinate Agents',
        site_management: 'Site Management',
        user_list: 'User List',
        commission_management: 'Commission Management',
        agent_reviewe: 'Agent Review',
        staff: 'Staff',
        Feedback:'Feedback',
        set:'Settings',
        Lottery_List:'Lottery List',
        Dealer_backend_system:'Dealer Backend System',
        login_password:'Login Password',
        logout:'Log Out',
    },
    name:'Name',
    account:'Account',
    login:'Login',
    Welcome_to_login:'Welcome to Login',
    Dealer_backend:'Dealer Backend',
    new_password:'New Password',
    plz_password:'Please enter password',
    plz_newPassword:'Please enter new password',
    plz_name:'Please enter name',
    plz_phone:'Please enter phone number',
    password_modify:'Leave password blank if not modifying staff password',
    password:'Password',
    cancel:'Cancel',
    save:'Save',
    save:'Save',
    phone:'Phone Number',
    date:'Date',
    to:'To',
    income:'Income',
    device:'Device',
    device_name:'Device Name',
    device_num:'Total Devices',
    device_online:'Online Devices',
    device_not_online:'Offline Devices',
    device_id:'Device ID',
    add_device:'Add Device',
    refund:'Refund',
    Withdrawal:'Withdrawal',
    Venue:'Venue',
    select:'Select',
    submit:'Submit',
    Commission_sharing_method:'Commission Sharing Method',
    commission:'Commission',
    commission1:'Commission',
    Retained:'Retained',
    start_date:'Start Date',
    end_date:'End Date',
    edit:'Edit',
    Replenishment_records:'Replenishment Records',
    add_staff:'Add Staff',
    edit_staff:'Edit Staff',
    Please_enter_keywords_for_search:'Please enter keywords for search',
    delete:'Delete',
    Default_this_month:'Default This Month',
    number:'Number',
    detail:'Detail',
    Replenishment_date:'Replenishment Date',
    Revenue_statistics:'Revenue Statistics',
    statistics:'Statistics',
    Revenue_detail:'Revenue Detail',
    type:'Type',
    describe:'Describe',
    Equipment_in_operation:'Equipment in Operation',
    operate:'Operate',
    Distribution_Equipment:'Distribute Equipment',
    Parameter_settings:'Parameter Settings',
    Proportional_sharing:'Proportional Sharing',
    commission_rate:'Commission Rate',
    Fixed_amount:'Fixed Amount',
    sell:'Sell',
    Platform_home:'Platform Home',
    today_order:'Today Order',
    month_order:'This Month Order',
    order_all:'Total Orders',
    net_income:"Net Income",
    AddAgent:'Add Agent',
    Name:'Name',
    PleaseEnterKeywords:'PleaseEnterKeywords',
    Agent:'Agent',
    Level:'Level',
    NumberOfDevices:'NumberOfDevices',
    '抽成':'TakeAPercentage',
    '售卖':'Selling',
    '电话':'Telephone',
    '操作':'Operation',
    '出货统计':'ShipmentStatistics',
    'TA的设备':'TaSEquipment',
    'TA的下级':'TaSSubordinates',
    'TA的抽成':'TaSCommission',
    '佣金明细':'CommissionDetails',
    '取消身份':'CancelIdentity',
    'TA的设备':'TaSEquipment',
    '设备名称':'DeviceName',
    '蓝牙':'Bluetooth',
    '设备ID':'DeviceId',
    '状态':'State',
    '屏幕':'Screen',
    '出货':'Shipment',
    '启用':'Enable',
    '禁用':'Disabled',
    '创建时间':'CreationTime',
    '详情':'Details',
    '解绑':'Unbind',
    '设备IMEI':'Equipment Imei',
    '设备名称':'Device Name',
    '设备型号':'Equipment Model',
    '设备状态':'Device Status',
    '正常':'Normal',
    '禁用':'Disabled',
    '出货状态':'Shipment Status',
    '在线':'OnLine',
    '离线':'OffLine',
    '累计出货':'Accumulated Shipments',
    '错误代码':'ErrorCode',
    '最近在线':'Recently Online',
    '货道':'FreightLane',
    '库存':'Inventory',
    '容量':'Capacity',
    '出货统计':'Shipment Statistics',
    '月份':'Month',
    '请选择月份':'Please Select The Month',
    '设备':'Equipment',
    '请选择设备':'Please Select ADevice',
    '设备':'Equipment',
    '商品':'Commodity',
    '日期':'Date',
    '佣金明细':'Commission Details',
    '描述':'Describe',
    '订单':'OrderForm',
    '资金状态':'Funding Status',
    '到账':'Received',
    '冻结':'Freeze',
    '创建时间':'CreationTime',
    '搜索设备':'Search For Devices',
    '请输入设备名称或IMEI号进行查询':'Please Enter The DeviceName Or Imei Number For Querying',
    '设备名称':'Device Name',
    '蓝牙':'Bluetooth',
    '设备ID':'DeviceId',
    '所属区域':'Region',
    '分组':'Grouping',
    '状态':'State',
    '屏幕状态':'Screen Status',
    '创建时间':'CreationTime',
    '操作':'Operation',
    '详情':'Details',
    '解绑':'Unbind',
    '搜索下级':'Pearch For Subordinates',
    '请输入手机号或微信昵称进行查询':'Please Enter Your Phone Number Or WeChat Nickname To Search',
    '设备数量':'Number Of Devices',
    '电话':'Telephone',
    '地址':'Address',
    '出货统计':'ShipmentStatistics',
    'TA的设备':'His Equipment',
    '佣金明细':'CommissionDetails',
    '取消身份':'CancelIdentity',
    'TA的抽成':'His Commission',
    '售卖':'Selling',
    '是否开启':'Is It Enabled',
    '分润':'Share In The Benefit Or Profit',
    '分润模式':'Dividend Model',
    '按比例分成':'Divided Proportionally',
    '抽成':'Take A Percentage',
    '请输入分润数值':'Please Enter The Profit Sharing Value',
    '分润':'Share In The Benefit Or Profit',
    '分润模式':'Dividend Model',
    '按比例分成':'Divided Proportionally',
    '抽成':'Take A Percentage',
    '请输入分润数值':'Please Enter The Profit Sharing Value',
    '元':'element',
    '是否开启':'Is It Enabled',
    '分润':'Share In The Benefit Or Profit',
    '分润模式':'Dividend Model',
    '按比例分成':'Divided Proportionally',
    '添加代理商':'AddAgent',
    '编辑代理商':'Editing Agents',
    '基础信息':'Basic In for mation',
    '姓名':'Name',
    '输入下级代理姓名':'Enter The Name Of The Subordinate Agent',
    '手机号码':'Mobile Phone Number',
    '输入手机号':'Enter Phone Number',
    '此手机号码会作为登录账户':'This Phone Number Will Be Used As The Login Account',
    '登录密码':'Login Password',
    '会作为用户代理登录的密码':'The Password That Will Be Used As A UserAgent Login',
    '分润比例':'Dividend Ratio',
    '请输入分润数值':'Please Enter The Profit Sharing Value',
    '固定金额':'Fixed Amount',
    '请输入抽成数值':'Please Enter The CommissionValue',
    '取消':'cancel',
    '提交':'Submit',
    '新增分类':'Add A New Category',
    '分类名称':'Classification Name',
    '排序值':'Sorting Values',
    '图片':'Picture',
    '删除':'Delete',
    '新增分类':'Add A New Category',
    '编辑分类':'Edit Classification',
    '分类名称':'Classification Name',
    '请输入分类名称':'Please Enter The Category Name',
    '请输入排序值':'Please Enter Sorting Values',
    '上传图片':'Upload Images',
    '只能上传jpg/png文件':'Only Jpg Png Files Can Be Uploaded',
    '佣金管理':'Commission Management',
    '收入明细':'Revenue Details',
    '账户余额':'Account Balance',
    '提现':'Withdrawal',
    '记录':'Record',
    '提现记录':'Withdrawal Records',
    '申请人':'Applicant',
    '类型':'type',
    '收入':'income',
    '支出':'expenditure',
    '金额':'money',
    '资金状态':'funding Status',
    '到账':'received',
    '冻结':'freeze',
    '余额提现':'Withdrawal Of Balance',
    '账户余额':'Account Balance',
    '提现金额':'Withdrawal Amount',
    '请输入提现金额（单位：元）':'Please Enter The Withdrawal Amount',
    '全部提现':'All Withdrawals',
    '导入设备':'ImportDevice',
    '缺货设备':'Out Of Stock Equipment',
    '故障设备':'Defective Equipment',
    '导出补货单':'Export Replenishment Order',
    '设备名称':'Device Name',
    '设备imei':'Equipment Imei',
    '设备IMEI':'Equipment Imei',
    '所属':'BelongingTo',
    '状态':'State',
    '在线':'OnLine',
    '未在线':'Not Online',
    '库存':'Iinventory',
    '详情':'Details',
    '补货':'Replenishment',
    '记录':'Record',
    '编辑':'Edit',
    '型号':'Model',
    '转让':'Assignment',
    '选取文件':'SelectFile',
    '提交到服务器':'Submit To Server',
    '下载导入模板':'Download And ImportTemplates',
    '只能上传xlsx/xls文件':'Only Xlsx Xls Files Can Be Uploaded',
    '请您检查无误后，再点击“提交到服务器”按钮':'Please Check For Accuracy Before Clicking The Submit To Server Button',
    '缺货设备':'Out Of Stock Equipment',
    '补货':'Replenishment',
    '添加设备':'AddDevice',
    '编辑设备':'Edit Device',
    '设备编号':'Equipment Number',
    '请输入设备编号':'Please Enter The Device Number',
    '请输入设备名称':'Please Enter The Device Name',
    '设备场地':'Equipment Site',
    '设备密码':'Device Password',
    '请输入密码':'Please Enter Password',
    '密码留空则不修改运营人员密码':'If The Password Is Left Blank The Operator Password Will Not Be Changed',
    '取 消':'cancel',
    '确 定':'confirm',
    '货道':'freight Lane',
    '容量':'Capacity',
    '当前数量':'current Quantity',
    '补货':'replenishment',
    '补满':'fillUp',
    '一键补满':'oneClick Fill',
    '设备转让':'equipment Transfer',
    '设备数量':'number Of Devices',
    '电话':'Telephone',
    '转让':'assignment',
    '故障设备':'defective Equipment',
    '编号':'number',
    '故障描述':'fault Description',
    '故障时间':'faultTime',
    '查看设备库存':'View Equipment Inventory',
    '总数':'total Number',
    '货道':'freight Lane',
    '详情':'Details',
    '设备型号':'equipment Model',
    '设备状态':'device Status',
    '出货记录':'Shipping Records',
    '订单号':'orderNumber',
    '用户':'User',
    '名称(必填)':'name(required)',
    'IMEI(必填)':'imei(required)',
    '代理商(选填)':'agent(required)',
    '型号(选填)':'Model(required)',
    '下级代理':'Subordinate Agents',
    '添加优惠券':'Add Coupons',
    '优惠券名称':'coupon Name',
    '额度':'limit',
    '优惠券类型':'Coupon Type',
    '金额券':'amount Voucher',
    '新用户金额券':'new User Amount Voucher',
    '领取代码':'claimCode',
    '操作':'operation',
    '添加优惠券':'add Coupons',
    '编辑优惠券':'Edit Coupon',
    '基础信息':'Basic Information',
    '请输入折扣券名称':'please Enter The Name Of The Discount Coupon',
    '长度至少3个字符':'At Least_3 Characters In Length',
    '请输入优惠券名称':'Please Enter The Coupon Name',
    '优惠券额度':'coupon Limit',
    '请输入折扣券额度':'Please Enter The Discount Coupon Limit',
    '请输入数字':'please Enter A Number',
    '折扣券类型':'discount Coupon Type',
    '请选择折扣券类型':'please Select The Type Of Discount Coupon',
    '金额券':'Amount Voucher',
    '新用户金额券':'new User Amount Voucher',
    '优惠券领取代码':'coupon Redemption Code',
    '请输入折扣券代码':'Please Enter The Discount Coupon Code',
    '请输入优惠券领取代码':'Please Enter The Coupon Redemption Code',
    '我的':'My',
    '是否删除该优惠券':'Do You Want To Delete This Coupon',
    '提示':'Prompt',
    '删除成功':'delete Successful',
    '添加成功':'Successfully Added',
    '编辑成功':'edit Successful',
    '设备信息':'DeviceInformation',
    '所属代理':'Affiliated Agent',
    '请输入内容':'Please Enter The Content',
    '代理':'agent',
    '代理信息':'Agent Information',
    '电话':'Telephone',
    '上级代理':'superior Agent',
    '分润':'share In The Benefit Or Profit',
    '未启用':'not Enabled',
    '已启用':'not Enabled',
    '硬件IMEI':'hardwareImei',
    "设备型号":'Equipment Model',
    '请选择型号':'Please Select The Model',
    '商品名称':'Product Name',
    '当前数量':'Current Quantity',
    '设备场地':'Equipment Site',
    '请选择场地':'Please Select A Venue',
    '立即提交':'SubmitNow',
    '下级代理':'Subordinate Agents',
    '广告名称':'advertising Name',
    '广告类型':'Advertising Type',
    '首页广告':'Homepage Ads',
    '待机广告':'Standby Advertisement',
    '图片组':'picture Group',
    '广告视频':'advertisingVideos',
    '视频封面':'video Cover',
    '通过审核':'Approved',
    '待审核':'Pending Review',
    '被驳回':'rejected',
    '通过':'Adopt',
    '驳回':'Reject',
    '添加广告':'Add Advertisement',
    '编辑广告':'Edit Advertisement',
    '广告名称':'Advertising Name',
    '请输入广告名称':'please Enter The Advertisement Name',
    '广告类型':'advertising Type',
    '图片':'picture',
    '视频':'video',
    '点击上传':'Click To Upload',
    '只能上传jpg/png文件':'only Jpg Pn gFiles Can Be Uploaded',
    '广告视频':'Advertising Videos',
    '只能上传mp4文件':'only Mp4 Files Can Be Uploaded',
    '视频封面':'Video Cover',
    '只能上传jpg/png文件':'Only Jpg Png Files Can Be Uploaded',
    '设备名称':'Device Name',
    '手机号码':'mobile Phone Number',
    '反馈内容':'Feedback Content',
    '商品管理':'Product Management',
    '添加商品':'Add Product',
    '商品':'commodity',
    '单价':'UnitPrice',
    '商品名称':'Product Name',
    '请输入商品名称':'Please Enter The Product Name',
    '价格（元）':'Price',
    '请输入价格':'please Enter The Price',
    '成本（元）':'Cost',
    '请输入成本（成本不参与分佣金）':'Please Enter The Cost(Cost Does Not Participate In Commission Sharing)',
    '请输入商品单位':'Please Enter The Product Unit',
    '冷藏':'coldStorage',
    '推荐加热时长（分）':'recommended Heating Time Minutes',
    '商品分类':'product Classification',
    '请选择商品分类':'Please Select Product Category',
    '详情图片':'Detailed Images',
    '商品管理':'Product Management',
    '轮播图':'CarouselChart',
    '请输入关键字搜索':'please Enter Keywords For Search',
    '绑定设备':'binding Devices',
    '重新编辑':'reEdit',
    '绑定':'Binding',
    '解绑':'Unbind',
    '绑定状态':'binding Status',
    '已绑定':'bound',
    '未绑定':'unbound',
    '是否删除此广告':'do You Want To Delete This Advertisement',
    '请输入openId':'please Enter OpenId',
    '查询':'Query',
    '展开所有':'expandAll',
    '头像':'Avatar',
    '昵称':'Nickname',
    '订单数':'Number Of Orders',
    '消费总金额':'total Consumption Amount',
    '总金额':'Total Amount',
    '注册日期':'RegistrationDate',
    '确定该笔提现申请通过审核吗？':'Are You Sure The Withdrawal Application Has Been Approved',
    '提现审核':'Withdrawal Review',
    '确定驳回该笔提现申请吗？':'Are You Sure To Reject The Withdrawal Application',
    '提交成功':'Submitted Successfully',
    '订单管理':'Order Management',
    '订单号':'Order Number',
    '请输入订单号':'Please Enter The Order Number',
    '导出':'Export',
    '售卖':'Selling',
    '来源':'Source',
    '用户':'user',
    '非会员支付':'Non Member Payment',
    '所属':'BelongingTo',
    '进行中':'InProgress',
    '已完成':'Completed',
    '退款':'refund',
    '已退款':'Refunded',
    '订单退款':'OrderRefund',
    '订单编号':'OrderNumber',
    '商品金额':'productAmount',
    '请输入要退款的金额，最小退款金额':'please Enter The Amount To Be Refunded Minimum Refund Amount',
    '结束订单':'endOrder',
    '请选择结束时间':'please Select The EndTime',
    '选择日期时间':'select DateAndTime',
    '解绑成功!':'unbind Successful',
    '微信':'WeChat',
    '支付宝':'alipay',
    '余额支付':'Balance Payment',
    '其他':'Other',
    '支付号':'Payment Number',
    '支付类型':'Payment Type',
    '用户名':'User Name',
    '用户openid':'User Openid',
    '商品ID':'ProductId',
    '商品数量':'product Quantity',
    '商品价格':'commodity Price',
    '支付金额':'paymentAmount',
    '订单状态':'orderStatus',
    '代理商名称':'agentName',
    '设备名称':'DeviceName',
    '订单时间':'orderTime',
    '导出订单':'ExportOrder',
    '是否确认退款操作':'are You Sure About The Refund Operation',
    '退款失败':'Refund Failed',
    '禁止退款其他代理订单':'refunds Are Prohibited For Other Agency Orders',
    '请输入商品数量':'please Enter The Quantity Of Products',
    '退款成功':'refund Successful',
    '请输入折扣券名称':'Please Enter The Name Of The Discount Coupon',
    '长度至少3个字符':'At Least_3 Characters In Length',
    '请输入优惠券名称':'Please Enter The CouponName',
    '优惠券额度':'Coupon Limit',
    '请输入折扣券额度':'Please Enter The Discount CouponLimit',
    '请输入数字':'Please Enter A Number',
    '元':'',
    '折扣券类型':'Discount CouponType',
    '请选择折扣券类型':'please Select The TypeOf DiscountCoupon',
    '抽奖概率':'luckyDraw Probability',
    '是否删除该优惠券':'Do You Want To Delete This Coupon',
    '请选择代理商':'Please SelectAn Agent',
    '账户余额':'AccountBalance',
    '提现时间':'Withdrawal Time',
    '提现金额':'withdrawal Amount',
    '提现方式':'Withdrawal Method',
    '微信到账':'WeChatAccount Received',
    '申请中':'In Application',
    '申请等待':'Application Waiting',
    '设 置':'Settings',
    '烤箱提示信息':'Oven Prompt In formation',
    '设备预警数量':'Number Of Device Warnings',
    '请输入设备预警数量':'Please Enter The Number Of DeviceAlerts',
    '登录头部图片':'Login Header Image',
    '个人信息头部图片':'personal Information Header Image',
    '运营人员':'Operations Personnel',
    '售卖':'Selling',
    '充电':'Charging',
    '虚拟货道':'Virtual FreightLane',
    '请输入姓名':'Please Enter Your Name',
    '请输入手机号码':'Please Enter Your PhoneNumber',
    '请输入初始密码':'Please Enter TheInitial Password',
    '是否删除该运营人员?':'Do You Want To Delete The Operator?',
    '是否删除该设备?':'Do You Want To Delete The Device?',
    '请选择':'Please Select',
    '分组':'grouping',
    '未选择设备':'no Device Selected',
    '设置成功':'Set Successfully',
    '重新编辑':'ReEdit',
    '此视频暂无法播放，请稍后再试':'This Video Cannot Be Played At The Moment Please Try AgainLater',
    '添加广告组':'Add Advertising Group',
    '广告组名称':'advertising GroupName',
    '布局方式':'Layout Method',
    '顶部+中间+底部':'Top+Middle+Bottom',
    '(顶部_中间)+底部':'(Top_Middle)+Bottom',
    '顶部+(中间_底部)':'Top+(Middle_Bottom)',
    '全屏':'fullScreen',
    '布局方式':'LayoutMethod',
    '广告一':'advertising_1',
    '请选择广告':'Please Select An Advertisement',
    '广告二':'advertising_2',
    '广告三':'Advertising_3',
    '组名称不能为空':'Group Name Cannot Be Empty',
    '型号信息':'Model Information',
    '支持类型':'SupportTypes',
    '售货机':'VendingMachine',
    '货道信息':'FreightLane Information',
    '添加货道':'Add Freight Lane',
    '编辑型号':'Edit Model',
    '添加场地':'Add A Venue',
    '场地名称':'venue Name',
    '请输入场地名称':'Please Enter The Venue Name',
    '退 款':'Refund'
}