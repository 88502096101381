<template>
  <div class="content-view">
    <el-button type="primary" @click="createClick">{{$t('新增分类')}}</el-button>
    <el-table :data="list" style="margin-top:10px;" v-loading="loading">
      <el-table-column type="index" label="#" width="50"></el-table-column>
      <el-table-column :label="$t('分类名称')" prop="name">
      </el-table-column>
      <el-table-column prop="sort" :label="$t('排序值')"></el-table-column>
      <el-table-column :label="$t('图片')">
        <template slot-scope="scope">
          <el-image style="width: 80px;height:80px" v-for="item, index in scope.row.cover_img_url" :key="index"
            :src="item.url" :preview-src-list="scope.row.cover_img_url.map(item => item.url)">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column :label="$t('操作')">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="editClick(scope.row)">{{$t('编辑')}}</el-button>
          <el-button type="danger" size="mini" @click="deleteClick(scope.row.id)">{{$t('删除')}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="listQuery.page"
      :page-size="listQuery.page_size" :total="listQuery.total" @current-change="pageChange"></el-pagination>

    <el-dialog :title="form.action == 'create' ? $t('新增分类') : $t('编辑分类')" :key="form.key" width="500px"
      :visible.sync="form.visible" :close-on-click-modal="false">
      <el-form :model="form" label-width="auto">
        <el-form-item :label="$t('分类名称')">
          <el-input v-model="form.data.name" :placeholder="$t('请输入分类名称')" clearable></el-input>
        </el-form-item>
        <el-form-item :label="$t('排序值')">
          <el-input-number controls-position="right" style="width:100%;" v-model="form.data.sort" :placeholder="$t('请输入排序值')"
            clearable></el-input-number>
        </el-form-item>
        <el-form-item :label="$t('上传图片')">
          <el-upload ref="uploader" class="avatar-uploader bigImg" :action="'/api/category/upload'"
            :headers="headers" list-type="picture" name="image" :data="{ type: 0 }" :accept="'.jpg, .png'"
            :show-file-list="false" :auto-upload="false" :on-change="handleChange" :on-success="handleAvatarSuccess"
            :on-error="handleAvatarError">
            <div slot="tip" class="el-upload__tip">{{$t('只能上传jpg/png文件')}}</div>
            <el-image v-if="imageUrl" :src="imageUrl" class="avatar"></el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon bigImg"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="form.visible = false">{{$t('取消')}}</el-button>
        <el-button type="primary" :loading="form.disabled" :disabled="form.disabled" @click="createSubmit">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: "goods",
  data() {
    return {
      imageUrl: '',
      action: {
        url: '/api/goods/upload',
        headers: {
          token: sessionStorage.getItem('token')
        },
        name: 'image',
      },
      list: [],
      loading: false,
      form: {
        key: 0,
        visible: false,
        disabled: false,
        action: 'create',
        data: {
          id: '',
          name: '',
          sort: 0,
          cover_img: [],
        }
      },
      baseForm: {
        key: 0,
        visible: false,
        action: 'create',
        data: {
          name: '',
          sort: 0
        }
      },
      listQuery: {
        page: 1,
        page_size: 20,
        total: 0
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleAvatarError(err) {
      this.$message.error(err.msg)
    },
    handleChange(file) {
      if (this.$refs["uploader"].uploadFiles.length > 1) {
        this.$refs["uploader"].uploadFiles.shift()
      }
      this.imageUrl = file.url
    },
    async handleAvatarSuccess(res) {
      console.log(res)
      if (!this.form.data.id) {
        this.imageUrl = res.data.url;
        this.form.data.cover_img[0] = res.data.name;
        this.add()
      } else {
        // console.log(123);
        await this.$post('/api/file/del', {
          name: this.form.data.cover_img[0],
          file_type: 'image',
          module: 'category'
        })
        this.imageUrl = res.data.url
        this.form.data.cover_img[0] = res.data.name
        this.edit()
      }
    },
    getList() {
      this.loading = true;
      this.$get('/api/category/list').then(res => {
        console.log('res--', res);
        this.list = res.data;
        this.loading = false;
      }).catch(res => {
        this.loading = false;
      })
    },
    beforeUpload(item) {
      console.log('item--', item);
    },
    pageChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    editClick(item) {
      console.log(item);
      this.form.visible = true;
      this.form.action = 'update';
      let { id, name, sort, cover_img_url } = item
      this.form.data = { id, name, sort, cover_img: [] }
      this.form.data.cover_img[0] = cover_img_url[0].name
      this.imageUrl = cover_img_url[0].url
    },
    deleteClick(id) {
      this.$confirm('是否删除此分类', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$post('/api/category/del', {
          ids: id
        }).then(res => {
          this.$message({
            message: "删除成功",
            type: "success",
            center: true
          });
          this.getList()
        }).catch(() => {

        })
      }).catch(() => {

      });
    },
    createClick() {
      this.form = _.cloneDeep(this.baseForm);
      this.form.visible = true;
      this.form.action = 'create';
      this.form.data = {
        id: '',
        name: '',
        sort: 0,
        cover_img: [],
      };
      this.imageUrl = '';
    },

    add() {
      this.$post('/api/category/add', {
        name: this.form.data.name,
        sort: this.form.data.sort,
        cover_img: this.form.data.cover_img[0]
      }).then(res => {
        this.$message({
          message: '操作成功',
          type: 'success'
        });
        this.form.visible = false;
        this.form.disabled = false;
        this.getList();
      }).catch(() => {

      })

    },
    edit() {
      this.$post('/api/category/update', {
        id: this.form.data.id,
        name: this.form.data.name,
        sort: this.form.data.sort,
        cover_img: this.form.data.cover_img[0]
      }).then(res => {
        this.$message({
          message: '操作成功',
          type: 'success'
        });
        this.form.visible = false;
        this.form.disabled = false;
        this.getList();
      })
    },
    createSubmit() {
      this.form.disabled = true;
      // console.log('form',this.form);
      // return;
      if (!this.form.data.name) { return this.$message.error('请输入广告名称') }
      if (!this.imageUrl) { return this.$message.error('请广告图片') }
      // console.log(this.$refs['uploader'].uploadFiles)
      if (this.$refs['uploader'].uploadFiles.length > 0) {
        this.$refs["uploader"].submit()
        return
      }
      this.edit()
    },

    deleteGoodsClick(id) {
      this.$confirm("是否删除该商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$post('/api/goods/del', {
            ids: id
          }).then(res => {
            this.$message({
              message: "删除成功",
              type: "success",
              center: true
            });
            location.reload();
          })
        })
        .catch(() => { });
    },

  }
};
</script>

<style lang="scss" scoped>
.bigImg {
  width: 200px;
  height: 200px;
}

.avatar-uploader .el-upload .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px solid #d9d9d9;
}

.pic-uploader {
  width: 178px;
  height: 178px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  overflow: hidden;
}

.pic-uploader:hover {
  border-color: #409eff;
}

.pic-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
}

.pic {
  width: 100%;
  height: 100%;
}
</style>