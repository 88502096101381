import Vue from 'vue'
import axios from 'axios'
import Router from 'vue-router'
import {
    MessageBox,
    Message
} from 'element-ui'


const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 60000
})

service.interceptors.request.use(
    config => {
        config.baseURL = process.env.VUE_APP_BASE_API
        return config
    },
    error => {
        console.log('request error');
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    res => {
        console.log('res', res);
        if (res.headers['content-type'] === 'image/jpeg') {
            return Promise.resolve(res.data)
        }
        console.log('res.data.code', res.data.code);
        switch (parseInt(res.data.code)) {
            case 0:
            case 200:
                if (res.data.data) {
                    return Promise.resolve(res.data.data)
                } else {
                    return Promise.resolve(res.data)
                }
            case 8000:
                console.log('登录失效了');
                Message({
                    message: '登录已过期，请重新登录',
                    type: 'error'
                });
                sessionStorage.removeItem('token');
                location.href = '/#/login'
            default:
                console.log('网络异常请刷新');
                // Message.closeAll();
                // if(res.data.msg == '登录已过期，请重新登录' || res.data.msg == '验证失败，无效的token'){
                //     Message({
                //         message: res.data.msg || '网络卡住了，请刷新',
                //         type: 'error'
                //       })
                // }else{
                Message({
                    message: res.data.msg || '网络卡住了，请刷新',
                    type: 'error'
                })
                return Promise.reject(res.data)
            //   }
        }
    },
    (error) => {
        if (error.message && error.message.indexOf('timeout of') > -1) {
            location.reload()
        } else {
            Message.closeAll()
            if (!error.response) {
                Message({
                    message: '网络卡住了，请刷新',
                    type: 'error'
                });
            }
            if (error.code == 'ECONNABORTED' && error.message.indexOf('timeout') != -1) {
                Message({
                    message: '网络卡住了，请刷新',
                    type: 'error'
                });
            }
        }
        console.log('reject');
        return Promise.reject(error)
    }

)

export function $get(url, params = {}) {
    console.log('get params', params);
    // params.user_id = getToken('user_id')
    // params.agent_id = params.agent_id || getToken('agent_id')
    let token = sessionStorage.getItem('token');
    console.log('get token', token);
    // return service.get(url,{params},{headers:{
    //     token2:123
    // }})
    return service({
        url: url,
        method: 'get',
        headers: {
            "Authorization": "Bearer " + token
        },
        params: params
    })
}


export function $post(url, data = {}) {
    // console.log('Vue.prototype',Vue.prototype);
    // console.log('Vue.prototype.BASE_URL',Vue.prototype.BASE_URL);
    // return;
    // url = url + '?token=' + getToken()
    // data.token = getToken()


    // return service.post(url,data)
    let token = sessionStorage.getItem('token');
    console.log('post token', token);

    return service({
        url: url,
        method: 'post',
        headers: {
            "Authorization": "Bearer " + token
        },
        data: data
    })

}

export default service