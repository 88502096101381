<template>

  <div class="login" data-title="登录" style="display:block;position:relative">

    <!-- <div class="card">
      <span class="title">欢迎登录</span>
      <span class="title">代理商后台</span>
      <div>
            <span style="font-weight:700;">账号</span>
            <el-input v-model="username" placeholder="请输入用户名"></el-input>
            <span style="font-weight:700;">密码</span>
            <el-input v-model="password" placeholder="请输入密码"></el-input>
      </div>
    </div> -->

    <div class="" style="border-radius:5px;height:380px;width:480px;
    background-color:#fff;position:absolute;right:8%;top:20%">
      <div class="login-box" style="padding:40px;">
        <div class="title1" style="">{{ $t('Welcome_to_login') }}</div>
        <div class="title2" style="display:block;">{{ $t('Dealer_backend') }}</div>


        <el-dropdown @command="changeLanguage" style="float: right;">
          <div class="el-dropdown-link">
            <i class="iconfont icon-langua" style="font-size: 20px;"></i>
            {{ language }}
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="zh">简体中文</el-dropdown-item>
            <el-dropdown-item command="en">English</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <div class="form-container">
          <el-form :model="form" ref="loginForm">
            <div>{{ $t('phone') }}</div>
            <el-form-item style="margin-top:8px;" prop="username"
              :rules="[{ required: true, message: $t('plz_phone'), trrigger: 'blur' }]">
              <el-input v-model="form.username" :placeholder="$t('plz_phone')" size="medium"></el-input>
            </el-form-item>
            <div style="margin-top:-4px;">{{ $t('password') }}</div>
            <el-form-item style="margin-top:8px;" prop="password"
              :rules="[{ required: true, message: $t('plz_password'), trrigger: 'blur' }]">
              <el-input type="password" v-model="form.password" :placeholder="$t('plz_password')" size="medium"
                @keyup.enter.native="login"></el-input>
            </el-form-item>

            <el-button type="primary" class="login-btn" size="small" @click="login" :loading="loading">{{ $t('login')
              }}</el-button>

          </el-form>

        </div>

        <!-- </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
export default {
  name: "login",

  data() {
    return {
      form: {
        username: "",
        password: "" // todo
      },
      loading: false,
      language: localStorage.getItem('lang') == 'zh' ? '简体中文' : 'English',
      lang: localStorage.getItem('lang'),
    };
  },
  created() {
    // console.log('lang', this.lang);

  },
  mounted() {
    console.log('Vue.prototype.BASE_URL', process.env.VUE_APP_BASE_API);
    // let params = {
    //   op: "loginQR"
    // };
    // this.$api.login.getLoginUrl(params).then(res => {
    //   if (res.data.status) {
    //     // this.creatQrCode(res.data.data.data);
    //     let uniq = this.getQueryString(res.data.data.data, 'uniq');
    //     this.interval = setInterval(() => {
    //       this.getLoginPoll(uniq);
    //     }, 1000);
    //   }
    // });
  },
  methods: {
    changeLanguage(lang) {
      if (lang == 'zh') {
        this.language = '简体中文';
      } else {
        this.language = 'English';
      }
      // 更新 VueI18n 实例的 locale 属性
      this.$i18n.locale = lang;
      // 同时将语言标识存储到 localStorage 中以保持用户选择的语言状态
      localStorage.setItem('lang', lang);
    },
    login() {
      console.log('form', this.form);

      this.$refs['loginForm'].validate((valid) => {
        console.log('valid', valid);
        if (valid) {
          this.loading = true;
          // this.$alert('验证');
          this.$post('/api/agent/login', {
            mobile: this.form.username,
            password: this.form.password
          }).then(res => {
            console.log('res', res);
            this.loading = false;
            sessionStorage.setItem("token", res.token);
            sessionStorage.setItem("agent_id", res.user_info.id);
            sessionStorage.setItem("userInfo", JSON.stringify(res.user_info));

            this.$router.push('/stats');
          }).catch(res => {
            this.loading = false;

          });
        }
      })

    },
  }
};
</script>
<style scoped lang='scss'>
.login {
  width: 100%;
  height: 100%;
  background-image: url("../assets/background.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
}

.login-btn {
  width: 100%;
  height: 35px;
  margin-top: 16px;
  background-color: #4e73df
}

.title1 {
  font-size: 18px;
  display: block;
  text-align: left;
}

.title2 {
  font-size: 24px;
  display: block;
  text-align: left;
}

.login-box {
  text-align: left;
}

.form-container {
  font-weight: 700;
  font-size: 14px;
  margin-top: 30px;
}

.card {
  width: 300px;
  background-color: #fff;
  position: absolute;
  right: 10vw;
  top: calc(50vh - 250px);
  border-radius: 5px;
  box-shadow: 0 0 5px #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;

  .title {
    font-size: 22px;
    font-weight: 500;
    text-shadow: 2px 2px 5px #ccc;
  }

  .qrcode-view {
    width: 200px;
    height: 200px;
    background-color: #fff;
    border: 1px solid #eee;
    margin: 20px 0;
    padding: 10px;
    border-radius: 5px;
  }
}
</style>