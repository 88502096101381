<template>
  <div class="content-view" v-title :data-title="$t('设 置')" style="background:#fff; padding: 0px 50px 50px 20px;">
    <el-form ref="oven" :model="oven" label-width="auto" v-if="userInfo.superior_id == 0"
      style="padding-top: 30px; display: flex; flex-direction: column;">
      <el-form-item :label="$t('烤箱提示信息')">
        <el-input type="textarea" v-model="oven.ovenWarning" style="width:500px;" :rows="5"></el-input>
      </el-form-item>
      <el-form-item style="margin-top: 20px; align-self: flex-end;width:500px; ">
        <el-button type="primary" @click="onSubmit('form')" :disabled="clickSubmit">{{$t('提交')}}</el-button>
      </el-form-item>
    </el-form>
    <el-form ref="deviceWarn" :model="deviceWarn" label-width="auto"
      style="padding-top: 30px; display: flex; flex-direction: column;">
      <el-form-item :label="$t('设备预警数量')">
        <el-input type="text" v-model="deviceWarn.warn" :placeholder="$t('请输入设备预警数量')"
          oninput="this.value = this.value.replace(/[^0-9]/g, '')" style="width:500px;"></el-input>
      </el-form-item>
      <el-form-item style="margin-top: 20px; align-self: flex-end;width:500px; ">
        <el-button type="primary" @click="deviceWarnSubmit('deviceWarn')">{{$t('提交')}}</el-button>
      </el-form-item>
    </el-form>
    <el-form :model="form" ref="form" label-width="auto" inline>
      <el-form-item class="image-upload" style="padding: 50px;">
        <div class="upload-container">
          <label class="upload-label">{{$t('登录头部图片')}}</label>
          <el-upload ref="uploader" class="avatar-uploader bigImg" :action="'/api/advert/upload'" :headers="headers"
            list-type="picture" name="image" :data="{ type: 0 }" :accept="'image/jpeg,image/png'"
            :show-file-list="false" :auto-upload="true" :before-upload="beforeAvatarUpload1"
            :on-success="handleAvatarSuccess1">
            <div slot="tip" class="el-upload__tip">{{$t('只能上传jpg和png文件')}}</div>
            <el-image v-if="imageUrl1" :src="imageUrl1" class="avatar"></el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon bigImg"></i>
          </el-upload>
        </div>
      </el-form-item>
      <el-form-item class="image-upload" style="padding: 50px;">
        <div class="upload-container">
          <label class="upload-label">{{$t('个人信息头部图片')}}</label>
          <el-upload ref="uploader" class="avatar-uploader bigImg" :action="'/api/advert/upload'" :headers="headers"
            list-type="picture" name="image" :data="{ type: 0 }" :accept="'image/jpeg,image/png'"
            :show-file-list="false" :auto-upload="true" :before-upload="beforeAvatarUpload2"
            :on-success="handleAvatarSuccess2">
            <div slot="tip" class="el-upload__tip">{{$t('只能上传jpg和png文件')}}</div>
            <el-image v-if="imageUrl2" :src="imageUrl2" class="avatar"></el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon bigImg"></i>
          </el-upload>
        </div>
      </el-form-item>
      <!-- <el-form-item class="image-upload" style="padding: 50px;">
        <div class="upload-container">
          <label class="upload-label">选购头部图片</label>
          <el-upload ref="uploader" class="avatar-uploader bigImg" :action="'/api/advert/upload'"
            :headers="headers" list-type="picture" name="image" :data="{ type: 0 }" :accept="'image/jpeg,image/png'"
            :show-file-list="false" :auto-upload="true" :before-upload="beforeAvatarUpload3"
            :on-success="handleAvatarSuccess3">
            <div slot="tip" class="el-upload__tip">只能上传jpg和png文件</div>
            <el-image v-if="imageUrl3" :src="imageUrl3" class="avatar"></el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon bigImg"></i>
          </el-upload>
        </div>
      </el-form-item> -->
    </el-form>
    <!-- <div slot="footer" class="dialog-footer" style="display: flex; justify-content: flex-end;">
      <el-button type="primary" style="text-align: right;" @click="createSubmit('form')">确定</el-button>
    </div> -->

  </div>
</template>

<script>
// import { deepClone } from '@/utils/index'
import _ from 'lodash'
export default {
  name: "agent",
  data() {
    return {
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      clickSubmit: false,
      oven: {
        ovenWarning: ''
      },
      deviceWarn: {
        warn: 0
      },
      imageUrl1: '',
      imageUrl2: '',
      imageUrl3: '',
      form: {
        id: '',
        cover_img1: [],
        cover_img2: [],
        cover_img3: [],
      },
      deleteForm: {

      }
    };
  },
  created() {
    this.getImg();
    this.getInfo()
  },
  methods: {
    /**
     * 获取信息
     */
    getInfo() {
      this.$get('/api/oven/getOven').then(res => {
        this.oven.ovenWarning = res.text;
        this.deviceWarn.warn = res.warn;
      })
    },
    onSubmit() {
      console.log("🚀 ~ onSubmit ~ this.form.ovenWarning:", this.oven.ovenWarning)
      this.$post('/api/oven/setOven', {
        ovenWarning: this.oven.ovenWarning,
      }).then(res => {
        console.log("🚀 ~ this.$post ~ res:", res)
        this.$message({
          message: '提交成功',
          type: 'success'
        })
      }).catch(err => {

      })
    },
    deviceWarnSubmit() {
      console.log("🚀 ~ onSubmit ~ this.form.ovenWarning:", this.deviceWarn.warn)
      this.$post('/api/device/setWarn', {
        deviceWarn: this.deviceWarn.warn,
      }).then(res => {
        console.log("🚀 ~ this.$post ~ res:", res)
        this.$message({
          message: '提交成功',
          type: 'success'
        })
        this.getInfo();
      }).catch(err => {

      })
    },
    handleAvatarSuccess1(res, file) {
      this.deleteForm = _.cloneDeep(this.form);
      this.imageUrl1 = file.response.data.url;
      this.form.cover_img1 = [];
      this.form.cover_img1.push(file.response.data.name)
      this.createSubmit();
      this.$post('/api/file/del', {
        name: this.deleteForm.cover_img1[0],
        file_type: 'image',
        module: 'advert'
      }).then(res => {

      }).catch(error => {

      })
    },
    beforeAvatarUpload1(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      return isJPG;
    },
    handleAvatarSuccess2(res, file) {
      this.deleteForm = _.cloneDeep(this.form);
      this.imageUrl2 = file.response.data.url;
      this.form.cover_img2 = [];
      this.form.cover_img2.push(file.response.data.name)
      this.createSubmit();
      this.$post('/api/file/del', {
        name: this.deleteForm.cover_img2[0],
        file_type: 'image',
        module: 'advert'
      }).then(res => {

      }).catch(error => {

      })
    },
    beforeAvatarUpload2(file) {

      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      return isJPG;
    },
    handleAvatarSuccess3(res, file) {
      this.deleteForm = _.cloneDeep(this.form);
      this.imageUrl3 = file.response.data.url;
      this.form.cover_img3 = [];
      this.form.cover_img3.push(file.response.data.name)
      this.createSubmit();
      this.$post('/api/file/del', {
        name: this.deleteForm.cover_img3[0],
        file_type: 'image',
        module: 'advert'
      }).then(res => {

      }).catch(error => {

      })
    },
    beforeAvatarUpload3(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      return isJPG;
    },
    getImg() {
      this.$get('/api/picture/getAgentImg', {
      }).then(res => {
        let { id, cover_img1_url, cover_img2_url, cover_img3_url } = res
        this.form = { id, cover_img1: [], cover_img2: [], cover_img3: [] }

        if (cover_img1_url && cover_img1_url.length > 0) {
          this.form.cover_img1.push(cover_img1_url[0].name);
          this.imageUrl1 = cover_img1_url[0].url;
        }

        if (cover_img2_url && cover_img2_url.length > 0) {
          this.form.cover_img2.push(cover_img2_url[0].name);
          this.imageUrl2 = cover_img2_url[0].url;
        }

        if (cover_img3_url && cover_img3_url.length > 0) {
          this.form.cover_img3.push(cover_img3_url[0].name);
          this.imageUrl3 = cover_img3_url[0].url;
        }
      }).catch(res => {
      })
    },
    createSubmit() {

      if (!this.form.id) {
        const res1 = this.$post('/api/picture/createAgentImg', { form: this.form }).then(res => {
          this.$message.success('设置成功');
          this.getImg()
        }).catch(error => {
          return this.$message.error(error)
        })
      } else {
        const res1 = this.$post('/api/picture/updateAgentImg', { form: this.form }).then(res => {
          this.$message.success('设置成功');
          this.getImg()
        }).catch(error => {
          return this.$message.error(error)
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.el-upload__tip .el-icon-delete {
  cursor: pointer;
}

.content-view {
  width: 60%;
}

.image-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-container {
  margin-top: 10px;
  text-align: center;
}

.upload-label {
  margin-bottom: 10px;
}

.bigImg {
  width: 200px;
  height: 200px;
}

.avatar-uploader .el-upload .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px solid #d9d9d9;
}

.btns-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
}

.breadcrumb-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #eee;
  padding: 10px;

  .breadcrumb-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    .name {
      font-size: 14px;
      cursor: pointer;
    }

    .name:hover {
      text-decoration: underline;
    }

    .el-icon-arrow-right {
      font-size: 14px;
      color: #9d9d9d;
      margin-left: 5px;
    }
  }

  .breadcrumb-item:not(:first-child) {
    margin-left: 5px;
  }

  .breadcrumb-item:first-child .name {
    font-weight: 500;
  }
}

.table-row:hover {
  .el-icon-edit-outline {
    display: block;
  }
}

.row-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .username {
    margin-left: 10px;
  }

  .el-icon-edit-outline {
    margin-left: 10px;
    display: none;
    color: #9d9d9d;
    cursor: pointer;
  }
}

.level {
  padding: 5px 10px;
  color: #fff;
}

.qrcode {
  width: 150px;
  cursor: pointer;
}

.qrcode:active {
  opacity: 0.7;
}

.fa-qrcode:hover {
  color: #ccc;
}

.fa-bluetooth {
  margin-left: 10px;
  color: #1870d2;
}

.fa-bluetooth:hover {
  opacity: 0.7;
}

.group-text {
  padding: 5px 10px;
  color: #fff;
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: 0.5em;
}

.circle:hover {
  opacity: 0.7;
}

.el-table {
  .online {
    background-color: #67c23a;
  }

  .offline {
    background-color: #f56c6c;
  }
}

.detail-view {
  width: 100%;
  display: flex;
  flex-direction: column;

  .row-view {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    padding: 10px 0;

    .title {
      width: 120px;
      text-align: right;
    }

    .content {
      width: calc(100% - 150px);
      margin-left: 30px;
      color: #888;
      display: flex;
      flex-direction: row;
      align-items: center;

      .goods-img {
        width: 50px;
        height: 50px;
      }

      .goods-name {
        margin-left: 20px;
      }

      .goods-price {
        color: #f56c6c;
        margin-left: 20px;
      }
    }

    .online {
      color: #67c23a;
    }

    .offline {
      color: #f56c6c;
    }
  }
}

.top-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    font-size: 14px;
  }
}

.online-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .online-point {
    width: 10px;
    height: 10px;
  }

  .online-title {
    font-size: 14px;
    margin-left: 10px;
  }

  .online {
    background-color: #67c23a;
  }

  .offline {
    background-color: #ccc;
  }
}

.status-online {
  color: #67c23a;
}

.status-offline {
  color: #ccc;
}
</style>