<template>
    <div class="p-10">
        <el-row class="pl30 pr-30 bg-white">
            <el-col>
                <el-form ref="form" :rules="rules" :model="form" label-width="auto">
                    <h4>{{$t('型号信息')}}</h4>

                    <!-- <el-form-item label="所属代理">
                <el-autocomplete
                v-model="form.agent_id"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入内容"
                @select="handleSelect"
                ></el-autocomplete>
              </el-form-item> -->

                    <el-form-item :label="$t('名称')">
                        <el-input v-model="form.name" placeholder="" style="width:200px"></el-input>
                    </el-form-item>

                    <el-form-item :label="$t('支持类型')">
                        <el-radio v-model="form.product_sk" :label="0" size="small" border>{{$t('售货机')}}</el-radio>
                        <!-- <el-radio style="margin-left:-25px;" v-model="form.product_sk" :label="1" size="small"
                            border>套线二合一</el-radio> -->

                    </el-form-item>

                    <h4>{{$t('货道信息')}}</h4>
                    <div v-for="(item, index) in form.lanes" :key="index"
                        style="display:flex;align-items:center;margin:10px 0 20px 10px;height:30px;">
                        <div style="color:#8BC34A;font-size:20px;margin-top: -10px;">{{ index + 1 }}</div>
                        <div style="margin-top: -8px;">{{$t('货道')}}</div>

                        <div style="margin-left:10px;">
                            {{$t('容量')}}<el-input-number v-model="form.lanes[index].max_num" :placeholder="$t('容量')"
                                style="margin-left:5px;"></el-input-number>
                        </div>
                        <div style="margin-left:10px;">
                            <i v-if="index == 0" class="el-icon-setting" style="color:#607d8b;"></i>
                            <i v-else class="el-icon-delete-solid" @click="deleteClick(index)"
                                @mouseover="changeColor($event, '#000')" @mouseout="changeColor($event, '#607d8b')"
                                style="color:#607d8b;cursor: pointer;"></i>
                        </div>

                    </div>

                    <div style="margin-left:700px;">
                        <!-- <i ></i> -->
                        <el-button type="success" icon="el-icon-plus" size="small"
                            @click="addLaneClick">{{$t('添加货道')}}</el-button>
                    </div>

                    <el-form-item>
                        <el-button class="mt-10 mb-10" type="primary" @click="onSubmit('form')"
                            :disabled="clickSubmit">{{$t('立即提交')}}</el-button>
                    </el-form-item>
                </el-form>



            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            value: '',
            form: {
                name: '',
                product_sk: 0, // 0:售货机 1:套线二合一
                lanes: [
                    {},
                ] // 货道列表

            },
            clickSubmit: false,
            rules: {

            },
            goodsList: [
                {
                    id: 1,
                    name: "商品一"
                }
            ],
            id: this.$route.query.id || '',
            querySearchAsync: function (queryString, cb, index) {
                this.$get('/admin/goods/list', {
                    keyword: queryString,
                    page_size: 20
                }).then(res => {
                    let data = [];
                    for (let i in res.data) {
                        let item = res.data[i];
                        data.push({ value: item.name, id: item.id });
                    }
                    console.log('data', data);
                    cb(data);
                });
            },
            params: {}
        }

    },
    mounted() {
        if (this.id > 0) this.getInfo()
    },
    computed: {
        goods: {
            get() {
                console.log('goods value');
            },
            set(value) {

            }
        }
    },
    methods: {
        remoteMethod() {
            console.log('123');
        },
        onSubmit() {
            // return console.log('this.form',this.form);
            // return console.log(this.form);
            if (this.id) {
                this.$post('/api/deviceType/update', {
                    id: this.id,
                    lanes: this.form.lanes,
                    product_sk: this.form.product_sk,
                    name: this.form.name,
                }).then(res => {
                    this.$message({
                        message: '编辑成功',
                        type: 'success'
                    });
                    this.$router.push({
                        path: '/TypeIndex'
                    })
                }).catch(error => {
                })
            } else {
                this.$post('/api/deviceType/add', {
                    name: this.form.name,
                    lanes: this.form.lanes,
                    product_sk: this.form.product_sk
                }).then(res => {
                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    });
                    this.$router.push({
                        path: '/TypeIndex'
                    })
                }).catch(error => { })
            }
        },
        handleSelect(item, index) {
            this.form.lanes[index].goods_id = item.id
        },
        changeColor(event, color) {
            event.srcElement.style.color = color;
        },
        deleteClick(index) {
            console.log(index);
            let arrL = index;
            this.form.lanes.splice(arrL, 1);
        },
        getInfo() {
            console.log('getInfo');
            this.$get('/api/deviceType/info', {
                id: this.id
            }).then(res => {
                // console.log('res',res);
                let data = res.info;
                this.form = {
                    name: data.name,
                    product_sk: data.product_sk,
                    lanes: data.extra.lanes
                }
            })
        },
        billingChange() {
            console.log('method', this.form.billing_method);
        },
        addLaneClick() {
            console.log('addLaneClick');
            for (let i = 0; i < 10; i++) {
                this.form.lanes.push({
                    goods_id: '',
                    max_num: 1
                });
            }
            console.log('laneList', this.form.lanes);

        },
        changeColor(event, color) {
            event.srcElement.style.color = color;
        },

        getSummaries(param) {
            console.log('param', param);
            // const {columns,data} = param;
            let plusIcon = this.$createElement('el-button', {
                attrs: { icon: 'el-icon-plus' },
                on: { click: this.addLaneClick }
            });
            const sums = [plusIcon]; // ,'N/A','1883元','15.83元','63元'

            // columns.forEach((col,idx)=>{
            //     if(idx == 0){
            //         sums[index] = '总价';
            //         return;
            //     }
            // });
            return sums;

        }
    }
}
</script>./TypeEdit.vue