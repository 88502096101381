<template>
  <div>
    <el-form :inline="true" class="search-form">
      <!-- 根据代理商查询 -->
      <el-form-item label="openId：">
        <el-input v-model="agent" :placeholder="$t('请输入openId')"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchByAgent">{{$t('查询')}}</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchByAgentAll">{{$t('展开所有')}}</el-button>
      </el-form-item>
    </el-form>

    <el-table class="ptd-5" id="list_table" ref="list_table" v-loading="listLoading" :data="agents"
     element-loading-text="Loading" stripe highlight-current-row>
      <el-table-column :label="$t('头像')" width="80">
        <template slot-scope="scope">
          <el-avatar :size="40" :src="scope.row.avatar" fit="fill" icon="el-icon-picture-outline"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column label="openid" >
        <template slot-scope="scope">
          <el-link class="cursor">{{ scope.row.openid }}</el-link>
        </template>
      </el-table-column>
      <el-table-column :label="$t('昵称')" width="200">
        <template slot-scope="scope">
          <el-link class="cursor">【{{ scope.row.id }}】{{ scope.row.nick_name }}</el-link>
        </template>
      </el-table-column>
      <el-table-column :label="$t('订单数')">
        <template slot-scope="scope">
          {{ scope.row.order_count }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('消费总金额')">
        <template slot-scope="scope">
          ￥{{ scope.row.order_sum }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('总金额')">
        <template slot-scope="scope">
          ￥{{ scope.row.money }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('注册日期')">
        <template slot-scope="scope">
          {{ scope.row.create_time }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="page" :page-size="pagesize"
      :total="total" @current-change="agentPageChange"></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      agent: '',
      agentOptions: [],
      agents: [],
      page: 1,
      pagesize: 20,
      total: 0,
      listLoading:false
    };
  },
  created() {
    this.reflectList();
    // this.searchName();
    // this.agent.loading = true;
  },

  methods: {
    reflectList(keyword) {
      this.$get('/api/user/list', {
        page: this.page,
        page_size: this.page_size,
        keyword: this.agent,
      }).then(res => {
        this.listLoading = false;
        this.agents = res.data;
        this.total = res.total;
      }).catch(err => {

      });
    },
    searchByAgentAll() {
      this.agent = '';
      this.reflectList();
    },
    agentPageChange(val) {
      this.page = val;
      this.reflectList();
    },

    searchByAgent() {
      // 根据代理商查询逻辑
        this.reflectList(this.agent);
      // if (this.agent) {
      // } else {
      //   console.log('请选择代理商进行查询');
      // }
    },
    upApply(row, agree) {
      if (agree == 3) {
        this.$alert(this.$tt.t('确定该笔提现申请通过审核吗？'), this.$tt.t('提现审核'), {
          confirmButtonText: this.$tt.t('确定'),
          callback: action => {
            if (action == 'confirm') {
              this.postApply(row, agree)
            }
          }
        })
      } else if (agree == 2) {
        this.$alert(this.$tt.t('确定驳回该笔提现申请吗？'), this.$tt.t('提现审核'), {
          confirmButtonText: this.$tt.t('确定'),
          callback: action => {
            if (action == 'confirm') {
              this.postApply(row, agree)
            }
          }
        })
      }
    },
    postApply(row, agree) {
      let url = '/api/withdraw/update'
      this.$post(url, {
        id: row.id,
        status: agree
      }).then(res => {
        this.$message({
          message: this.$tt.t('提交成功'),
          type: 'success'
        })
        this.reflectList();
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.btns-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
}

.breadcrumb-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #eee;
  padding: 10px;

  .breadcrumb-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    .name {
      font-size: 14px;
      cursor: pointer;
    }

    .name:hover {
      text-decoration: underline;
    }

    .el-icon-arrow-right {
      font-size: 14px;
      color: #9d9d9d;
      margin-left: 5px;
    }
  }

  .breadcrumb-item:not(:first-child) {
    margin-left: 5px;
  }

  .breadcrumb-item:first-child .name {
    font-weight: 500;
  }
}

.table-row:hover {
  .el-icon-edit-outline {
    display: block;
  }
}

.row-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .username {
    margin-left: 10px;
  }

  .el-icon-edit-outline {
    margin-left: 10px;
    display: none;
    color: #9d9d9d;
    cursor: pointer;
  }
}

.level {
  padding: 5px 10px;
  color: #fff;
}

.qrcode {
  width: 150px;
  cursor: pointer;
}

.qrcode:active {
  opacity: 0.7;
}

.fa-qrcode:hover {
  color: #ccc;
}

.fa-bluetooth {
  margin-left: 10px;
  color: #1870d2;
}

.fa-bluetooth:hover {
  opacity: 0.7;
}

.group-text {
  padding: 5px 10px;
  color: #fff;
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: 0.5em;
}

.circle:hover {
  opacity: 0.7;
}

.el-table {
  .online {
    background-color: #67c23a;
  }

  .offline {
    background-color: #f56c6c;
  }
}

.detail-view {
  width: 100%;
  display: flex;
  flex-direction: column;

  .row-view {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    padding: 10px 0;

    .title {
      width: 120px;
      text-align: right;
    }

    .content {
      width: calc(100% - 150px);
      margin-left: 30px;
      color: #888;
      display: flex;
      flex-direction: row;
      align-items: center;

      .goods-img {
        width: 50px;
        height: 50px;
      }

      .goods-name {
        margin-left: 20px;
      }

      .goods-price {
        color: #f56c6c;
        margin-left: 20px;
      }
    }

    .online {
      color: #67c23a;
    }

    .offline {
      color: #f56c6c;
    }
  }
}

.top-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    font-size: 14px;
  }
}

.online-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .online-point {
    width: 10px;
    height: 10px;
  }

  .online-title {
    font-size: 14px;
    margin-left: 10px;
  }

  .online {
    background-color: #67c23a;
  }

  .offline {
    background-color: #ccc;
  }
}

.status-online {
  color: #67c23a;
}

.status-offline {
  color: #ccc;
}
</style>