<template>
  <div>
    <el-form :inline="true" class="search-form">


      <!-- 根据代理商查询 -->
      <!-- <el-form-item label="代理商：">
        <el-select v-model="agent" placeholder="请选择代理商">
          <el-option v-for="option in agentOptions" :key="option.value" :label="option.label"
            :value="option.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchByAgent">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchByAgentAll">展开所有</el-button>
      </el-form-item> -->
    </el-form>

    <el-table class="ptd-5" id="list_table" ref="list_table" v-loading="listLoading" :data="agents"
      :max-height="tableMaxH" element-loading-text="Loading" stripe highlight-current-row>
      <el-table-column prop="id" label="ID" width="50" />
      <el-table-column :label="$t('名称')">
        <template slot-scope="scope">
          <el-tooltip effect="dark" placement="top-start">
            <template #content>
              {{$t('型号')}}ID:{{ scope.row.id }}
            </template>
            <span>{{ scope.row.name }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column :label="$t('货道容量')" prop="lane_num" />
      <!-- <el-table-column label="设备数量" prop="device_num"/> -->
      <el-table-column :label="$t('支持类型')" prop="product_sk_txt">
      </el-table-column>
      <el-table-column :label="$t('创建时间')" prop="create_time" />
      <el-table-column :label="$t('操作')">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" plain round
            @click="$router.push({ path: `/typeEdit?id=${scope.row.id}` })">{{$t('编辑型号')}}</el-button>
          <!-- <el-button type="primary" size="mini" plain round @click="$router.push({path: `/equipment/index?device_type_id=${scope.row.id}`})">型号设备</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="page" :page-size="pagesize"
      :total="total" @current-change="agentPageChange"></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      agent: '',
      agentOptions: [],
      agents: [],
      page: 1,
      pagesize: 20,
      total: 0,
    };
  },
  created() {
    this.reflectList();
    this.searchName();
    // this.agent.loading = true;
  },

  methods: {
    reflectList(keyword) {
      this.$get('/api/deviceType/list', {
        page: this.page,
        page_size: this.page_size,
        keyword: this.agent,
      }).then(res => {
        this.listLoading = false;
        this.agents = res.data;
        this.total = res.total;
      }).catch(err => {

      });
    },
    searchByAgentAll() {
      this.agent = '';
      this.reflectList();
    },
    agentPageChange(val) {
      this.page = val;
      this.reflectList();
    },
    searchName() {
      this.$get('/api/deviceType/list').then(res => {
        console.log("🚀 ~ this.$get ~ res:", res)
        this.agentOptions = agentOptions;

      }).catch(error => {

      })
    },
    searchByAgent() {
      // 根据代理商查询逻辑
      if (this.agent) {
        this.reflectList(this.agent);
      } else {

      }
    },
  }
};
</script>

<style lang="scss" scoped>
.btns-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
}

.breadcrumb-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #eee;
  padding: 10px;

  .breadcrumb-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    .name {
      font-size: 14px;
      cursor: pointer;
    }

    .name:hover {
      text-decoration: underline;
    }

    .el-icon-arrow-right {
      font-size: 14px;
      color: #9d9d9d;
      margin-left: 5px;
    }
  }

  .breadcrumb-item:not(:first-child) {
    margin-left: 5px;
  }

  .breadcrumb-item:first-child .name {
    font-weight: 500;
  }
}

.table-row:hover {
  .el-icon-edit-outline {
    display: block;
  }
}

.row-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .username {
    margin-left: 10px;
  }

  .el-icon-edit-outline {
    margin-left: 10px;
    display: none;
    color: #9d9d9d;
    cursor: pointer;
  }
}

.level {
  padding: 5px 10px;
  color: #fff;
}

.qrcode {
  width: 150px;
  cursor: pointer;
}

.qrcode:active {
  opacity: 0.7;
}

.fa-qrcode:hover {
  color: #ccc;
}

.fa-bluetooth {
  margin-left: 10px;
  color: #1870d2;
}

.fa-bluetooth:hover {
  opacity: 0.7;
}

.group-text {
  padding: 5px 10px;
  color: #fff;
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: 0.5em;
}

.circle:hover {
  opacity: 0.7;
}

.el-table {
  .online {
    background-color: #67c23a;
  }

  .offline {
    background-color: #f56c6c;
  }
}

.detail-view {
  width: 100%;
  display: flex;
  flex-direction: column;

  .row-view {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    padding: 10px 0;

    .title {
      width: 120px;
      text-align: right;
    }

    .content {
      width: calc(100% - 150px);
      margin-left: 30px;
      color: #888;
      display: flex;
      flex-direction: row;
      align-items: center;

      .goods-img {
        width: 50px;
        height: 50px;
      }

      .goods-name {
        margin-left: 20px;
      }

      .goods-price {
        color: #f56c6c;
        margin-left: 20px;
      }
    }

    .online {
      color: #67c23a;
    }

    .offline {
      color: #f56c6c;
    }
  }
}

.top-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    font-size: 14px;
  }
}

.online-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .online-point {
    width: 10px;
    height: 10px;
  }

  .online-title {
    font-size: 14px;
    margin-left: 10px;
  }

  .online {
    background-color: #67c23a;
  }

  .offline {
    background-color: #ccc;
  }
}

.status-online {
  color: #67c23a;
}

.status-offline {
  color: #ccc;
}
</style>