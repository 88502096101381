<template>
  <div class="content-view" v-title data-title="'订单管理'">
    <div class="top-view">
      <span class="title">{{$t('订单号')}}：</span>
      <el-input style="width:350px;" :placeholder="$t('请输入订单号')" v-model="listQuery.order_no" clearable @clear="searchClick"
        @keyup.enter.native="searchClick">
        <el-button slot="append" icon="el-icon-search" @click="searchClick"></el-button>
      </el-input>
      <span class="title" style="margin-left: 20px;">{{$t('设备')}}：</span>
      <el-select v-model="listQuery.device_id" :placeholder="$t('请输入关键词')" filterable remote clearable
        :remote-method="searchDevice" :loading="device.loading" @change="deviceChange">
        <el-option v-for="(item, index) in device.list" :key="index" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <span class="title" style="margin-left: 20px;">{{$t('日期')}}：</span>
      <el-date-picker v-model="listQuery.dateArray" type="daterange" value-format="yyyy-MM-dd" :picker-options="dateOptions" unlink-panels
        @change="dateChange"></el-date-picker>
      <el-button type="primary" style="margin-left:20px;" :icon="exporting ? 'el-icon-loading' : ''"
        :disabled="exporting" @click="exportClick">{{ exporting ? percent + '%' : $t('导出') }}</el-button>
    </div>

    <el-tabs v-model="listQuery.type" type="card" style="margin-top:10px;" @tab-click="tabClick">
      <el-tab-pane :label="$t('售卖')" name="0"></el-tab-pane>
      <!-- <el-tab-pane label="充电" name="1"></el-tab-pane>
      <el-tab-pane label="虚拟" name="2"></el-tab-pane> -->
    </el-tabs>
    <el-table :data="order.list" style="margin-top:10px;" v-loading="order.loading">
      <el-table-column label="#" type="index" width="50"></el-table-column>
      <el-table-column :label="$t('订单号')" min-width="180">
        <template slot-scope="scope">
          <!-- <span v-if="scope.row.voucher">提货码：{{ scope.row.voucher.code }}</span>
          <span v-else-if="scope.row.account">公众号：{{
            typeof scope.row.account === 'object' ? scope.row.account.title :
              scope.row.account
          }}</span> -->
          <span v-if="!scope.row.transaction_id && !scope.row.orderId" style="color:#9d9d9d;">
            <span class="order-text">{{ scope.row.order_no }}</span>
          </span>
          <div class="column-view" v-else>
            <span>{{ scope.row.transaction_id }}</span>
            <span class="order-text">{{ scope.row.order_no }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('商品')" min-width="180">
        <template slot-scope="scope">
          <div class="column-view" v-if="scope.row.extra.goods && scope.row.extra.goods.length > 0">
            <span v-for="(item, index) in scope.row.extra.goods" :key="index"
              :class="{ 'refund-text': item.status == 2 }">{{ item.name }} * {{ item.num }}</span>
            <div>
              <span>¥ {{ scope.row.amount }}</span>
            </div>
          </div>
          <div class="column-view" v-else-if="scope.row.extra.user.notes && scope.row.extra.user.notes.length > 0">
            <span>{{ scope.row.extra.user.notes }}</span>
            <div>
              <span>¥ {{ scope.row.amount }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('来源')" min-width="50">
        <template slot-scope="scope">
          <el-tooltip effect="dark" :content="getAccountTitle(scope.row)" placement="right">
            <el-image class="from-icon" :src="getAccountIcon(scope.row)" fit="fill" lazy></el-image>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column :label="$t('用户')" min-width="200">
        <template slot-scope="scope">
          <div class="order-user" v-if="scope.row.extra.user.openid">
            <span class="username">{{ scope.row.extra.user.openid }}</span>
          </div>
          <div class="order-user" v-else-if="scope.row.extra.user == '非会员支付'">
            <span class="username">{{ scope.row.extra.user }}</span>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="商品" min-width="150">
        <template slot-scope="scope">
          <div class="column-view">
            <span v-for="(item,index) in scope.row.extra.goods" :key="index" :class="{ 'refund-text': item.status == 2 }">{{ item.name }} * {{ item.num }}</span>
            <div>
              <span>¥ {{ scope.row.amount }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="用户" min-width="180">
        <template slot-scope="scope">
          <div class="row-view">
            <div>
              <el-image class="avatar" :src="scope.row.user_avatar" fit="fill" lazy></el-image>
            </div>
            <span class="username">{{ scope.row.user_nickname }}</span>
          </div>
        </template>
      </el-table-column>
      
      <el-table-column label="类型">
        <template slot-scope="scope">
          <span v-if="scope.row.source_method == 0">售卖</span>
          <span v-if="scope.row.source_method == 1">充电</span>
          <span v-if="scope.row.source_method == 2">虚拟货道</span>
        </template>
      </el-table-column>
      <el-table-column label="充电套餐">
        <template slot-scope="scope">
          <span v-if="scope.row.source_method == 1">
            <span v-if="scope.row.is_sustain == 1">套餐计费</span>
            <span v-if="scope.row.is_sustain == 0">按时计费</span>
          </span>
          <span v-else>--</span>
          
        </template>
      </el-table-column> -->
      <el-table-column prop="device_imei" :label="$t('设备')" min-width="120"></el-table-column>
      <!-- <el-table-column label="地区/IP" min-width="180">
        <template slot-scope="scope">
          <div class="column-view">
            <span>{{ scope.row.ip_info }}</span>
            <span class="ip-text">{{ scope.row.ip }}</span>
          </div>
        </template>
      </el-table-column> -->
      <el-table-column :label="$t('创建时间')" min-width="200">
        <template slot-scope="scope">
          {{ parseTime(scope.row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="agent_name" :label="$t('所属')" min-width="200">
        <template slot-scope="{ row }">
          <div style="
          color: #333;
          padding: 5px 10px;
          border-radius: 4px;
          ">{{ row.agent_name }}</div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('操作')" min-width="100" align="center">
        <template slot-scope="scope">
          <!-- scope.row.is_sustain && scope.row.status == 0 删除 from Leo Morgen -->
          <span v-if="scope.row.status == 0" style="color:#909399;">
            <div>{{$t('进行中')}}</div>
            <!-- <el-button type="warning" size="mini" @click="endOrder(scope.row)">结束订单</el-button> -->
          </span>
          <span v-if="scope.row.status == 1" style="color:#67C23A;">
            <div>{{$t('已完成')}}</div>
            <el-button type="danger" @click="refundClick(scope.row)" size="mini">{{$t('退款')}}</el-button>
          </span>
          <span v-if="scope.row.status == 2" style="color:#E6A23C;">{{$t('已退款')}}</span>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="order.page"
      :page-size="order.page_size" :total="order.total" @current-change="orderPageChange"></el-pagination>


    <el-dialog :title="$t('订单退款')" width="600px" :visible.sync="refund.visible">
      <el-form :model="refund.detail" label-width="120px" v-if="refund.detail">
        <el-form-item :label="$t('订单编号')">
          <span>{{ refund.detail.transaction_id }}</span>
        </el-form-item>
        <el-form-item :label="$t('商品金额')">

          <el-input v-model="inputAmount" controls-position="right" :min="0.01" :max="refund.detail.amount" :step="1"
            style="width: 200px" @input="handleInput"></el-input>
          <!-- <strong><span>{{refund.detail.amount}}</span></strong> -->
        </el-form-item>
        <span class="tips">* {{$t('请输入要退款的金额，最小退款金额')}}：0.01</span>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="refund.visible = false">{{$t('取 消')}}</el-button>
        <el-button type="primary" :loading="refund.loading" @click="refundWithNumClick">{{$t('退 款')}}</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="$t('结束订单')" :visible.sync="order.endVisible" :close-on-click-modal="false" width="300px">
      <div>
        <div>{{$t('请选择结束时间')}}</div>
        <el-date-picker v-model="order.endTime" type="datetime" :placeholder="$t('选择日期时间')">
        </el-date-picker>
      </div>

      <template slot="footer">
        <el-button @click="order.endVisible = false" size="small">{{$t('取消')}}</el-button>
        <el-button @click="endOrderSubmit" size="small" type="primary">{{$t('提交')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
import FileSaver from 'file-saver'
import * as ExcelJS from 'exceljs'
import { parseTime } from '@/utils/index'
// import i18n from '../i18n/index.js'
export default {
  name: "order",
  data() {
    return {
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      inputAmount: 0.00,
      order: {
        list: [],
        page: 1,
        page_size: 20,
        total: 0,
        loading: false,
        endVisible: false,
        endTime: 0,
        endId: 0
      },
      keyword: "",
      device: {
        list: [],
        page: 1,
        pagesize: 20,
        loading: false
      },
      deviceid: null,
      dateArray: null,
      dateOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      exporting: false,
      refund: {
        visible: false,
        detail: null,
        num: 0,
        loading: false
      },
      percent: 0,
      listQuery: {
        order_no: '',
        device_id: '',
        type: '1',
        page: 1,
        page_size: 20,
        dateArray: []
      },
      device: {
        loading: false,
        list: []
      },
      activeName: '',
    };

  },
  created() {
    this.getOrderList();
  },

  methods: {



    handleInput() {
      this.inputAmount = this.inputAmount.replace(/[^\d.]/g, '');
      // 限制小数点后最多两位
      let parts = this.inputAmount.split('.');
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        this.inputAmount = parts.join('.');
      }
      if (this.inputAmount > this.refund.detail.amount) {
        this.inputAmount = this.refund.detail.amount;
      } else if (this.inputAmount < 0.01) {
        this.inputAmount = this.refund.detail.amount;
      }
    },




    endOrderSubmit() {
      let endTime = this.order.endTime.getTime() / 1000;
      this.$post('/api/order/end', {
        endTime: endTime,
        id: this.order.endId
      }).then(res => {
        this.$message({
          type: "success",
          message: "解绑成功!",
          center: true
        });
        this.getOrderList();
      }).catch(err => { })
    },
    endOrder(row) {
      this.order.endVisible = true;
      this.order.endTime = new Date();
      this.order.endId = row.id;
      // picker.$emit('pick', new Date());
    },
    getAccountTitle(item) {
      if (item.pay_platform == 1) {
        return this.$tt.t("微信")
      } else if (item.pay_platform == 2) {
        return this.$tt.t('支付宝');
      } else if (item.pay_platform == 3) {
        return this.$tt.t('余额支付');
      } else {
        return this.$tt.t('其他');
      }
    },
    getAccountIcon(item) {
      if (item.pay_platform == 1) {
        console.log('wechat Icon');
        return require('@/assets/wechat.png');
      } else if (item.pay_platform == 2) {
        console.log('alipay Icon');
        return require('@/assets/alipay.png');
      } else if (item.pay_platform == 3) {
        console.log('alipay Icon');
        return require('@/assets/balance.png');
      } else {
        return '';
      }
    },
    searchDevice(keyword) {
      console.log('searchDevice', keyword);

      if (keyword) {
        this.device.page = 1;
        this.device.list = [];
        this.device.loading = true;
        this.$get('/api/device/list', { page: 1, page_size: 20, keyword: keyword }).then(res => {
          // console.log('res--',res);
          // console.log('res.data',res.data);
          this.device.list = res.data;
          this.device.loading = false;
        });
      } else {
        this.device.list = [];
      }
    },
    async exportClick() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet1');
      worksheet.columns = [
        { header: 'ID', key: 'id', width: 15 },
        { header: this.$tt.t('订单号'), key: 'order_no', width: 15 },
        { header: this.$tt.t('支付号'), key: 'transaction_id', width: 15 },
        { header: this.$tt.t('支付类型'), key: 'pay_platform_txt', width: 15 },
        { header: this.$tt.t('用户名'), key: 'user_nickname', width: 15 },
        { header: this.$tt.t('手机号'), key: 'user_mobile', width: 15 },
        { header: this.$tt.t('用户openid'), key: 'openid', width: 15 },
        { header: this.$tt.t('商品ID'), key: 'goods_id', width: 15 },
        { header: this.$tt.t('商品名称'), key: 'goods_name', width: 15 },
        { header: this.$tt.t('商品数量'), key: 'goods_num', width: 15 },
        { header: this.$tt.t('商品价格'), key: 'price', width: 15 },
        { header: this.$tt.t('支付金额'), key: 'amount', width: 15 },
        { header: this.$tt.t('订单状态'), key: 'status_txt', width: 15 },
        { header: this.$tt.t('代理商名称'), key: 'agent_name', width: 15 },
        { header: this.$tt.t('设备名称'), key: 'device_name', width: 15 },
        { header: this.$tt.t('设备IMEI'), key: 'device_imei', width: 15 },
        { header: this.$tt.t('订单时间'), key: 'create_time', width: 15 },
      ];

      let data = [];
      let statusMap = [this.$tt.t('进行中'), this.$tt.t('已结束'), this.$tt.t('已退款')];
      for (let i in this.order.list) {
        let item = this.order.list[i];
        console.log('item-', item);
        let goodsName = [];
        if (item.extra && item.extra.goods && item.extra.goods) {
          // item.extra.goods.forEach(e=>{
          //   return e.name;
          // });
          for (let i in item.extra.goods) {
            let item2 = item.extra.goods[i];
            goodsName.push(item2.name);
          }
        }

        data.push({
          id: item.id,
          order_no: item.order_no,
          transaction_id: item.transaction_id,
          pay_platform_txt: item.pay_platform === 1 ? this.$tt.t('微信') :
            item.pay_platform === 2 ? this.$tt.t('支付宝') :
              item.pay_platform === 3 ? this.$tt.t('余额支付') : this.$tt.t('其他'),
          user_nickname: item.user_nickname,
          user_mobile: item.user_mobile,
          user_mobile: item.user_mobile,
          openid: item.openid ? item.openid : '',
          goods_name: goodsName.join(','),
          goods_num: item.goods_title,
          price: item.price,
          amount: item.amount,
          status_txt: statusMap[item.status],
          agent_name: item.agent_name ? item.agent_name : '',
          device_name: item.device_name ? item.device_name : '',
          device_imei: item.device_imei ? item.device_imei : '',
          create_time: parseTime(item.create_time),
        });
      }
      console.log('data1', data);
      data.forEach((row) => {
        worksheet.addRow(row);
      })

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-fooicedocument.spreadsheetml.sheet' })
      FileSaver(blob,  this.$tt.t('导出订单')+'.xlsx')
    },
    getOrderList() {
      this.order.loading = true;
      // console.log('this.listQuery-',this.listQuery);
      // conorder.list123sole.log('this.listQuery.dateArray--',this.listQuery.dateArray);
      // console.log('this.listQuery.dateArray.length',this.listQuery.dateArray.length);
      if (this.listQuery.dateArray && this.listQuery.dateArray.length > 0) {
        this.listQuery.start_date = this.listQuery.dateArray[0];
        this.listQuery.end_date = this.listQuery.dateArray[1];
      } else {
        delete this.listQuery.start_date;
        delete this.listQuery.end_date;
      }
      // console.log('this.listQuery1',this.listQuery);

      this.$get('/api/order/orderListOnAgent',
        this.listQuery
      ).then(res => {
        console.log('res+++', res);
        this.order.list = res.data;
        this.order.loading = false;
        this.order.total = res.total;
      }).catch(err => {
        this.order.loading = false;

      })
      // let params = {
      //   op: "OrderDefault",
      //   page: this.order.page,
      //   pagesize: this.order.pagesize
      // };
      // if (this.keyword) {
      //   params.order = this.keyword;
      // }
      // if (this.deviceid) {
      //   params.device = this.deviceid;
      // }
      // if (this.dateArray) {
      //   params.start = this.dateArray[0];
      //   params.end = this.dateArray[1];
      // }
      // this.$api.order
      //   .getOrderList(params)
      //   .then(res => {
      //     this.order.loading = false;
      //     if (res.data.status) {
      //       this.order.list = res.data.data.orders;
      //       this.order.total = res.data.data.total;
      //     } else {
      //       this.$alert(res.data.data.msg, "提示");
      //     }
      //   })
      //   .catch(() => {
      //     this.order.loading = false;
      //   });
    },
    orderPageChange(val) {
      this.order.page = val;
      this.listQuery.page = val;
      this.getOrderList();
    },
    refundClick(item) {

      if (item.num == 1) {
        this.$confirm(this.$tt.t("是否确认退款操作"), this.$tt.t("提示"), {
          confirmButtonText: this.$tt.t("确定"),
          cancelButtonText: this.$tt.t("取消"),
          type: "warning"
        }).then(() => {
          this.$post('/api/order/refund', {
            id: item.id
          }).then(res => {
            this.$message({
              message: this.$tt.t('操作成功'),
              type: "success",
              center: true
            });
            this.getOrderList();
          }).catch(err => { })
        }).catch(error => {
          this.$message({
            message: this.$tt.t('退款失败'),
            type: "error",
            center: true
          });
        });
      } else if (item.agent_id !== this.userInfo.id) {
        this.$message({
          message: this.$tt.t('禁止退款其他代理订单'),
          type: "error",
          center: true
        });
      } else {
        item.amount = parseFloat(item.amount);
        this.refund.detail = item;
        this.refund.visible = true;
        this.refund.amount = item.amount;
        this.inputAmount = item.amount;
      }
    },
    refundWithNumClick() {
      if (this.refund.num === undefined) {
        this.$message({
          message: this.$tt.t("请输入商品数量"),
          type: "warning",
          center: true
        });
        return;
      }
      this.refund.loading = true;
      // let params = {
      //   op: "orderRefund",
      //   orderid: this.refund.detail.id,
      //   amount: this.refund.amount
      // };
      this.$post('/api/order/refund', {
        orderid: this.refund.detail.id,
        amount: this.inputAmount
      }).then(res => {
        this.refund.visible = false;
        this.$message({
          message: this.$tt.t('退款成功'),
          type: "success",
          center: true
        });
        this.refund.visible = false;
        this.getOrderList();
      }).finally(() => {
        this.refund.loading = false;
      });
    },
    searchClick() {
      this.getOrderList();
    },
    deviceChange() {
      this.getOrderList();
    },
    dateChange() {
      console.log('dateArray--', this.listQuery.dateArray);
      this.getOrderList();
    },
    tabClick(val) {
      console.log('tabClick', this.activeName);
      this.getOrderList();
    }
  }
};
</script>

<style lang="scss" scoped>
.top-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    font-size: 14px;
  }
}

.column-view {
  display: flex;
  flex-direction: column;
  font-size: 13px;

  .order-text {
    font-size: 12px;
    color: #9d9d9d;
  }

  .ip-text {
    font-size: 12px;
    color: #9d9d9d;
  }

  .fa-exclamation-circle {
    margin-left: 5px;
    cursor: pointer;
    color: #f56c6c;
  }

  .fa-exclamation-circle:hover {
    opacity: 0.7;
  }

  .refund-text {
    text-decoration: line-through;
    color: #9d9d9d;
  }
}

.row-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .username {
    margin-left: 10px;
  }
}

.from-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.tips {
  color: #9d9d9d;
  margin-left: 120px;
  font-size: 14px;
}
</style>