import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import ElementUI from 'element-ui';
import elementVariables from './plugins/element-variables.scss';
import './assets/iconfont/iconfont.css'
import { $get, $post, $test } from './utils/request'
import moment from 'moment';
//Video
import VideoPlayer from 'vue-video-player/src';
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)
import xlsx from 'xlsx';

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})

import i18n from './i18n'






// new Vue({
//   el: '#app',
//   router,
//   store,
//   i18n, // 挂载i18n
//   render: h => h(App)
// })

Vue.prototype.$xlsx = xlsx;

Vue.prototype.$get = $get
Vue.prototype.$post = $post
Vue.prototype.$tt = i18n
Vue.prototype.$moment = moment;
Vue.prototype.$test = $test
Vue.config.productionTip = false

import { parseTime } from './utils/index';

Vue.prototype.parseTime = parseTime

Vue.use(ElementUI);
new Vue({
  router,
  i18n, // 挂载i18n
  render: h => h(App)
}).$mount('#app')
