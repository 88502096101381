<template>
  <div class="content-view" v-title :data-title="$t('下级代理')" style="background:#fff;">
    <div class="top-view" style="padding:10px;">
      <el-button type="success" @click="createAdvert">{{$t('添加广告')}}</el-button>
      <el-input style="width:350px;margin-left: 20px;" :placeholder="$t('请输入关键字搜索')" v-model="searchName" clearable
        @clear="getStaffList" @keyup.enter.native="getStaffList">
        <el-button slot="append" icon="el-icon-search" @click="getStaffList"></el-button>
      </el-input>
      <!-- <el-button type="success" @click="searchDevice">搜索设备</el-button>
      <el-button type="warning" @click="searchAgent">搜索下级</el-button> -->
      <!-- <el-button type="success" @click="createClick">添加会员</el-button> -->
    </div>
    <!-- {{levelArray}} -->
    <div class="breadcrumb-view" v-if="levelArray.length > 1">
      <i class="el-icon-user-solid"></i>

      <div class="breadcrumb-item" v-for="(item, index) in levelArray" :key="index">
        <span class="name" @click="breadcrumbClick(item, index)">{{ item.title }}</span>
        <i class="el-icon-arrow-right" v-if="index != levelArray.length - 1"></i>
      </div>
    </div>

    <el-table :data="list" v-loading="agent.loading" row-class-name="table-row">
      <el-table-column type="index" label="#"></el-table-column>

      <el-table-column prop="name" :label="$t('广告名称')">
      </el-table-column>
      <el-table-column :label="$t('图片')">
        <template slot-scope="scope">
          <el-image style="width: 80px;height:80px" v-for="item, index in scope.row.cover_img_url" :key="index"
            :src="item.url" :preview-src-list="scope.row.cover_img_url.map(item => item.url)">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="sort" :label="$t('排序值')">
      </el-table-column>
      <el-table-column :label="$t('状态')">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 1">{{$t('通过审核')}}</span>
          <span v-if="scope.row.status === 0">{{$t('待审核')}}</span>
          <span v-else-if="scope.row.status === 2">{{$t('被驳回')}}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('操作')" width="400" align="center">
        <template slot-scope="scope">

          <el-button v-if="scope.row.status === 1" type="primary" @click="openEdit(scope.row)"
            size="mini">{{$t('编辑')}}</el-button>

          <el-button v-if="scope.row.status === 1" type="primary" @click="editClick(scope.row)"
            size="mini">{{$t('绑定设备')}}</el-button>

          <el-button v-if="scope.row.status === 1" type="danger" @click="deleteClick(scope.row)"
            size="mini">{{$t('删除')}}</el-button>

          <el-button v-if="scope.row.status === 2 || scope.row.status === 0" type="primary" @click="openEdit(scope.row)"
            size="mini">{{$t('重新编辑')}}</el-button>

          <el-button v-if="scope.row.status === 2 || scope.row.status === 0" type="danger"
            @click="deleteClick(scope.row)" size="mini">{{$t('删除')}}</el-button>
          <!-- <span v-else-if="scope.row.status === 0">待审核</span> -->
        </template>
      </el-table-column>
    </el-table>

    <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="agent.page"
      :page-size="agent.page_size" :total="agent.total" @current-change="agentChange"></el-pagination>


    <el-dialog :title="form.action == 'create' ? $t('添加广告') : $t('编辑广告')" :visible.sync="show" @close="clickFalse">
      <el-form :model="form" ref="form" label-width="auto">
        <el-form-item :label="$t('广告名称')" prop="name" :rules="[{ required: true, message: $t('请输入广告名称'), trigger: 'blur' }]">
          <el-input v-model="form.name" style="width:350px" :placeholder="$t('请输入广告名称')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('排序值')" prop="sort" :rules="[{ required: true, message: $t('请输入排序值'), trigger: 'blur' },
      { pattern: /^[0-9]+$/, message: '请输入数字', trigger: 'blur' }]">
          <el-input v-model="form.sort" style="width:350px" :placeholder="$t('请输入排序值')"></el-input>
        </el-form-item>
        <!-- <el-form-item label="上传图片">
          <el-upload ref="uploader" class="avatar-uploader bigImg" :action="'/api/advert/upload'"
            :headers="headers" list-type="picture" name="image" :data="{ type: 0 }" :accept="'.jpg, .png'"
            :show-file-list="false" :auto-upload="false" :on-change="handleChange" :on-success="handleAvatarSuccess"
            :on-error="handleAvatarError">
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
            <el-image v-if="imageUrl" :src="imageUrl" class="avatar"></el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon bigImg"></i>
          </el-upload>
        </el-form-item> -->
        <el-form-item :label="$t('图片组')">
          <div class="upload">
            <el-upload :action="baseApi + 'api/advert/upload'" list-type="picture" :headers="headers" name="image"
              :data="{ type: 0 }" :limit="4" :accept="'.jpg, .png'" :file-list="cover_img_url"
              :on-remove="handleRemove1" :before-remove="beforeRemove1" :on-success="handleSuccess1">
              <el-button size="small" type="primary">{{$t('点击上传')}}</el-button>
              <div slot="tip" class="el-upload__tip">{{$t('只能上传jpg/png文件')}}</div>
            </el-upload>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickFalse()">{{$t('取 消')}}</el-button>
        <el-button type="primary" :disabled="form.disabled" :loading="form.disabled" @click="createSubmit('form')">{{$t('确 定')}}</el-button>
      </div>
    </el-dialog>



    <el-dialog :title="$t('编辑设备')" :visible.sync="devShow" @close="clickFalse">
      <el-form :model="devForm" ref="devForm" label-width="auto">
        <div class="head">
          <!-- <el-autocomplete v-model="searchDev" :fetch-suggestions="SearchDevNameAsync" value-key="name"
            :placeholder="$t('devList.searchDev')" @select="handleSearchDevName" :select-when-unmatched="true" clearable
            @clear="getDevList">
          </el-autocomplete>
          <el-autocomplete v-model="searchImei" :fetch-suggestions="SearchDevImeiAsync" value-key="value"
            :placeholder="$t('devList.searchId')" @select="handleSearchDevImei" :select-when-unmatched="true" clearable
            @clear="getDevList">
          </el-autocomplete> -->
          <div class="right">
            <el-button type="primary" @click="bind(true)">{{$t('绑定')}}</el-button>
            <el-button type="danger" @click="bind(false)">{{$t('解绑')}}</el-button>
          </div>
        </div>
        <div class="list">
          <el-table :data="devList" v-loading="agent.loading" row-class-name="table-row"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center">
            </el-table-column>
            <el-table-column prop="name" :label="$t('设备名称')">
            </el-table-column>
            <el-table-column prop="imei" :label="$t('IMEI')" show-overflow-tooltip>
            </el-table-column>
            <el-table-column :label="$t('绑定状态')">
              <template slot-scope="scope">
                <div v-if="scope.row.adverts && scope.row.adverts.length > 0" style="color: rgb(0, 233, 0);">{{$t('已绑定')}}</div>
                <div v-else style="color: red;">{{$t('未绑定')}}</div>
                <div v-if="scope.row.adverts && scope.row.adverts.length > 0">{{ scope.row.adverts[0].name }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickFalse">{{$t('取 消')}}</el-button>
        <el-button type="primary" :disabled="devForm.disabled" :loading="devForm.disabled"
          @click="createSubmit('devForm')">{{$t('确 定')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { deepClone } from '@/utils/index'
import _ from 'lodash'
export default {
  name: "agent",
  data() {
    return {
      cover_img_url: [],
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      searchName: '',
      imageUrl: '',
      index_id: null,
      goods: [],
      devShow: false,
      devList: [],
      select: [],
      form: {
        id: '',
        action: 'create',
        name: '',
        sort: 0,
        cover_img: []
      },
      show: false,
      devForm: {},
      agent: {

        loading: false,

      },
      level: 1,
      levelArray: [
        {
          guid: "",
          title: this.$tt.t("我的")
        }
      ],
      device: {
        list: [],
        page: 1,
        pagesize: 20,
        total: 0,
        visible: false,
        loading: false,
        agent: null
      },
      detail: {
        data: null,
        visible: false
      },
      record: {
        visible: false,
        list: [],
        page: 1,
        pagesize: 20,
        total: 0,
        type: "30d",
        loading: false,
        guid: null
      },
      userIncome: {
        list: [],
        loading: false
      },
      search: {
        device: {
          list: [],
          page: 1,
          pagesize: 20,
          total: 0,
          loading: false,
          keyword: '',
          visible: false
        },
        agent: {
          list: [],
          loading: false,
          keyword: '',
          visible: false,
          remove: false,
          page: 1,
          page_size: 20,
          total: 0,
        }
      },
      listQuery: {
        device: '',
        keyword: ''
      },
      loading: false,
      list: [],
      goods: [],
      lane: {
        visbile: false,
        loading: false,
        list: [],
      },
      baseUrl:process.env.VUE_APP_BASE_URL
    };
  },
  created() {
    this.getList();
    // this.getDevList();
  },
  methods: {


    handleRemove1(file, fileList) {

      console.log("🚀 ~ handleRemove1 ~ file:", file)

      // console.log(file, fileList)

      this.form.cover_img = this.form.cover_img.filter(item => item !== file.name)

      console.log("🚀 ~ handleRemove1 ~ this.cover_img:", this.form.cover_img)

    },


    handleSuccess1(response, file, fileList) {
      // console.log(response, file, fileList)
      this.form.cover_img.push(response.data.name)
      // console.log(this.newform.cover_img)
    },






    agentChange(val) {
      this.agent.page = val;
      this.getOrderList();
    },
    getStaffList() {
      this.getList();
    },
    createAdvert() {
      this.form = {
        action: 'create',
        agent_id: this.userInfo.id,
        name: '',
        sort: 0,
        cover_img: []
      };
      this.cover_img_url = [];
      this.show = true;
      this.imageUrl = '';
    },
    handleSelectionChange(val) {
      // console.log("🚀 ~ handleSelectionChange ~ val:", val)
      // console.log(val)
      this.select = []
      val.forEach(item => {
        // console.log(item.id)
        this.select.push(item.id)
      })
    },
    async handleAvatarSuccess(res) {
      console.log(res)
      if (!this.form.id) {
        this.imageUrl = res.data.url
        this.form.cover_img[0] = res.data.name
        this.addAD()
      } else {
        console.log(123);
        await this.$post('/api/file/del', {
          name: this.form.cover_img[0],
          file_type: 'image',
          module: 'advert'
        })
        this.imageUrl = res.data.url
        this.form.cover_img[0] = res.data.name
        this.editAD()
      }
    },
    async addAD() {
      // console.log(this.form, "add")
      const res = await this.$post('/api/advert/addIndex', this.form)
      if (!res) { return this.$message.error(res.msg) }
      this.show = false
      this.getList()
    },
    handleChange(file) {
      if (this.$refs["uploader"].uploadFiles.length > 1) {
        this.$refs["uploader"].uploadFiles.shift()
      }
      this.imageUrl = file.url
    },
    handleAvatarError(err) {
      this.$message.error(err.msg)
    },
    createSubmit() {
      console.log('this.form-', this.form);
      // return;
      // console.log('this.$refs', this.$refs['form']);
      // return;
      if (!this.form.name) { return this.$message.error(this.$tt.t('请输入广告名称')) }
      // if (!this.imageUrl) { return this.$message.error('请广告图片') }
      // console.log(this.$refs['uploader'].uploadFiles)
      // if (this.$refs['uploader'].uploadFiles.length > 0) {
      //   this.$refs["uploader"].submit()
      //   return
      // }
      if (this.form.id) {
        this.editAD()
      } else {
        this.addAD();
      }


    },
    async editAD() {
      // console.log(this.form,"edit")
      const res = await this.$post('/api/advert/updateIndex', this.form)
      if (!res) { return this.$message.error(res.msg) }
      this.show = false
      this.getList()
    },

    async editClick(item) {
      this.form = item;
      this.index_id = item.id
      this.devShow = true;
      this.getDevList()
      // console.log('this.form-', this.form);
    },

    async bind(type) {
      // console.log(type);
      // console.log(item);
      console.log(this.select.toString())
      const res = await this.$post('/api/advert/belongDevice', {
        device_ids: this.select.toString(),
        advert_id: this.index_id,
        operate: type ? 'bind' : 'unbind'
      })
      if (!res) { return this.$message.error(res.msg) }
      this.devShow = false;
      this.getDevList()
    },
    async getDevList(name, imei) {
      // if (!name && !imei) { this.devLoading = true }
      const res = await this.$get('/api/device/list', {

      })
      // this.devLoading = false
      console.log(res)
      // if (!res) { return this.$message.error(res.msg) }
      // if (name || imei) {
      //   return res.data.data
      // } else {
      //   this.select = []
      this.devList = res.data
      //   this.devTotal = res.data.total
      // }
    },


    openEdit(row) {
      // console.log(row)
      this.cover_img_url = [];
      this.form.action = 'update';
      this.show = true;
      let { id, name, file_type, type, cover_img_url, video_name, video_img, sort } = row
      this.form = { id, name, file_type, type, cover_img: [], video_name, video_img, sort }
      this.cover_img_url = cover_img_url
      // console.log(cover_img_url);
      this.cover_img_url.forEach(item => {
        this.form.cover_img.push(item.name)
      });
      this.imageUrl = row.video_img_url
      // this.imageUrl = cover_img_url[0].url
    },





    clickFalse() {
      // console.log("click", this.show)
      this.show = false;
      this.devShow = false;
    },

    addZone() {
      this.show = true;
    },

    wifiPageChange(val) {
      this.listQuery.page = val;
      this.getWifiList();
    },

    getList() {
      this.$get('/api/advert/list', {
        page: this.agent.page,
        page_size: this.agent.page_size,
        type: 2,
        name: this.searchName,
      }).then(res => {
        // console.log(res);
        this.loading = false;
        this.list = res.data;
      }).catch(res => {
        this.loading = false;
      })
    },



    deleteClick(row) {
      this.$confirm(this.$tt.t('是否删除此广告'), this.$tt.t('提示'), {
        confirmButtonText: this.$tt.t('确定'),
        cancelButtonText: this.$tt.t("取消"),
        type: "warning"
      }).then(() => {
        this.$post('/api/advert/del', {
          ids: row.id
        }).then(res => {
          this.$message({
            message: this.$tt.t("删除成功"),
            type: "success",
            center: true
          });
          this.getList()
        }).catch(() => {

        })
      }).catch(() => {

      });
    },


  }
};
</script>

<style lang="scss" scoped>
.bigImg {
  width: 200px;
  height: 200px;
}

.avatar-uploader .el-upload .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px solid #d9d9d9;
}

.btns-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
}

.breadcrumb-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #eee;
  padding: 10px;

  .breadcrumb-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    .name {
      font-size: 14px;
      cursor: pointer;
    }

    .name:hover {
      text-decoration: underline;
    }

    .el-icon-arrow-right {
      font-size: 14px;
      color: #9d9d9d;
      margin-left: 5px;
    }
  }

  .breadcrumb-item:not(:first-child) {
    margin-left: 5px;
  }

  .breadcrumb-item:first-child .name {
    font-weight: 500;
  }
}

.table-row:hover {
  .el-icon-edit-outline {
    display: block;
  }
}

.row-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .username {
    margin-left: 10px;
  }

  .el-icon-edit-outline {
    margin-left: 10px;
    display: none;
    color: #9d9d9d;
    cursor: pointer;
  }
}

.level {
  padding: 5px 10px;
  color: #fff;
}

.qrcode {
  width: 150px;
  cursor: pointer;
}

.qrcode:active {
  opacity: 0.7;
}

.fa-qrcode:hover {
  color: #ccc;
}

.fa-bluetooth {
  margin-left: 10px;
  color: #1870d2;
}

.fa-bluetooth:hover {
  opacity: 0.7;
}

.group-text {
  padding: 5px 10px;
  color: #fff;
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: 0.5em;
}

.circle:hover {
  opacity: 0.7;
}

.el-table {
  .online {
    background-color: #67c23a;
  }

  .offline {
    background-color: #f56c6c;
  }
}

.detail-view {
  width: 100%;
  display: flex;
  flex-direction: column;

  .row-view {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    padding: 10px 0;

    .title {
      width: 120px;
      text-align: right;
    }

    .content {
      width: calc(100% - 150px);
      margin-left: 30px;
      color: #888;
      display: flex;
      flex-direction: row;
      align-items: center;

      .goods-img {
        width: 50px;
        height: 50px;
      }

      .goods-name {
        margin-left: 20px;
      }

      .goods-price {
        color: #f56c6c;
        margin-left: 20px;
      }
    }

    .online {
      color: #67c23a;
    }

    .offline {
      color: #f56c6c;
    }
  }
}

.top-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    font-size: 14px;
  }
}

.online-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .online-point {
    width: 10px;
    height: 10px;
  }

  .online-title {
    font-size: 14px;
    margin-left: 10px;
  }

  .online {
    background-color: #67c23a;
  }

  .offline {
    background-color: #ccc;
  }
}

.status-online {
  color: #67c23a;
}

.status-offline {
  color: #ccc;
}
</style>