<template>
    <div>
        <el-table class="ptd-5" id="list_table" ref="list_table" v-loading="listLoading" :data="agents"
            :max-height="tableMaxH" element-loading-text="Loading" stripe highlight-current-row>
            <el-table-column label="#" type="index"></el-table-column>

            <el-table-column prop="device_name" :label="$t('设备名称')"></el-table-column>
            <el-table-column prop="name" :label="$t('手机号码')"></el-table-column>
            <el-table-column prop="opinion" :label="$t('反馈内容')"></el-table-column>
            <el-table-column prop="create_time" :label="$t('创建时间')"></el-table-column>

        </el-table>
        <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="page"
            :page-size="pagesize" :total="total" @current-change="agentPageChange"></el-pagination>
    </div>
</template>

<script>
export default {
    data() {
        return {
            agent: '',
            agentOptions: [],
            agents: [],
            page: 1,
            pagesize: 20,
            total: 0,
        };
    },
    created() {
        this.reflectList();
        // this.agent.loading = true;
    },

    methods: {
        reflectList() {
            this.$get('/api/feedback/list', {
                page: this.page,
                page_size: this.page_size,
            }).then(res => {
                this.listLoading = false;
                this.agents = res.data;
                this.total = res.total;
            }).catch(err => {

            });
        },
    }
};
</script>

<style lang="scss" scoped>
.btns-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
}

.breadcrumb-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #eee;
    padding: 10px;

    .breadcrumb-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        .name {
            font-size: 14px;
            cursor: pointer;
        }

        .name:hover {
            text-decoration: underline;
        }

        .el-icon-arrow-right {
            font-size: 14px;
            color: #9d9d9d;
            margin-left: 5px;
        }
    }

    .breadcrumb-item:not(:first-child) {
        margin-left: 5px;
    }

    .breadcrumb-item:first-child .name {
        font-weight: 500;
    }
}

.table-row:hover {
    .el-icon-edit-outline {
        display: block;
    }
}

.row-view {
    display: flex;
    flex-direction: row;
    align-items: center;

    .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    .username {
        margin-left: 10px;
    }

    .el-icon-edit-outline {
        margin-left: 10px;
        display: none;
        color: #9d9d9d;
        cursor: pointer;
    }
}

.level {
    padding: 5px 10px;
    color: #fff;
}

.qrcode {
    width: 150px;
    cursor: pointer;
}

.qrcode:active {
    opacity: 0.7;
}

.fa-qrcode:hover {
    color: #ccc;
}

.fa-bluetooth {
    margin-left: 10px;
    color: #1870d2;
}

.fa-bluetooth:hover {
    opacity: 0.7;
}

.group-text {
    padding: 5px 10px;
    color: #fff;
}

.circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-left: 0.5em;
}

.circle:hover {
    opacity: 0.7;
}

.el-table {
    .online {
        background-color: #67c23a;
    }

    .offline {
        background-color: #f56c6c;
    }
}

.detail-view {
    width: 100%;
    display: flex;
    flex-direction: column;

    .row-view {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        padding: 10px 0;

        .title {
            width: 120px;
            text-align: right;
        }

        .content {
            width: calc(100% - 150px);
            margin-left: 30px;
            color: #888;
            display: flex;
            flex-direction: row;
            align-items: center;

            .goods-img {
                width: 50px;
                height: 50px;
            }

            .goods-name {
                margin-left: 20px;
            }

            .goods-price {
                color: #f56c6c;
                margin-left: 20px;
            }
        }

        .online {
            color: #67c23a;
        }

        .offline {
            color: #f56c6c;
        }
    }
}

.top-view {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
        font-size: 14px;
    }
}

.online-view {
    display: flex;
    flex-direction: row;
    align-items: center;

    .online-point {
        width: 10px;
        height: 10px;
    }

    .online-title {
        font-size: 14px;
        margin-left: 10px;
    }

    .online {
        background-color: #67c23a;
    }

    .offline {
        background-color: #ccc;
    }
}

.status-online {
    color: #67c23a;
}

.status-offline {
    color: #ccc;
}
</style>