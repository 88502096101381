<template>
  <div class="content-view" v-title :data-title="$t('下级代理')" style="background:#fff;">
    <div class="top-view" style="padding:10px;">
      <!-- <el-button type="success" @click="searchDevice">搜索设备</el-button>
      <el-button type="warning" @click="searchAgent">搜索下级</el-button> -->
      <el-button type="success" @click="createClick">{{$t('添加优惠券')}}</el-button>
    </div>
    <!-- {{levelArray}} -->
    <div class="breadcrumb-view" v-if="levelArray.length > 1">
      <i class="el-icon-user-solid"></i>

      <div class="breadcrumb-item" v-for="(item, index) in levelArray" :key="index">
        <span class="name" @click="breadcrumbClick(item, index)">{{ item.title }}</span>
        <i class="el-icon-arrow-right" v-if="index != levelArray.length - 1"></i>
      </div>
    </div>

    <el-table :data="list" v-loading="agent.loading" row-class-name="table-row">
      <el-table-column type="index" label="#"></el-table-column>
      <el-table-column :label="$t('优惠券名称')" prop="name">
      </el-table-column>
      <el-table-column prop="coupons" :label="$t('额度')">
        <template slot-scope="scope">
          <span>￥</span>{{ scope.row.coupons }}
        </template>
      </el-table-column>
      <el-table-column prop="coupon_type" :label="$t('优惠券类型')">
        <template slot-scope="scope">
          <span v-if="scope.row.coupon_type == 1">{{$t('金额券')}}</span>
          <!-- <span v-else-if="scope.row.coupon_type == 2">折扣券</span> -->

        </template>
      </el-table-column>
      <el-table-column prop="probability" :label="$t('概率')+'（%）'">
      </el-table-column>
      <!-- <el-table-column prop="coupon_code" label="领取代码">
      </el-table-column> -->
      <!-- <el-table-column prop="redemption_code" label="核销码">
      </el-table-column> -->
      <!-- <el-table-column prop="probability" label="参与抽奖(百分比)" width="250">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.probability" :min="0" :step="1" :max="100">
          </el-input-number>
          <el-tooltip v-if="scope.row.raffle === 0" content="启用抽奖" placement="top">
            <el-button type="success" icon="el-icon-check"
              @click="editProbability(scope.row.id, scope.row.probability, scope.row.raffle)" circle></el-button>
          </el-tooltip>
          <el-tooltip v-else-if="scope.row.raffle === 1" content="禁用抽奖" placement="top">
            <el-button type="danger" icon="el-icon-close"
              @click="editProbability(scope.row.id, scope.row.probability, scope.row.raffle)" circle></el-button>
          </el-tooltip>
        </template>
      </el-table-column> -->

      <el-table-column :label="$t('操作')" width="400" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="editClick(scope.row)" size="mini">{{$t('编辑')}}</el-button>
          <el-button type="danger" @click="deleteClick(scope.row)" size="mini">{{$t('删除')}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="agent.page"
      :page-size="agent.pagesize" :total="agent.total" @current-change="agentChange"></el-pagination>



    <el-dialog :title="form.action == 'create' ? $t('添加优惠券') : $t('编辑优惠券')" :visible.sync="devShow">
      <el-form :model="form" ref="form" label-width="auto">
        <h4>{{$t('基础信息')}}</h4>
        <el-form-item :label="$t('优惠券名称')" prop="name" :rules="[{ required: true, message: $t('请输入折扣券名称'), trigger: 'blur' },
        { min: 3, message: $t('长度至少3个字符'), trigger: 'blur' }]">
          <el-input v-model="form.name" style="width:350px" :placeholder="$t('请输入优惠券名称')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('优惠券额度')" prop="coupons" :rules="[{ required: true, message: $t('请输入折扣券额度'), trigger: 'blur' },
        { pattern: /^[0-9]+$/, message: $t('请输入数字'), trigger: 'blur' }]">
          <!-- <el-input v-model="form.coupons" style="width:350px" placeholder="请输入优惠券额度"></el-input> -->
          <el-input-number v-model="form.coupons" controls-position="right" :min="0"
            @change="handleChange"></el-input-number>&nbsp;&nbsp;{{$t('元')}}
        </el-form-item>
        <el-form-item :label="$t('折扣券类型')" prop="coupon_type" style="text-align: left;"
          :rules="[{ required: true, message: $t('请选择折扣券类型'), trigger: 'blur' },]">
          <el-radio-group v-model="form.coupon_type">
            <el-radio label="1">{{$t('金额券')}}</el-radio>
            <!-- <el-radio label="2">折扣券</el-radio> -->
            <!-- <el-radio label="3">新用户金额券</el-radio> -->
            <!-- <el-radio label="4">新用户折扣券</el-radio> -->
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('抽奖概率')" prop="probability" :rules="[{ required: true, message: $t('请输入折扣券额度'), trigger: 'blur' },
        { pattern: /^[0-9]+$/, message: $t('请输入数字'), trigger: 'blur' }]">
          <el-input-number v-model="form.probability" controls-position="right" :min="0"
            :max="100"></el-input-number>&nbsp;&nbsp;%
        </el-form-item>
        <!-- {{form}} -->

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickFalse()">取 消</el-button>
        <el-button type="primary" :disabled="form.disabled" :loading="form.disabled" @click="createSubmit('form')">{{$t('确 定')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { deepClone } from '@/utils/index'
import _ from 'lodash'
export default {
  name: "agent",
  data() {
    return {
      devShow: false,
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      goods: [],
      form: {
        action: 'create',
        name: '',
        coupons: '',
        coupon_type: '',
        probability: '',

      },
      agent: {
        list: [
          {
            avatar: '',
            name: '',
            level: {
              clr: 1
            }
          }
        ],
        page: 1,
        pagesize: 20,
        total: 0,
        loading: false,
        guid: null,
        remove: false
      },
      level: 1,
      levelArray: [
        {
          guid: "",
          title: $t("我的")
        }
      ],
      device: {
        list: [],
        page: 1,
        pagesize: 20,
        total: 0,
        visible: false,
        loading: false,
        agent: null
      },
      detail: {
        data: null,
        visible: false
      },
      record: {
        visible: false,
        list: [],
        page: 1,
        pagesize: 20,
        total: 0,
        type: "30d",
        loading: false,
        guid: null
      },
      userIncome: {
        list: [],
        loading: false
      },
      search: {
        device: {
          list: [],
          page: 1,
          pagesize: 20,
          total: 0,
          loading: false,
          keyword: '',
          visible: false
        },
        agent: {
          list: [],
          loading: false,
          keyword: '',
          visible: false,
          remove: false
        }
      },
      listQuery: {
        device: '',
        keyword: ''
      },
      loading: false,
      list: [],
      goods: [],
      lane: {
        visbile: false,
        loading: false,
        list: [],
      },
      wifi: {
        visbile: false,
        loading: false,
        page: 1,
        page_size: 20,
        zone_id: 0,
        listQuery: {
          page: 1,
          page_size: 20,
          total: 0
        },
        list: [],
        form: {
          visible: false,
          data: {
            name: '',
            password: ''
          },
        },
        baseData: {
          name: '',
          password: ''
        }
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    editProbability(id, probability, raffle) {
      // console.log(id);
      // console.log(probability);
      // console.log(raffle);

      // raffle = raffle === 1 ? 0 : 1;
      // console.log(raffle);
      this.agent.loading = true;
      this.$post('/api/Marketing/editProbability', {
        id: id,
        probability: probability,
        // raffle: raffle,
      }).then(res => {
        // console.log(res);
        this.agent.loading = false;
        this.list = res.data;
        this.getList();
      }).catch(res => {
        this.agent.loading = false;
        this.getList();
      })

    },
    clickFalse() {
      this.devShow = false;
    },

    handleChange() {

    },
    wifiPageChange(val) {
      this.listQuery.page = val;
      this.getWifiList();
    },

    getList() {
      this.$get('/api/Marketing/list', {
        agent_id: this.userInfo.id,
        raffle: 1,
      }).then(res => {
        // console.log(res);
        this.loading = false;
        this.list = res;
      }).catch(res => {
        this.loading = false;
      })
    },

    createClick() {
      this.form = {
        action: 'create',
        name: '',
        coupons: '',
        coupon_type: '',
        probability: '',
      };
      this.devShow = true;
    },
    async editClick(item) {
      this.form = item;
      this.form.action = 'update';
      this.devShow = true;
      this.form.coupon_type = `${item.coupon_type}`;
      console.log('this.form-', this.form);
    },

    deleteClick(row) {
      this.$confirm(this.$tt.t('是否删除该优惠券'), this.$tt.t('提示'), {
        confirmButtonText: this.$tt.t("确定"),
        cancelButtonText: this.$tt.t("取消"),
        type: "warning"
      }).then(() => {
        this.$post('/api/Marketing/delCoupon', {
          ids: row.id
        }).then(res => {
          this.$message({
            message: this.$tt.t("删除成功"),
            type: "success",
            center: true
          });
          location.reload();
        })
      });
    },
    createSubmit() {
      // console.log('this.form-',this.form);
      // return;
      // console.log('this.$refs', this.$refs['form']);
      // return;
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let form = _.cloneDeep(this.form);
          let param = {
            agent_id: this.userInfo.id,
            name: form.name,
            coupons: form.coupons,
            coupon_type: form.coupon_type,
            probability: form.probability,
            raffle: 1
          };
          // console.log(param);
          this.form.disabled = true;
          // console.log('param', param);
          // console.log('this.form-',this.form);
          // return;
          if (this.form.action == 'create') {
            this.$post('/api/Marketing/addCoupon', param).then(res => {
              console.log('res', res);
              this.form.disabled = false;
              this.form.visible = false;
              this.$message(
                {
                  message: $t("添加成功"),
                  type: "success",
                  center: true
                });
              location.reload();
            }).catch(res => {
              this.form.disabled = false;
            });

          } else {
            param.id = this.form.id;
            this.$post('/api/Marketing/updateCoupon', { ...param }).then(res => {
              console.log('res', res);
              this.form.disabled = false;
              this.form.visible = false;
              this.$message(
                {
                  message: $t("编辑成功"),
                  type: "success",
                  center: true
                });
              location.reload();
            }).catch(res => {
              console.log('');
              this.form.disabled = false;
            });
          }
          this.getList();
        }

      })


    },


  }
};
</script>

<style lang="scss" scoped>
.el-input-number {
  width: 140px;
}

.btns-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
}

.breadcrumb-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #eee;
  padding: 10px;

  .breadcrumb-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    .name {
      font-size: 14px;
      cursor: pointer;
    }

    .name:hover {
      text-decoration: underline;
    }

    .el-icon-arrow-right {
      font-size: 14px;
      color: #9d9d9d;
      margin-left: 5px;
    }
  }

  .breadcrumb-item:not(:first-child) {
    margin-left: 5px;
  }

  .breadcrumb-item:first-child .name {
    font-weight: 500;
  }
}

.table-row:hover {
  .el-icon-edit-outline {
    display: block;
  }
}

.row-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .username {
    margin-left: 10px;
  }

  .el-icon-edit-outline {
    margin-left: 10px;
    display: none;
    color: #9d9d9d;
    cursor: pointer;
  }
}

.level {
  padding: 5px 10px;
  color: #fff;
}

.qrcode {
  width: 150px;
  cursor: pointer;
}

.qrcode:active {
  opacity: 0.7;
}

.fa-qrcode:hover {
  color: #ccc;
}

.fa-bluetooth {
  margin-left: 10px;
  color: #1870d2;
}

.fa-bluetooth:hover {
  opacity: 0.7;
}

.group-text {
  padding: 5px 10px;
  color: #fff;
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: 0.5em;
}

.circle:hover {
  opacity: 0.7;
}

.el-table {
  .online {
    background-color: #67c23a;
  }

  .offline {
    background-color: #f56c6c;
  }
}

.detail-view {
  width: 100%;
  display: flex;
  flex-direction: column;

  .row-view {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    padding: 10px 0;

    .title {
      width: 120px;
      text-align: right;
    }

    .content {
      width: calc(100% - 150px);
      margin-left: 30px;
      color: #888;
      display: flex;
      flex-direction: row;
      align-items: center;

      .goods-img {
        width: 50px;
        height: 50px;
      }

      .goods-name {
        margin-left: 20px;
      }

      .goods-price {
        color: #f56c6c;
        margin-left: 20px;
      }
    }

    .online {
      color: #67c23a;
    }

    .offline {
      color: #f56c6c;
    }
  }
}

.top-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    font-size: 14px;
  }
}

.online-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .online-point {
    width: 10px;
    height: 10px;
  }

  .online-title {
    font-size: 14px;
    margin-left: 10px;
  }

  .online {
    background-color: #67c23a;
  }

  .offline {
    background-color: #ccc;
  }
}

.status-online {
  color: #67c23a;
}

.status-offline {
  color: #ccc;
}
</style>