<template>
  <div class="content-view" v-title :data-title="$t('Subordinate_agents')" style="background:#fff;">
    <div class="top-view" style="padding:10px;">
      <!-- <el-button type="success" @click="searchDevice">搜索设备</el-button>
      <el-button type="warning" @click="searchAgent">搜索下级</el-button> -->
      <el-button type="success" @click="addAgentClick">{{$t('AddAgent')}}</el-button>
      <!-- <span class="title" style="margin-left:20px;">设备：</span>
      <el-input style="width:300px" v-model="agent.device" placeholder="请输入关键字"></el-input> -->
      <span class="title" style="margin-left:20px;">{{$t('Name')}}：</span>

      <el-input style="width:300px" v-model="agent.keyword" :placeholder="$t('PleaseEnterKeywords')" clearable @clear="getAgentList"
        @keyup.enter.native="getAgentList">
        <el-button slot="append" icon="el-icon-search" @click="getAgentList"></el-button>
      </el-input>
    </div>
    <!-- {{levelArray}} -->
    <div class="breadcrumb-view" v-if="levelArray.length > 1">
      <i class="el-icon-user-solid"></i>

      <div class="breadcrumb-item" v-for="(item, index) in levelArray" :key="index">
        <span class="name" @click="breadcrumbClick(item, index)">{{ item.title }}</span>
        <i class="el-icon-arrow-right" v-if="index != levelArray.length - 1"></i>
      </div>
    </div>
    <el-table :data="agent.list" v-loading="agent.loading" row-class-name="table-row">
      <el-table-column type="index"></el-table-column>
      <el-table-column :label="$t('Agent')" min-width="150">
        <template slot-scope="scope">
          <div class="row-view">
            <!-- <div>
              <el-image class="avatar" :src="scope.row.avatar" fit="fill" lazy></el-image>
            </div> -->
            <span class="username">{{ scope.row.name }}</span>
            <!-- <i class="el-icon-edit-outline" @click="nicknameEditClick(scope.row)"></i> -->
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('Level')" min-width="100">
        <template slot-scope="scope">
          <span class="level"
            :style="{ 'background-color': scope.row.level == 1 ? '#673ab7' : scope.row.level == 2 ? '#ccffcc' : '#ffc125' }">{{
        level }}{{$t('Level')}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="device_count" :label="$t('NumberOfDevices')" min-width="100"></el-table-column>
      <el-table-column :label="$t('抽成')" min-width="100">
        <template slot-scope="scope">
          <div style="display:flex;flex-direction:column;">
            <div>{{$t('售卖')}}：{{ getCommission(0, scope.row) }}</div>
            <!-- <div>充电：{{getCommission(1,scope.row)}}</div> -->
            <!-- <div>虚拟货道：{{getCommission(2,scope.row)}}</div> -->
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" :label="$t('电话')" min-width="100"></el-table-column>
      <!-- <el-table-column prop="address" label="地址" min-width="150"></el-table-column> -->
      <el-table-column :label="$t('操作')" min-width="200">
        <template slot-scope="scope">
          <el-dropdown split-button type="primary" size="small" @click="statsClick(scope.row)">
            {{$t('出货统计')}}
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="deviceClick(scope.row)">{{$t('TA的设备')}}</el-dropdown-item>
              <el-dropdown-item :disabled="scope.row.sub_count == 0"
                @click.native="subAgentClick(scope.row)">{{$t('TA的下级')}}</el-dropdown-item>
              <el-dropdown-item @click.native="percentClick(scope.row)">{{$t('TA的抽成')}}</el-dropdown-item>
              <el-dropdown-item @click.native="balanceClick(scope.row)">{{$t('佣金明细')}}</el-dropdown-item>
              <el-dropdown-item @click.native="removeAgentClick(scope.row)" v-if="agent.remove">{{$t('取消身份')}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="agent.page"
      :page-size="agent.pagesize" :total="agent.total" @current-change="agentPageChange"></el-pagination>

    <el-dialog :title="$t('TA的设备')" :visible.sync="device.visible" width="1200px">
      <el-table :data="device.list" v-loading="device.loading">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column width="80">
          <template slot-scope="scope">
            <el-popover placement="right" trigger="hover">
              <img class="qrcode" :src="scope.row.qrcode" @click="copyQrcodeUrl(scope.row.qrcode)" />
              <i slot="reference" class="fa fa-qrcode"></i>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column :label="$t('设备名称')" min-width="130">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
            <el-tooltip effect="dark" :content="$t('蓝牙')" placement="right">
              <i class="fa fa-bluetooth"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="imei" :label="$t('设备ID')" min-width="130"></el-table-column>
        <el-table-column :label="$t('状态')" min-width="80">
          <template slot-scope="scope">
            <div v-if="scope.row.status.app !== undefined"
              :class="['online-view', scope.row.status.app === true ? 'status-online' : '', scope.row.status.app === false ? 'status-offline' : '']">
              <div
                :class="['online-point', scope.row.status.app === true ? 'online' : '', scope.row.status.app === false ? 'offline' : '']">
              </div>
              <span class="online-title">{{$t('屏幕')}}</span>
            </div>
            <div v-if="scope.row.status.mcb !== undefined"
              :class="['online-view', scope.row.status.mcb === true ? 'status-online' : '', scope.row.status.mcb === false ? 'status-offline' : '']">
              <div
                :class="['online-point', scope.row.status.mcb === true ? 'online' : '', scope.row.status.mcb === false ? 'offline' : '']">
              </div>
              <span class="online-title">{{$t('出货')}}</span>
            </div>
            <div v-if="scope.row.status == 1" class="online-view">{{$t('启用')}}</div>
            <div v-else class="status-online">{{$t('禁用')}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" :label="$t('创建时间')" min-width="180"></el-table-column>
        <el-table-column :label="$t('操作')" min-width="150">
          <template slot-scope="scope">
            <el-button type="success" size="mini" @click="deviceDetailClick(scope.row)">{{$t('详情')}}</el-button>
            <el-button type="danger" size="mini" @click="unbindDetailClick(scope.row)">{{$t('解绑')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="device.page"
        :page-size="device.pagesize" :total="device.total" @current-change="devicePageChange"></el-pagination>
    </el-dialog>

    <el-dialog :title="$t('详情')" width="500px" :visible.sync="detail.visible">
      <div class="detail-view" v-if="detail.data">
        <div class="row-view">
          <span class="title">{{$t('设备IMEI')}}</span>
          <span class="content">{{ detail.data.imei }}</span>
        </div>
        <div class="row-view">
          <span class="title">{{$t('设备名称')}}</span>
          <span class="content">{{ detail.data.name }}</span>
        </div>
        <div class="row-view" v-if="detail.data.device_type_id_txt">
          <span class="title">{{$t('设备型号')}}</span>
          <span class="content">{{ detail.data.device_type_id_txt }}</span>
        </div>
        <div class="row-view">
          <span class="title">{{$t('设备状态')}}</span>
          <span class="content" style="color:#67c23a" v-if="detail.data.status == 1">{{$t('正常')}}</span>
          <span class="content" style="color:#ccc" v-else>{{$t('禁用')}}</span>
        </div>
        <div class="row-view" v-if="detail.data.status.mcb !== undefined">
          <span class="title">{{$t('出货状态')}}</span>
          <span :class="{ 'content': true, 'online': detail.data.status.mcb, 'offline': !detail.data.status.mcb }">{{
        detail.data.status.mcb
          ? $t('在线') : $t('离线') }}</span>
        </div>
        <div class="row-view" v-if="detail.data.status && detail.data.status.count">
          <span class="title">{{$t('累计出货')}}</span>
          <span class="content">{{ detail.data.status.count }}</span>
        </div>
        <div class="row-view" v-if="detail.data.status && detail.data.status.error">
          <span class="title">{{$t('错误代码')}}</span>
          <span class="content" style="color: red;">{{ detail.data.status.error }}</span>
        </div>
        <div class="row-view" v-if="detail.data.update_time">
          <span class="title">{{$t('最近在线')}}</span>
          <span class="content">{{ detail.data.update_time }}</span>
        </div>
        <div class="row-view" v-if="detail.data.lanes" style="width:1000px;margin-left:100px;">
          <div style="display:flex;flex-direction:column;">
            <div v-for="(item, index) in detail.data.lanes" :key="index"
              style="display:flex;align-items:center;margin-top:5px;">
              <div>{{$t('货道')}}{{ item.lane }}</div>
              <el-image class="m-l-10" src="" style="width:50px;height:50px;"></el-image>
              <div class="m-l-10" style="width:80px">{{ item.goods.name }}</div>
              <div class="m-l-10" style="width:50px">{{$t('库存')}}{{ item.num }}</div>
              <div class="m-l-10" style="width:50px">{{$t('容量')}}{{ item.max_num }}</div>
            </div>
          </div>

        </div>
      </div>
    </el-dialog>



    <el-dialog :title="$t('出货统计')" :visible.sync="stats.visible" width="700px" @closed="statsClosed">
      <div class="top-view">
        <span class="title">>{{$t('月份')}}：</span>
        <el-date-picker v-model="stats.date" type="month" :placeholder="$t('请选择月份')" value-format="yyyy-MM" :clearable="false"
          :editable="false" @change="dateChange"></el-date-picker>
        <span class="title" style="margin-left:20px;">{{$t('设备')}}：</span>
        <el-select v-model="stats.deviceid" :placeholder="$t('请选择设备')" filterable @change="deviceChange">
          <el-option v-for="item in stats.devices" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <!-- <div id="main" style="width: 100%;height:400px;margin-top:20px;"></div> -->
      <el-table :data="stats.days" show-summary :summary-method="getSummaries"
        :header-cell-style="{ 'background-color': '#eef1f6' }" style="margin-top:20px;" v-loading="stats.loading">
        <el-table-column type="index" width="100"></el-table-column>
        <el-table-column property="device_name" :label="$t('设备')" />
        <el-table-column property="goods_title" :label="$t('商品')" />
        <el-table-column property="amount">
          <template slot-scope="scope">
            ￥{{ scope.row.amount }}
          </template>
        </el-table-column>
        <el-table-column property="create_time" :label="$t('日期')" />

        <!-- <el-table-column property="date" label="日期">
           <template slot-scope="scope">
            {{parseTime(scope.row.create_time)}}
            </template>
        </el-table-column>
        <el-table-column property="price" label="金额"></el-table-column>
        <el-table-column property="fee" label="资金状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1" style="color:#67C23A">到账</span>
            <span v-else style="color:#909399">冻结</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="全部">
          <template slot-scope="scope">
            <span>{{scope.row.free + scope.row.fee}}</span>
          </template>
        </el-table-column> -->
      </el-table>
    </el-dialog>
    <el-dialog :title="$t('佣金明细')" :visible.sync="record.visible" width="1000px" @closed="recordClosed">
      <el-tabs type="border-card" v-model="record.type" style="margin-top:20px;" @tab-click="tabsClick">
        <!-- <el-tab-pane label="30日收益" name="30d"></el-tab-pane> -->
        <!-- <el-tab-pane label="收入" name="incr"></el-tab-pane>
        <el-tab-pane label="支出" name="decr"></el-tab-pane> -->
        <!-- <el-table :data="userIncome.list" show-summary style="width: 100%" v-loading="userIncome.loading" v-if="record.type == '30d'">
          <el-table-column type="index" width="50"></el-table-column>
          <el-table-column prop="date" label="日期"></el-table-column>
          <el-table-column prop="income" label="收益（元）"></el-table-column>
        </el-table> -->
        <!-- <div v-else> -->
        <el-table :data="record.list" style="width: 100%" v-loading="record.loading">
          <el-table-column type="index" width="50"></el-table-column>
          <!-- <el-table-column prop="title" label="标题" min-width="150"></el-table-column> -->
          <el-table-column :label="$t('描述')" min-width="250">
            <template slot-scope="scope">
              {{ scope.row.order_id ? $t('订单') : '' }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('金额')" min-width="100">
            <template slot-scope="scope">
              <span :class="{ 'incr': scope.row.x_val > 0, 'decr': scope.row.x_val < 0 }">{{ scope.row.x_val }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('资金状态')">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1" style="color:#67C23A">{{$t('到账')}}</span>
              <span v-else style="color:#909399">{{$t('冻结')}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" :label="$t('创建时间')" min-width="200"></el-table-column>
        </el-table>
        <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="record.page"
          :page-size="record.pagesize" :total="record.total" @current-change="recordPageChange"></el-pagination>
        <!-- </div> -->
      </el-tabs>
    </el-dialog>
    <el-dialog :title="$t('搜索设备')" :visible.sync="search.device.visible" width="1200px" @closed="searchDeviceClosed">
      <el-input style="width:500px;" :placeholder="$t('请输入设备名称或IMEI号进行查询')" v-model="search.device.keyword" clearable
        @keyup.enter.native="searchDeviceClick">
        <el-button slot="append" icon="el-icon-search" @click="searchDeviceClick"></el-button>
      </el-input>
      <el-table :data="search.device.list" v-loading="search.device.loading" style="margin-top:20px;">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column width="80">
          <template slot-scope="scope">
            <el-popover placement="right" trigger="hover">
              <img class="qrcode" :src="scope.row.device.qrcode" @click="copyQrcodeUrl(scope.row.device.qrcode)" />
              <i slot="reference" class="fa fa-qrcode"></i>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column :label="$t('设备名称')" min-width="130">
          <template slot-scope="scope">
            <span>{{ scope.row.device.name }}</span>
            <el-tooltip effect="dark" :content="$t('蓝牙')" placement="right" v-if="scope.row.device.buid">
              <i class="fa fa-bluetooth"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="device.id" :label="$t('设备ID')" min-width="130"></el-table-column>
        <el-table-column :label="$t('所属区域')" min-width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.extra.location">{{ scope.row.extra.location.area[0] }}-{{
        scope.row.extra.location.area[1] }}-{{ scope.row.extra.location.area[2] }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('分组')" min-width="130">
          <template slot-scope="scope">
            <div v-if="scope.row.group">
              <span class="group-text" :style="{ 'background-color': scope.row.group.clr }">{{ scope.row.group.title
                }}</span>
            </div>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('状态')" min-width="80">
          <template slot-scope="scope">
            <el-popover placement="right" trigger="hover">
              <div style="margin-left:20px;">{{$t('出货状态')}}：{{ scope.row.status.online ? $t('在线') : $t('离线') }}</div>
              <div v-if="scope.row.app" style="margin-left:20px;margin-top:10px;">{{$t('屏幕状态')}}：{{ scope.row.app.online ? $t('在线') :
        $t('离线') }}</div>
              <div slot="reference"
                :class="{ 'circle': true, 'online': scope.row.status.online, 'offline': !scope.row.status.online }">
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="device.createtime" :label="$t('创建时间')" min-width="180"></el-table-column>
        <el-table-column :label="$t('操作')" min-width="150">
          <template slot-scope="scope">
            <el-button type="success" size="mini" @click="deviceDetailClick(scope.row)">$t('详情')</el-button>
            <el-button type="danger" size="mini" @click="unbindDetailClick(scope.row)">$t('解绑')</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="search.device.page"
        :page-size="search.device.pagesize" :total="search.device.total"
        @current-change="searchDevicePageChange"></el-pagination>
    </el-dialog>
    <el-dialog :title="$t('搜索下级')" :visible.sync="search.agent.visible" width="1000px" @closed="searchAgentClosed">
      <el-input style="width:500px;" :placeholder="$t('请输入手机号或微信昵称进行查询')" v-model="search.agent.keyword" clearable
        @keyup.enter.native="searchAgentClick">
        <el-button slot="append" icon="el-icon-search" @click="searchAgentClick"></el-button>
      </el-input>
      <el-table :data="search.agent.list" v-loading="search.agent.loading" style="margin-top:20px;">
        <el-table-column type="index"></el-table-column>
        <el-table-column :label="$t('代理商')" min-width="150">
          <template slot-scope="scope">
            <div class="row-view">
              <div>
                <el-image class="avatar" :src="scope.row.avatar" fit="fill" lazy></el-image>
              </div>
              <span class="username">{{ scope.row.name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="device_count" :label="$t('设备数量')" min-width="100"></el-table-column>
        <el-table-column prop="mobile" :label="$t('电话')" min-width="100"></el-table-column>
        <el-table-column prop="address" :label="$t('地址')" min-width="150"></el-table-column>
        <el-table-column label="操作" min-width="200">
          <template slot-scope="scope">
            <el-dropdown split-button type="primary" size="small" @click="statsClick(scope.row)">
              {{$t('出货统计')}}
              <el-dropdown-menu slot="dropdo<el-formwn">
                <el-dropdown-item @click.native="deviceClick(scope.row)">{{$t('TA的设备')}}</el-dropdown-item>
                <el-dropdown-item @click.native="balanceClick(scope.row)">{{$t('佣金明细')}}</el-dropdown-item>
                <el-dropdown-item @click.native="removeAgentClick(scope.row)"
                  v-if="search.agent.remove">{{$t('取消身份')}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog :title="$t('TA的抽成')" :visible.sync="commission.visible">
      <el-tabs v-model="commission.tab" type="card">
        <el-tab-pane :label="$t('售卖')" name="0"></el-tab-pane>
        <!-- <el-tab-pane label="充电" name="1"></el-tab-pane>
        <el-tab-pane label="虚拟货道" name="2"></el-tab-pane> -->
      </el-tabs>
      <el-form :model="commission" submit="">
        <div v-show="commission.tab == 0">
          <el-form-item>
            <template slot="label">
              {{$t('是否开启')}}{{ commission.array[commission.tab] }}{{$t('分润')}}
            </template>
            <el-switch v-model="commission.is_profit_check" active-color="#13ce66" @change="switchCommissionProfit(0)"
              inactive-color="#ff4949"></el-switch>
          </el-form-item>

          <el-form-item :label="$t('分润模式')">
            <!-- <el-radio v-model="commission.extra.profit.mode" label="0">固定金额</el-radio> -->
            <el-radio v-model="commission.extra.profit.mode" label="1">{{$t('按比例分成')}}</el-radio>
          </el-form-item>

          <el-form-item :label="$t('抽成')">
            <el-input-number type="number" style="width:200px;" :min="0" :max="100"
              v-model="commission.extra.profit.rel[0].val" controls-position="right" :placeholder="$t('请输入分润数值')">
              <template slot="append">
                {{ commission.extra.profit.mode == 0 ? $t('元') : '%' }}
              </template>
            </el-input-number>
          </el-form-item>


        </div>

        <div v-show="commission.tab == 1">

          <el-form-item>
            <template slot="label">
              {{$t('是否开启')}}{{ commission.array[commission.tab] }}{{$t('分润')}}
            </template>
            <el-switch v-model="commission.is_charge_checked" active-color="#13ce66" @change="switchCommissionProfit(1)"
              inactive-color="#ff4949"></el-switch>
          </el-form-item>

          <el-form-item :label="$t('分润模式')">
            <!-- <el-radio v-model="commission.extra.charge_profit.mode" label="0">固定金额</el-radio> -->
            <el-radio v-model="commission.extra.charge_profit.mode" label="1">{{$t('按比例分成')}}</el-radio>
          </el-form-item>

          <el-form-item :label="$t('抽成')">
            <el-input type="number" style="width:200px;" v-model="commission.extra.charge_profit.rel[0].val"
              controls-position="right" :placeholder="$t('请输入分润数值')">
              <template slot="append">
                {{ commission.extra.charge_profit.mode == 0 ? $t('元') : '%' }}
              </template>
            </el-input>

          </el-form-item>

        </div>

        <div v-show="commission.tab == 2">

          <el-form-item>
            <template slot="label">
              {{$t('是否开启')}}{{ commission.array[commission.tab] }}{{$t('分润')}}
            </template>
            <el-switch v-model="commission.is_market_check" active-color="#13ce66" @change="switchCommissionProfit(2)"
              inactive-color="#ff4949"></el-switch>
          </el-form-item>

          <el-form-item :label="$t('分润模式')">
            <!-- <el-radio v-model="commission.extra.market_profit.mode" label="0">固定金额</el-radio> -->
            <el-radio v-model="commission.extra.market_profit.mode" label="1">{{$t('按比例分成')}}</el-radio>
          </el-form-item>

          <el-form-item :label="$t('抽成')">
            <el-input type="number" style="width:200px;" v-model="commission.extra.market_profit.rel[0].val"
              controls-position="right" :placeholder="$t('请输入分润数值')">
              <template slot="append">
                {{ commission.extra.market_profit.mode == 0 ? $t('元') : '%' }}
              </template>
            </el-input>
          </el-form-item>

        </div>
      </el-form>
      <template slot="footer">
        <el-button type="" @click="closeDiaLog">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="saveCommission">{{$t('保存')}}</el-button>
      </template>

    </el-dialog>

    <el-dialog :title="form.action == 'create' ? $t('添加代理商') : $t('编辑代理商')" :visible.sync="form.visible">
      <h4>{{$t('基础信息')}}：</h4>
      <el-form :model="form.data" label-width="auto">

        <el-form-item :label="$t('姓名') + '：'" prop="name">
          <el-input v-model="form.data.name" style="width:300px" :placeholder="$t('输入下级代理姓名')" />
        </el-form-item>
        <el-form-item :label="$t('手机号码') + '：'" prop="mobile">
          <el-input v-model="form.data.mobile" style="width:300px" :placeholder="$t('输入手机号')" />
          <div class="fs-s2 text-gray">{{$t('此手机号码会作为登录账户')}}</div>
        </el-form-item>
        <el-form-item :label="$t('登录密码') + '：'" prop="password">
          <el-input v-model="form.data.password" style="width:300px" :placeholder="$t('会作为用户代理登录的密码')" />
        </el-form-item>
        <!-- {{form.data.extra}} -->
        <!-- <template v-if="id> -->
        <h4>{{$t('分润比例')}}</h4>
        <el-tabs v-model="form.tab" type="card">
          <el-tab-pane :label="$t('售卖')" name="0"></el-tab-pane>
          <!-- <el-tab-pane label="充电" name="1"></el-tab-pane>
          <el-tab-pane label="虚拟货道" name="2"></el-tab-pane> -->
        </el-tabs>

        <div v-show="form.tab == 0">
          <el-form-item>
            <template slot="label">
              {{$t('是否开启')}}{{ form.array[form.tab] }}{{$t('分润')}}
            </template>
            <el-switch v-model="form.data.is_profit_check" @change="switchProfit(0)" active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </el-form-item>

          <el-form-item :label="$t('分润模式')">
            <!-- <el-radio v-model="form.data.extra.profit.mode" label="0">固定金额</el-radio> -->
            <el-radio v-model="form.data.extra.profit.mode" label="1"> {{$t('按比例分成')}}</el-radio>
          </el-form-item>
          <el-form-item :label="$t('抽成')">
            <el-input type="number" style="width:200px;" v-model="form.data.extra.profit.rel[0].val"
              controls-position="right" :placeholder="$t('请输入分润数值')">
              <span slot="append">{{ form.data.extra.profit.mode == '0' ? $t('元') : '%' }}</span>
            </el-input>
          </el-form-item>
        </div>

        <div v-show="form.tab == 1">
          <el-form-item>
            <template slot="label">
              {{$t('是否开启')}}{{ form.array[form.tab] }}{{$t('分润')}}
            </template>
            <el-switch v-model="form.data.is_charge_checked" active-color="#13ce66" @change="switchProfit(1)"
              inactive-color="#ff4949">
            </el-switch>
          </el-form-item>
          <el-form-item :label="$t('分润模式')">
            <el-radio v-model="form.data.extra.charge_profit.mode" label="0">{{$t('固定金额')}}</el-radio>
            <el-radio v-model="form.data.extra.charge_profit.mode" label="1">{{$t('按比例分成')}}</el-radio>
          </el-form-item>
          <el-form-item :label="$t('抽成')">
            <el-input type="number" style="width:200px;" v-model="form.data.extra.charge_profit.rel[0].val"
              controls-position="right" :placeholder="$t('请输入抽成数值')">
              <span slot="append">{{ form.data.extra.charge_profit.mode == '0' ? $t('元') : '%' }}</span>
            </el-input>
          </el-form-item>
        </div>

        <div v-show="form.tab == 2">
          <el-form-item>
            <template slot="label">
              {{$t('是否开启')}}{{ form.array[form.tab] }}{{$t('分润')}}
            </template>
            <el-switch v-model="form.data.is_market_check" active-color="#13ce66" @change="switchProfit(2)"
              inactive-color="#ff4949">
            </el-switch>
          </el-form-item>

          <el-form-item :label="$t('分润模式')">
            <el-radio v-model="form.data.extra.market_profit.mode" label="0">{{$t('固定金额')}}</el-radio>
            <el-radio v-model="form.data.extra.market_profit.mode" label="1">{{$t('按比例分成')}}</el-radio>
          </el-form-item>
          <el-form-item :label="$t('抽成')">
            <el-input type="number" style="width:200px;" v-model="form.data.extra.market_profit.rel[0].val"
              controls-position="right" placeholder="">
              <span slot="append">{{ form.data.extra.market_profit.mode == '0' ? $t('元') : '%' }}</span>
            </el-input>
          </el-form-item>
        </div>

      </el-form>
      <div slot="footer">
        <el-button type="" @click="form.visible = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="onSubmit('form')" :disabled="form.clickSubmit">{{$t('提交')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "agent",
  data() {
    return {
      agent: {
        list: [
          {
            avatar: '',
            name: '',
            level: {
              clr: 1
            }
          }
        ],
        page: 1,
        pagesize: 20,
        total: 0,
        loading: false,
        guid: null,
        remove: false
      },
      level: 1,
      levelArray: [
        {
          guid: "",
          title: "我的"
        }
      ],
      device: {
        list: [],
        page: 1,
        pagesize: 20,
        total: 0,
        visible: false,
        loading: false,
        agent: null
      },
      detail: {
        data: null,
        visible: false
      },
      stats: {
        visible: false,
        loading: false,
        date: null,
        deviceid: "",
        days: [],
        devices: [],
        chart: null,
      },
      record: {
        visible: false,
        list: [],
        page: 1,
        page_size: 20,
        total: 0,
        type: "30d",
        loading: false,
        guid: null
      },
      userIncome: {
        list: [],
        loading: false
      },
      search: {
        device: {
          list: [],
          page: 1,
          pagesize: 20,
          total: 0,
          loading: false,
          keyword: '',
          visible: false
        },
        agent: {
          list: [],
          loading: false,
          keyword: '',
          visible: false,
          remove: false
        }
      },
      listQuery: {
        device: '',
        keyword: '',
        agent_id: ''
      },
      form: {
        visible: false,
        action: 'create',
        clickSubmit: false,
        tab: 0,
        array: ['售卖', '租借', '超市'],
        data: {
          password: '',
          name: '',
          mobile: '',
          mode: '0',
          superior_id: '',
          val: 0,
          extra: {
            profit: {
              rel: [
                { level: 1, val: 0 }
              ],
              mode: '1'
            },
            charge_profit: {
              rel: [
                { level: 1, val: 0 }
              ],
              mode: '0'
            },
            market_profit: {
              rel: [
                { level: 1, val: 0 }
              ],
              mode: '0'
            },
            is_profit: 0,
            is_charge_profit: 0,
            is_market_profit: 0
          },
          is_profit_check: false,
          is_charge_check: false,
          is_market_check: false,
        },
      },
      commission: {
        visible: false,
        tab: 0,
        array: ['售卖', '租借', '超市'],
        data: {},
        extra: {
          profit: {
            rel: [
              { val: 0, level: 1 }
            ],
            mode: '0'
          },
          charge_profit: {
            rel: [
              { val: 0, level: 1 }
            ],
            mode: '0'
          },
          market_profit: {
            rel: [
              { val: 0, level: 1 }
            ],
            mode: '0'
          },
          is_profit: 0,
          is_charge_profit: 0,
          is_market_profit: 0,
        },
        is_profit_check: false,
        is_charge_check: false,
        is_market_check: false,
      }
    };
  },
  created() {
    this.getAgentList();
    // this.agent.loading = true;

  },
  methods: {
    closeDiaLog() {
      this.commission.visible = false;
    },
    switchProfit(type) {
      // console.log('');
      if (type == 0) {
        if (this.form.data.is_profit_check) {
          this.form.data.extra.is_profit = 1;
          console.log('is_profit 1');
        } else {
          this.form.data.extra.is_profit = 0;
          console.log('is_profit 0');

        }
      } else if (type == 1) {
        if (this.form.data.is_charge_checked) {
          this.form.data.extra.is_charge_profit = 1;
          console.log('is_charge_profit 1');

        } else {
          this.form.data.extra.is_charge_profit = 0;
          console.log('is_charge_profit 0');

        }
      } else if (type == 2) {
        if (this.form.data.is_market_check) {
          this.form.data.extra.is_market_profit = 1;
          console.log('is_market_profit 1');
          console.log('this.form.data.extra.is_market_profit', this.form.data.extra.is_market_profit);

        } else {
          this.form.data.extra.is_market_profit = 0;
          console.log('is_market_profit 0');
          console.log('this.form.data.extra.is_market_profit', this.form.data.extra.is_market_profit);


        }
      }
    },
    saveCommission() {
      // console.log('this.extra',this.commission.extra);
      // return;
      this.$post('/api/agent/subSettings', {
        target_agent_id: this.commission.id,
        extra: this.commission.extra,
      }).then(res => {
        this.$message({ message: '提交成功', type: 'success' });
        this.getAgentList();
        this.commission.visible = false;
      }).catch(error => {
        console.log("🚀 ~ saveCommission ~ error:", error)
      });
    },
    switchCommissionProfit(type) { // 切换佣金抽成

      if (type == 0) {
        if (this.commission.is_profit_check) {
          this.commission.extra.is_profit = 1;
        } else {
          this.commission.extra.is_profit = 0;
        }
      } else if (type == 1) {
        if (this.commission.is_charge_check) {
          this.commission.extra.is_charge_profit = 1;
        } else {
          this.commission.extra.is_charge_profit = 0;
        }
      } else if (type == 2) {
        if (this.commission.is_market_check) {
          this.commission.extra.is_market_profit = 1;
        } else {
          this.commission.extra.is_market_profit = 0;
        }
      }
      console.log('type-', type);
      console.log('this.commission.extra', this.commission.extra);
    },
    getCommission(type, row) {
      let extra = row.extra;
      // console.log('extra',extra);
      // return '--';
      // console.log('extra.is_profit',extra.is_profit);
      if (type == 0) { // 售卖
        if (extra.is_profit) {
          if (extra.profit.mode == 0) {
            return '￥' + extra.profit.rel[0].val + '元';
          } else {
            return extra.profit.rel[0].val + '%';
          }

        } else {
          return '--';
        }
      } else if (type == 1) { // 充电
        if (extra.is_charge_profit) {
          if (extra.charge_profit.model == 0) {
            return '￥' + extra.charge_profit.rel[0].val + '元';
          } else {
            return extra.charge_profit.rel[0].val + '%';
          }

        } else {
          return '--';
        }
      } else if (type == 2) { // 虚拟货道
        if (extra.is_market_profit) {
          if (extra.market_profit.model == 0) {
            return '￥' + extra.market_profit.rel[0].val + '元';
          } else {
            return extra.market_profit.rel[0].val + '%';
          }

        } else {
          return '--';
        }
      }

    },
    switchCommissionProfit(type) {
      if (type == 0) {
        if (this.commission.is_profit_check) {
          this.commission.extra.is_profit = 1;
        } else {
          this.commission.extra.is_profit = 0;
        }

      } else if (type == 1) {
        if (this.commission.is_charge_checked) {
          this.commission.extra.is_charge_profit = 1;
        } else {
          this.commission.extra.is_charge_profit = 0;
        }

      } else if (type == 2) {
        if (this.commission.is_market_check) {
          this.commission.extra.is_market_profit = 1;
        } else {
          this.commission.extra.is_market_profit = 0;
        }
      }
    },
    getAgentList() {
      this.agent.loading = true;
      let params = {
        superior_id: this.agent.guid,
        device: this.agent.device,
        keyword: this.agent.keyword,
        page: this.agent.page,
        page_size: this.agent.page_size
      };
      // if (this.agent.guid) {
      //   params.guid = this.agent.guid;
      // }
      this.$get('/api/agent/subList', params).then(res => {
        this.agent.loading = false;

        console.log('res', res);
        this.agent.list = res.data;

      }).catch(res => {
        this.agent.loading = false;

      })
    },
    addAgentClick() {
      this.form.visible = true;
      this.form.action = 'create';
    },
    agentPageChange(val) {
      this.agent.page = val;
      this.getAgentList();
    },
    deviceClick(item) {
      this.device.visible = true;
      this.device.loading = true;
      this.device.page = 1;
      this.device.agent_id = item.id;
      console.log('this.device11', this.device);
      this.getDeviceList();
    },
    getDeviceList() {
      this.device.loading = true;
      console.log('this.device--', this.device);
      let param = { agent_id: this.device.agent_id };
      console.log('param', param);
      this.$get('/api/device/list', param).then(res => {
        this.device.loading = false;
        console.log('device.list res', res);
        this.device.list = res.data;
      }).catch(res => {
        this.device.loading = false;

      })
    },
    getOnlineState(e) {
      const params = {
        op: 'deviceOnline',
        id: e.device.id
      }
      this.$api.device
        .getOnlineState(params)
        .then(res => {
          if (res.data.status) {
            const list = this.device.list;
            const index = list.findIndex(el => el.device.id === e.device.id);
            if (index !== -1) {
              const data = res.data.data;
              if (data.app !== undefined) {
                this.$set(list[index].status, 'app', data.app.online)
              }
              if (data.mcb !== undefined) {
                this.$set(list[index].status, 'mcb', data.mcb.online)
              }
            }
          } else {
            this.$alert(res.data.data.msg, "提示");
          }
        });
    },
    devicePageChange(val) {
      this.device.page = val;
      this.getDeviceList();
    },
    copyQrcodeUrl(url) {
      this.$copyText(url).then(
        e => {
          this.$message({
            message: "复制成功",
            type: "success",
            center: true
          });
        },
        e => {
          this.$message({
            message: "复制失败",
            type: "error",
            center: true
          });
        }
      );
    },
    deviceDetailClick(item) {
      this.detail.data = item;
      this.detail.visible = true;
    },
    getDeviceInfo(id) {
      this.detail.visible = true;
      this.$get('/api/device/info?id=' + id).then(res => {
        this.detail.data = res.info;
        this.detail.visible = false;

      }).catch(res => {
        this.detail.visible = false;

      })
    },
    subAgentClick(item) {
      console.log('subAgentClick item', item);
      if (item.sub_count > 0) {
        this.level++;
        this.levelArray.push({
          guid: item.guid,
          title: item.name
        });
        this.agent.guid = item.id;
        this.agent.page = 1;
        this.getAgentList();
      } else {
        this.$message({
          message: "没有下级",
          type: "warning",
          center: true
        });
      }
    },
    breadcrumbClick(item, index) {
      this.level = index + 1;
      this.levelArray.splice(index + 1, this.levelArray.length - (index + 1));
      this.agent.guid = item.guid;
      this.agent.page = 1;
      this.getAgentList();
    },
    percentClick(item) {
      console.log(item);
      this.commission.visible = true;
      let extra = Object.assign({}, item.extra);


      let nExtra = {
        profit: extra.profit,
        is_profit: extra.is_profit ? extra.is_profit : 0,
        is_charge_profit: extra.is_charge_profit ? extra.is_charge_profit : 0,
        is_market_profit: extra.is_market_profit ? extra.is_market_profit : 0,
      }; // 兼容处理
      if (extra.charge_profit) {
        nExtra.charge_profit = extra.charge_profit;
      } else {
        nExtra.charge_profit = {
          mode: "0",
          rel: [
            { val: 0, level: 1 },
          ]
        };
      }
      if (extra.market_profit) {
        nExtra.market_profit = extra.market_profit;
      } else {
        nExtra.market_profit = {
          mode: "0",
          rel: [
            { val: 0, level: 1 },
          ]
        };
      }



      this.commission.id = item.id;
      this.commission.extra = nExtra;
      this.commission.is_profit_check = item.extra.is_profit == 1 ? true : false;
      this.commission.is_charge_check = item.extra.is_charge_profit == 1 ? true : false;
      this.commission.is_market_check = item.extra.is_market_profit == 1 ? true : false;

    },
    statsClick(item) {
      console.log('123123');
      // this.stats.date = this.$moment().format("YYYY-MM");
      this.stats.visible = true;
      this.stats.guid = item.guid;
      this.getStatistics(item.id);
    },
    getStatistics(target_id) {
      let param = {
        device_id: this.stats.device_id,
        target_id: target_id,
      };
      this.$get('/api/order/orderListOnAgent', param).then(res => {
        this.stats.days = res.data;
      }).catch(res => { })

    },
    dateChange() {
      this.getStatistics();
    },
    deviceChange() {
      this.getStatistics();
    },
    getSummaries(param) {
      const { columns, data } = param;
      // console.log("🚀 ~ getSummaries ~ columns:", columns)
      // console.log("🚀 ~ getSummaries ~ data:", data)
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (index === 2 || index === 3) {
          const values = data.map(item => item[column.property]);

          console.log("🚀 ~ columns.forEach ~ values:", values);

          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return Number((prev + value).toFixed(2));
            } else {
              return prev;
            }
          }, 0);
        } else if (index === 4) {

          const values = data.map(item => item.free + item.fee);

          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }
      });
      return sums;
    },
    drawLine() {
      // if (this.stats.chart === null) {
      //   const echarts = require("echarts");
      //   this.stats.chart = echarts.init(document.getElementById("main"));
      // }
      // this.stats.chart.setOption(this.stats.echartsOption, true);
    },
    statsClosed() {
      // let echarts = require("echarts");
      // let myChart = echarts.init(document.getElementById("main"));
      // myChart.clear();
      // this.stats.date = null;
      // this.stats.deviceid = "";
      // this.stats.days = [];
      // this.stats.devices = [];
    },
    unbindDetailClick(item) {
      this.$confirm("是否将设备解绑？", "提示", {
        type: "warning"
      })
        .then(() => {
          // let params = {
          //   op: "deviceBind",
          //   id: item.device.id
          // };
          // this.$api.device.unbind(params).then(res => {
          //   if (res.data.status) {
          //     this.$message({
          //       type: "success",
          //       message: "解绑成功!",
          //       center: true
          //     });
          //     this.getDeviceList();
          //   } else {
          //     this.$alert(res.data.data.msg, "提示");
          //   }
          // });
          this.$post('/api/device/unbind', {
            imei: item.imei
          }).then(res => {
            this.$message({
              type: "success",
              message: "解绑成功!",
              center: true
            });
            this.getDeviceList();
          }).catch(() => { });
        }).catch(() => { });

    },
    removeAgentClick(item) {
      let params = {
        op: "removeAgent",
        agent: item.guid
      };
      this.$api.agent.removeAgent(params).then(res => {
        if (res.data.status) {
          this.$message({
            type: "success",
            message: res.data.data.message,
            center: true
          });
          this.getAgentList();
        } else {
          this.$alert(res.data.data.msg, "提示");
        }
      });
    },
    balanceClick(item) {
      console.log("🚀 ~ balanceClick ~ item:", item)
      this.record.visible = true;
      this.record.guid = item.guid;
      this.getRecordList(item.id);
      // this.getUserIncome();
    },
    getRecordList(target_id) {
      this.record.loading = true;
      let params = {
        page: this.record.page,
        page_size: this.record.page_size,
        type: this.record.type,
        target_id: target_id,
      };
      this.$get('/api/order/balanceList', params)
        .then(res => {
          this.record.list = res;
          console.log('res.data-', res);
        })
        .finally(() => {
          this.record.loading = false;
        });
    },
    tabsClick() {
      if (this.record.type == '30d') {
        this.getUserIncome();
      } else {
        this.record.page = 1;
        this.getRecordList();
      }
    },
    recordPageChange(val) {
      this.record.page = val;
      this.getRecordList();
    },
    recordClosed() {
      this.record.list = [];
      this.record.page = 1;
      this.record.total = 0;
      this.record.type = '30d';
      this.record.guid = null;
    },
    getUserIncome() {
      this.userIncome.loading = true;
      this.$get('/api/order/balanceList').then(res => {
        this.userIncome.list = res.data;
      }).finally(() => {
        this.userIncome.loading = false;
      });
    },
    searchDevice() {
      this.search.device.visible = true;
    },
    searchDeviceClosed() {
      this.search.device.page = 1;
      this.search.device.total = 0;
      this.search.device.list = [];
      this.search.device.keyword = "";
    },
    searchDeviceClick() {
      if (this.search.device.keyword.trim() == "") {
        this.$message({
          message: "请输入关键字",
          type: "warning",
          center: true
        });
        return;
      }
      this.search.device.page = 1;
      this.getDeviceSub();
    },
    getDeviceSub() {
      this.search.device.loading = true;
      const params = {
        op: 'deviceSub',
        keyword: this.search.device.keyword,
        page: this.search.device.page,
        pagesize: this.search.device.pagesize
      }
      this.$api.agent
        .getDeviceSub(params)
        .then(res => {
          if (res.data.status) {
            this.search.device.list = res.data.data.list;
            this.search.device.total = res.data.data.total;
          } else {
            this.$alert(res.data.data.msg, "提示");
          }
        })
        .finally(() => {
          this.search.device.loading = false;
        });
    },
    searchDevicePageChange(val) {
      this.search.device.page = val;
      this.getDeviceSub();
    },
    searchAgent() {
      this.search.agent.visible = true;
    },
    searchAgentClosed() {
      this.search.agent.list = [];
      this.search.agent.keyword = "";
    },
    searchAgentClick() {
      if (this.search.agent.keyword.trim() == "") {
        this.$message({
          message: "请输入关键字",
          type: "warning",
          center: true
        });
        return;
      }
      this.getAgentSub();
    },
    getAgentSub() {
      this.search.agent.loading = true;
      const params = {
        op: 'agentSub',
        keyword: this.search.agent.keyword
      }
      this.$api.agent
        .getAgentSub(params)
        .then(res => {
          if (res.data.status) {
            this.search.agent.list = res.data.data.list;
            this.search.agent.remove = res.data.data.remove;
          } else {
            this.$alert(res.data.data.msg, "提示");
          }
        })
        .finally(() => {
          this.search.agent.loading = false;
        });
    },
    nicknameEditClick(item) {
      this.$prompt('原名称：' + item.name, '修改名称', {
        inputPlaceholder: '请输入新名称'
      }).then(({ value }) => {
        this.editNickname(item.guid, value);
      }).catch(() => {

      });
    },
    editNickname(guid, name) {
      const params = {
        op: 'agentUpdate',
        guid,
        name
      }
      this.$api.agent
        .editNickname(params)
        .then(res => {
          if (res.data.status) {
            this.$message({
              message: res.data.data.msg,
              type: "success",
              center: true
            });
            this.getAgentList();
          } else {
            this.$alert(res.data.data.msg, "提示");
          }
        });
    },
    onSubmit(formName) {
      // return console.log(this.form.data.extra);
      if (this.form.action == 'create') {
        this.form.clickSubmit = true;
        this.$post('/api/agent/add', {
          name: this.form.data.name,
          mobile: this.form.data.mobile,
          password: this.form.data.password,
          extra: this.form.data.extra,
          role: 1
        }).then(res => {
          console.log('res--', res);
          this.form.clickSubmit = false;
          this.$message(
            {
              message: '添加成功',
              type: 'success'
            }
          );
          location.reload();
          // this.$router.push('/agent/index');
        }).catch(err => {
          this.form.clickSubmit = false;
        })
      } else {
        this.form.clickSubmit = true;
        this.form.data.extra.is_profit = this.form.data.extra.is_profit ? 1 : 0;
        this.$post('/api/agent/update', {
          id: this.id,
          name: this.form.data.name,
          mobile: this.form.data.mobile,
          password: this.form.data.password,
          extra: this.form.data.extra,
          role: 1
        }).then(res => {
          this.form.clickSubmit = false;

          console.log('res--', res);
          this.$message(
            {
              message: '编辑成功',
              type: 'success'
            }
          )
          location.reload();

          // this.$router.push('/agent/index');
        }).catch(err => { }).finally(() => {
          this.form.clickSubmit = false;
        })
      }


    },
  }
};
</script>

<style lang="scss" scoped>
.btns-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
}

.breadcrumb-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #eee;
  padding: 10px;

  .breadcrumb-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    .name {
      font-size: 14px;
      cursor: pointer;
    }

    .name:hover {
      text-decoration: underline;
    }

    .el-icon-arrow-right {
      font-size: 14px;
      color: #9d9d9d;
      margin-left: 5px;
    }
  }

  .breadcrumb-item:not(:first-child) {
    margin-left: 5px;
  }

  .breadcrumb-item:first-child .name {
    font-weight: 500;
  }
}

.table-row:hover {
  .el-icon-edit-outline {
    display: block;
  }
}

.row-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .username {
    margin-left: 10px;
  }

  .el-icon-edit-outline {
    margin-left: 10px;
    display: none;
    color: #9d9d9d;
    cursor: pointer;
  }
}

.level {
  padding: 5px 10px;
  color: #fff;
}

.qrcode {
  width: 150px;
  cursor: pointer;
}

.qrcode:active {
  opacity: 0.7;
}

.fa-qrcode:hover {
  color: #ccc;
}

.fa-bluetooth {
  margin-left: 10px;
  color: #1870d2;
}

.fa-bluetooth:hover {
  opacity: 0.7;
}

.group-text {
  padding: 5px 10px;
  color: #fff;
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: 0.5em;
}

.circle:hover {
  opacity: 0.7;
}

.el-table {
  .online {
    background-color: #67c23a;
  }

  .offline {
    background-color: #f56c6c;
  }
}

.detail-view {
  width: 100%;
  display: flex;
  flex-direction: column;

  .row-view {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    padding: 10px 0;

    .title {
      width: 120px;
      text-align: right;
    }

    .content {
      width: calc(100% - 150px);
      margin-left: 30px;
      color: #888;
      display: flex;
      flex-direction: row;
      align-items: center;

      .goods-img {
        width: 50px;
        height: 50px;
      }

      .goods-name {
        margin-left: 20px;
      }

      .goods-price {
        color: #f56c6c;
        margin-left: 20px;
      }
    }

    .online {
      color: #67c23a;
    }

    .offline {
      color: #f56c6c;
    }
  }
}

.top-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    font-size: 14px;
  }
}

.online-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .online-point {
    width: 10px;
    height: 10px;
  }

  .online-title {
    font-size: 14px;
    margin-left: 10px;
  }

  .online {
    background-color: #67c23a;
  }

  .offline {
    background-color: #ccc;
  }
}

.status-online {
  color: #67c23a;
}

.status-offline {
  color: #ccc;
}
</style>