import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login.vue'
import Stats from './views/Stats.vue'
import Index from './views/Index.vue'
import Order from './views/Order.vue'
import Agent from './views/Agent.vue'
import Device from './views/Device.vue'
import Zone from './views/Zone.vue'
import Goods from './views/Goods.vue'
import DiscountCoupon from './views/DiscountCoupon.vue'
import Standby from './views/Standby.vue'
import StandbyGroup from './views/StandbyGroup.vue'
import HomeAD from './views/HomeAD.vue'
import Member from './views/Member.vue'
import Staff from './views/Staff.vue'
import Category from './views/Category.vue'
import Commission from './views/Commission.vue'
import Wifi from './views/Wifi.vue'
import PrizeCoupon from './views/PrizeCoupon.vue'
import SetHeadImg from './views/SetHeadImg.vue'
import Reviewe from './views/Reviewe.vue'
import Feedback from './views/Feedback.vue'
import TypeIndex from './views/TypeIndex.vue'
import TypeEdit from './views/TypeEdit.vue'
import equiEdit from './views/equiEdit.vue'
import Examine from './views/Examine.vue'
// import CDevice from './views/device/cdevice.vue'
import {
  MessageBox,
  Message
} from 'element-ui'

Vue.use(Router)

const router = new Router({
  mode: 'history', // require service support
  base: '/',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/',
      name: 'index',
      component: Index,
      children: [
        {
          path: 'stats',
          name: 'stats',
          component: Stats
        },
        {
          path: 'agent',
          name: 'agent',
          component: Agent
        },
        {
          path: 'device',
          name: 'device',
          component: Device
        },
        {
          path: 'DiscountCoupon',
          name: 'DiscountCoupon',
          component: DiscountCoupon
        },
        {
          path: 'PrizeCoupon',
          name: 'PrizeCoupon',
          component: PrizeCoupon
        },
        {
          path: 'Standby',
          name: 'Standby',
          component: Standby
        },
        {
          path: 'HomeAD',
          name: 'HomeAD',
          component: HomeAD
        },
        {
          path: 'StandbyGroup',
          name: 'StandbyGroup',
          component: StandbyGroup
        },
        // 会员
        {
          path: 'Member',
          name: 'Member',
          component: Member
        },
        {
          path: 'zone',
          name: 'zone',
          component: Zone
        },
        {
          path: 'goods',
          name: 'goods',
          component: Goods
        },
        {
          path: 'staff',
          name: 'staff',
          component: Staff
        },
        {
          path: 'order',
          name: 'order',
          component: Order
        },
        {
          path: 'category',
          name: 'category',
          component: Category
        },


        //   {
        //     path: 'cdevice',
        //     name: 'cdevice',
        //     component: CDevice
        //   },
        //   {
        //     path: 'low_statistics',
        //     name: 'low_statistics',
        //     component: LowStatistics
        //   },
        //   {
        //     path: 'group',
        //     name: 'group',
        //     component: Group
        //   },
        //   {
        //     path: 'goods',
        //     name: 'goods',
        //     component: Goods
        //   },
        //   {
        //     path: 'model',
        //     name: 'model',
        //     component: Model
        //   },
        //   {
        //     path: 'agent',
        //     name: 'agent',
        //     component: Agent
        //   },
        {
          path: 'commission',
          name: 'commission',
          component: Commission
        },
        {
          path: 'wifi',
          name: 'wifi',
          component: Wifi
        },
        {
          path: 'SetHeadImg',
          name: 'SetHeadImg',
          component: SetHeadImg
        },
        {
          path: 'Reviewe',
          name: 'Reviewe',
          component: Reviewe
        },
        {
          path: 'Feedback',
          name: 'Feedback',
          component: Feedback
        },
        {
          path: 'TypeIndex',
          name: 'TypeIndex',
          component: TypeIndex
        },
        {
          path: 'TypeEdit',
          name: 'TypeEdit',
          component: TypeEdit
        },
        {
          path: 'equiEdit',
          name: 'equiEdit',
          component: equiEdit
        },
        {
          path: 'Examine',
          name: 'Examine',
          component: Examine
        },
        //   {
        //     path: 'order',
        //     name: 'order',
        //     component: Order
        //   },
        //   {
        //     path: 'staff',
        //     name: 'staff',
        //     component: Staff
        //   },
        //   {
        //     path: 'income',
        //     name: 'income',
        //     component: Income
        //   },
        //   {
        //     path: 'subscription',
        //     name: 'subscription',
        //     component: Subscription
        //   },
        //   {
        //     path: 'adv',
        //     name: 'adv',
        //     component: Adv
        //   },
        //   {
        //     path: 'document',
        //     name: 'document',
        //     component: Document
        //   },
        //   {
        //     path: 'audit',
        //     name: 'audit',
        //     component: Audit
        //   },
        //   {
        //     path: 'setting',
        //     name: 'setting',
        //     component: Setting
        //   }
      ]
    }

  ]
});

router.beforeEach((to, form, next) => {
  console.log("🚀 ~ router.beforeEach ~ form:", form)
  console.log('to path', to.path);
  console.log("🚀 ~ router.beforeEach ~ to.name:", to.name)
  if (to.path.indexOf('/login') != -1) {
    next();
  } else {
    let token = sessionStorage.getItem("token");
    if (!token) { // 未登录
      Message({
        message: '登录失效,请重新登录',
        type: 'error'
      })
      // router.push('login')
      next('login');
    } else {
      next();
    }

  }
})


export default router;
