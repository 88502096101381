<template>
    <div class="p-10">
        <el-row class="pl30 pr-30 bg-white">
            <el-col>
                <el-form ref="form" :rules="rules" :model="form" v-loading="loading" label-width="auto">
                    <h4>{{$t('设备信息')}}</h4>
                    <el-form-item :label="$t('所属代理')">
                        <el-select v-model="form.agent_id" :placeholder="$t('请输入内容')" filterable clearable remote
                            @change="handleSelect" :remote-method="getAgentList">
                            <el-option v-for="(item, index) in agentList" :label="item.name" :value="item.id"
                                :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <!-- <el-link type="primary" @click="$router.push('/agent/edit/'+form.agent_id)">详情</el-link> -->

                        <el-table v-if="agent.info" :data="agent.info">

                            <el-table-column :label="$t('代理')">
                                <template slot-scope="scope">
                                    <div style="display:flex;">
                                        <img />
                                        <div>{{ scope.row.name }}</div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('代理信息')">
                                <template slot-scope="scope">
                                    <div>{{$t('电话')}}：{{ scope.row.mobile }}</div>
                                    <div>{{$t('上级代理')}}：{{ scope.row.superior_name }}</div>
                                    <div>{{$t('分润')}}：
                                        <span v-if="scope.row.extra && scope.row.extra.is_profit">{{
                                            scope.row.extra.is_profit
                                            == 0 ? $t('未启用') : $t('已启用') }}</span>
                                        <span v-else>未启用</span>
                                    </div>
                                    <div>{{$t('创建时间')}}：{{ scope.row.create_time }}</div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>

                    <el-form-item :label="$t('名称')">
                        <el-input v-model="form.name" placeholder="" style="width:200px"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('硬件IMEI')">
                        <el-input v-model="form.imei" readonly placeholder="" style="width:200px"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('设备型号')">
                        <div>
                            <el-select v-model="form.device_type_id" :placeholder="$t('请选择型号')" filterable clearable remote
                                :remote-method="getTypeList" @change="handleTypeChange">
                                <el-option v-for="(item, index) in typeList" :label="item.name" :value="item.id"
                                    :key="index"></el-option>
                            </el-select>
                        </div>
                        <div style="margin-top:10px;">
                            <el-table :data="form.lanes" border>
                                <el-table-column :label="$t('货道')" prop="lane"></el-table-column>
                                <el-table-column :label="$t('商品名称')">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.goods">{{ scope.row.goods.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('价格')">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.goods">{{ scope.row.goods.price }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$t('容量')" prop="max_num">
                                </el-table-column>
                                <el-table-column :label="$t('当前数量')" prop="num">
                                </el-table-column>
                            </el-table>
                        </div>

                    </el-form-item>

                    <el-form-item :label="$t('设备场地')">
                        <div>
                            <el-select v-model="form.zone_id" :placeholder="$t('请选择场地')" filterable clearable remote
                                :remote-method="searchZone">
                                <el-option v-for="(item, index) in zoneList" :label="item.name" :value="item.id"
                                    :key="index"></el-option>
                            </el-select>
                            <!-- <el-link type="primary">详情</el-link> -->
                        </div>
                    </el-form-item>


                    <el-form-item>
                        <el-button class="mt-10 mb-10" type="primary" @click="onSubmit('form')" :loading="clickSubmit"
                            :disabled="clickSubmit">{{$t('立即提交')}}</el-button>
                    </el-form-item>
                </el-form>



            </el-col>
        </el-row>
    </div>
</template>

<script>
import _ from 'lodash'
export default {
    data() {
        return {
            form: {
                // imei:'',
                // name:'',
                // agent_id:'',
                // agent_name:'',
                // data:{},
            },
            loading: true,
            clickSubmit: false,
            rules: {

            },
            agent: {

                info: [{
                    name: '',
                    store_name: '',
                    address: ''
                }]
            },
            eqiupStore: {
                store_name: '',
                address: ''
            },
            laneList: [{
                lane: '01',
                goods_id: 1,
                price: 0.01,
                max_num: 1,
                num: 1
            }, {
                lane: '02',
                goods_id: 1,
                price: 0.01,
                max_num: 1,
                num: 1
            }],
            vistualLane: [

                {
                    lane: '01',
                    goods_id: 1,
                    price: 0.01,
                    max_num: 1,
                    num: 1
                }, {
                    lane: '02',
                    goods_id: 1,
                    price: 0.01,
                    max_num: 1,
                    num: 1
                }
                ,
            ],
            typeList: [
                {
                    name: 'Y35',
                    id: 1
                },
                {
                    name: 'Y35-1',
                    id: 2
                }
            ],
            lane: [
                { id: 3, lane: 1, goods: '', price: '', max_num: '', num: '' },
                { id: 3, lane: 2, goods: '', price: '', max_num: '', num: '' }
            ],
            goodsList: [
                {
                    id: 1,
                    name: "商品一"
                }
            ],
            zoneList: [],
            agentList: [],
            zone: {
                data: []
            },
            id: this.$route.query.id || '',
            typeInfo: {},
            baseForm: {},
            zoneInfo: {},
        }
    },
    async mounted() {
        // if(this.id > 0) this.getInfo()
        await this.getInfo()
        this.loading = false;
        // console.log('this.form',this.form);
        this.getAgentInfo();
        this.getZoneInfo();
        this.getDeviceTypeInfo();
    },
    methods: {
        //   querySearchAsync(queryString, cb) {
        //     this.$get('/api/agent/list',{
        //         keyword:queryString
        //     }).then(res=>{
        //         console.log('res--',res);
        //         let data = [];
        //         for(let i in res.data){
        //             let item = res.data[i];
        //             item.id = item.id;
        //             item.value = item.name;
        //             data.push(item);
        //         }
        //         console.log('data--',data);
        //         cb(data);
        //     })
        //   },
        onSubmit() {
            let params = {
                id: this.form.id,
                agent_id: this.form.agent_id,
                name: this.form.name,
                device_type_id: this.form.device_type_id,
                zone_id: this.form.zone_id,
                lanes: this.form.lanes.map(e => {
                    return {
                        lane: e.lane,
                        num: e.num,
                        max_num: e.max_num,
                        goods_id: e.goods_id,
                    };
                })
            };
            this.clickSubmit = true;
            this.$post('/api/device/update', params).then(res => {
                this.$message({
                    message: '编辑成功',
                    type: 'success'
                });
                this.$router.push('/device');
            }).catch(re => {
                this.clickSubmit = false;
            })
        },
        getZoneList() {
            this.$get('/api/agentZone/list').then(res => {
                // console.log('agentZone',res.data);
                this.zone.data = res.data;
            })
        },
        getAgentInfo() {
            if (this.form.agent_id) {
                // console.log('getAgentInfo',this.form.agent_id);
                this.$post('/api/agent/info', {
                    id: this.form.agent_id
                }).then(res => {
                    // console.log('getAgentInfo--',res);
                    this.agent.info = [res.info];
                    this.getAgentList(res.info.name);
                }).finally();
            } else {
                this.form.agent_id = '';
            }

        },
        getAgentList(keyword) {
            // console.log('getAgentList');
            this.$get('/api/agent/list', { keyword }).then(res => {
                this.agentList = res.data;
            }).finally();;
        },
        async getInfo() {
            await this.$get('/api/device/info', {
                id: this.id
            }).then(res => {
                // console.log('id',this.id);
                // console.log('res--',res);
                this.form = res.info
                // console.log(this.form);
                this.baseForm = _.cloneDeep(this.form);
            }).finally();
        },
        getDeviceTypeInfo() { // 型号详情
            // console.log('getDeviceTypeInfo');
            // console.log('this.form-',this.form);

            if (this.form.device_type_id) {
                this.$get('/api/deviceType/info', {
                    id: this.form.device_type_id
                }).then(res => {
                    if (res == '') {
                        res.info = {
                            name: '3'
                        };
                    }
                    this.getTypeList(res.info.name);
                    this.typeInfo = res.info;
                }).finally();
            }
        },
        getTypeList(keyword) {
            // console.log(keyword);
            // console.log('getTypeList keyword',keyword);
            this.$get('/api/deviceType/list', { keyword }).then(res => {
                // console.log('res-----',res.data);
                this.typeList = res.data;
            }).finally();
        },
        getZoneInfo() {
            if (this.form.zone_id) {
                this.$get('/api/agentZone/info', {
                    id: this.form.zone_id
                }).then(res => {
                    this.zoneInfo = res.info;
                    this.searchZone(res.info.name);
                }).finally();
            }

        },
        searchZone(keyword) {
            this.$get('/api/agentZone/list', { keyword }).then(res => {
                this.zoneList = res.data;
            })
        },
        billingChange() {
            // console.log('method',this.form.billing_method);
        },
        handleInput() {
            this.$forceUpdate();
        },
        handleSelect(val) {
            let info = {};
            for (let i in this.agentList) {
                let item = this.agentList[i];
                if (item.id == val) {
                    info = this.agentList[i];
                }
            }
            this.agent.info = [info];
        },
        handleTypeChange(val) {
            // console.log('hadnleTypeChange',val);
            let current = {};
            for (let i in this.typeList) {
                if (this.typeList[i].id == val) {
                    current = this.typeList[i];
                }
            }
            // console.log(this.typeList);
            let lanes = [];
            // console.log('this.baseForm.lanes',this.baseForm.lanes);
            for (let t in current.extra.lanes) {

                let item = current.extra.lanes[t];
                let isMatch = false;
                for (let f in this.baseForm.lanes) {
                    let fItem = this.baseForm.lanes[f];
                    if (t == f) {
                        // console.log('fItem',fItem);
                        t = +t;
                        isMatch = true;
                        lanes[t] = {
                            lane: t + 1, num: fItem.num, max_num: item.max_num,
                            goods_id: fItem.goods_id,
                            goods: {
                                name: fItem.goods ? fItem.goods.name : '',
                                price: fItem.goods ? fItem.goods.price : ''
                            }
                        };
                    }
                }

                if (!isMatch) {
                    lanes.push({
                        lane: t = +t + 1,
                        num: 0,
                        max_num: item.max_num,
                        goods_id: item.goods_id,
                        goods: {
                            name: item.goods_name,
                        }
                    });
                }

            }
            // console.log('lanes---',lanes);
            this.form.device_type_id = current.id;
            this.form.lanes = lanes
        },
        addLaneClick() {
            // console.log('addLaneClick');
            this.laneList.push({
                lane: '01',
                goods_id: 1,
                price: 0.01,
                max_num: 1,
                num: 1
            });
        },
        changeColor(event, color) {
            event.srcElement.style.color = color;
        },
        deleteClick(index) {
            this.laneList = this.laneList.splice(index); // todo 移除下标
            // let laneList = this.laneList.slice(index,1);
            // console.log('laneList',this.laneList);
        },
        getSummaries(param) {
            // console.log('param',param);
            // const {columns,data} = param;
            let plusIcon = this.$createElement('el-button', {
                attrs: { icon: 'el-icon-plus' },
                on: { click: this.addLaneClick }
            });
            const sums = [plusIcon]; // ,'N/A','1883元','15.83元','63元'

            // columns.forEach((col,idx)=>{
            //     if(idx == 0){
            //         sums[index] = '总价';
            //         return;
            //     }
            // });
            return sums;

        }
    }
}
</script>