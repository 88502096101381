<template>
  <div class="content-view" v-title :data-title="$t('下级代理')" style="background:#fff;">
    <div class="top-view" style="padding:10px;">
      <!-- <el-button type="success" @click="searchDevice">搜索设备</el-button>
      <el-button type="warning" @click="searchAgent">搜索下级</el-button> -->
      <el-button type="success" @click="createClick">{{$t('添加场地')}}</el-button>
    </div>
    <!-- {{levelArray}} -->
    <div class="breadcrumb-view" v-if="levelArray.length > 1">
      <i class="el-icon-user-solid"></i>
      
      <div class="breadcrumb-item" v-for="(item,index) in levelArray" :key="index">
        <span class="name" @click="breadcrumbClick(item,index)">{{item.title}}</span>
        <i class="el-icon-arrow-right" v-if="index != levelArray.length - 1"></i>
      </div>
    </div>
    <el-table :data="list" v-loading="agent.loading" row-class-name="table-row">
      <el-table-column type="index" label="#"></el-table-column>
      <el-table-column :label="$t('场地名称')" prop="name">
      </el-table-column>
      <!-- <el-table-column label="套餐" prop="name">
        <template slot-scope="scope">
          {{scope.row?.extra?.base_package?.desc}}
        </template>
      </el-table-column> -->
      <el-table-column :label="$t('操作')" width="400" align="center">
        <template slot-scope="scope">
          <!-- <el-button type="" @click="wifiClick(scope.row)" size="mini">Wifi管理</el-button> -->
          <!-- <el-button type="" @click="goodsClick(scope.row)" size="mini">虚拟货道</el-button> -->
          <el-button type="primary" @click="editClick(scope.row)" size="mini">{{$t('编辑')}}</el-button>
          <el-button type="danger" @click="deleteClick(scope.row)" size="mini">{{$t('删除')}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="agent.page" :page-size="agent.pagesize" :total="agent.total" @current-change="agentChange"></el-pagination>
    
    <!-- <el-dialog title="Wifi管理" :visible.sync="wifi.visbile">
      <el-button type="success" @click="addWifiClick">添加Wifi</el-button>
      <el-table :data="wifi.list" v-loading="wifi.loading">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="密码" prop="password"></el-table-column>
        <el-table-column label="操作" >
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editWifiClick(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" @click="delWifiClick(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="wifi.listQuery.page"
      :page-size="wifi.listQuery.page_size" :total="wifi.listQuery.total" @current-change="wifiPageChange"></el-pagination>
    </el-dialog> -->

    <!-- <el-dialog title="虚拟货道" :visible.sync="wifi.visbile">
      <el-button type="success" @click="addLaneClick">添加虚拟货道</el-button>
      <el-table :data="lane.list">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="密码" prop="password"></el-table-column>
        <el-table-column label="操作" >
          <el-button type="primary" size="mini" @click="editWifiClick()">编辑</el-button>
          <el-button type="danger" size="mini" @click="delWifiClick()">删除</el-button>
        </el-table-column>
      </el-table>
      <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="wifi.listQuery.page"
      :page-size="wifi.listQuery.page_size" :total="wifi.listQuery.total" @current-change="wifiPageChange"></el-pagination>
    </el-dialog> -->

    <!-- <el-dialog :title="wifi.form.action == 'create'?'添加wifi':'编辑wifi'" :visible.sync="wifi.form.visible">
      <el-form :model="wifi.form.data" label-width="auto" ref="wifiForm">
        <el-form-item prop="name" label="名称" :rules="[{ required: true, message: '请输入wifi名称', trigger: 'blur' }]">
          <el-input v-model="wifi.form.data.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item prop="password" label="密码" :rules="[{ required: true, message: '请输入wifi密码', trigger: 'blur' }]">
          <el-input v-model="wifi.form.data.password" placeholder="请输入密码"></el-input>
        </el-form-item>

      </el-form>
      <template slot="footer">
          <el-button type="" @click="wifi.form.visible = false">取消</el-button>
          <el-button type="primary" @click="submitWifi()">保存</el-button>
      </template>
        

    </el-dialog> -->

    <el-dialog :title="form.action == 'create'? $t('添加场地') : $t('编辑场地')" :visible.sync="form.visbile">
      <el-form :model="form" ref="form" label-width="auto">
        <h4>{{$t('基础信息')}}</h4>
        <el-form-item :label="$t('名称')" prop="name" :rules="[{required:true,message:$t('请输入场地名称'),trigger:'blur'}]">
            <el-input v-model="form.name" style="width:350px" :placeholder="$t('请输入场地名称')"></el-input>
        </el-form-item>
        <el-form-item label="前台电话" prop="mobile" :rules="[{required:true,message:'请输入前台电话',trigger:'blur'}]">
            <el-input v-model="form.mobile" style="width:350px" placeholder="请输入前台电话"></el-input>
        </el-form-item>
        <el-form-item label="一级代理电话" prop="sup_mobile">
            <el-input v-model="form.sup_mobile" style="width:350px" placeholder="请输入一级代理电话"></el-input>
        </el-form-item>
        <!-- {{form}} -->
        <!-- <h4>虚拟货道</h4> -->
        <!-- <div>
          
          <div v-for="(item,index) in form.market" :key="index" style="display:flex;item-align:center;margin-top:5px;">
              <h4 style="line-height: 0;margin-left: 20px;">{{index+1}}</h4>
              <div style="margin-left:10px;">
                商品：
                <el-select v-model="form.market[index].goods_id" placeholder="请选择商品">
                  <el-option v-for="(item,index) in goods" :label="item.name" :value="item.id" :key="index"></el-option>
                </el-select>
              </div>
              <div style="margin-left:10px;">
                价格：
                <el-input v-model="form.market[index].price" style="width:100px" disabled></el-input>元
              </div>
              <div style="margin-top:10px;margin-left:10px;">
                <el-checkbox v-model="form.market[index].status">启用</el-checkbox>
                <el-switch
                v-model="form.market[index].status"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch>
              </div>
              <div>
                <el-button v-if="index > 0" @click="minusClick(index)" type="danger" icon="el-icon-minus" style="margin-left:20px;margin-top:6px;" size="mini" circle></el-button>
                <el-button v-if="index == form.market.length - 1" @click="plusClick" type="success" icon="el-icon-plus" style="margin-left:20px;margin-top:6px;" size="mini" circle></el-button>
              </div>
          </div>
          
        </div> -->

        <template>
            <!-- <h4>计费方式</h4>
            <el-form-item label="套餐规则">
            <el-checkbox-group v-model="form.type" size="small">
              <el-checkbox-button label="套餐计费"></el-checkbox-button>
              <el-checkbox-button label="按时长计费"></el-checkbox-button>
            </el-checkbox-group>
            </el-form-item>
            
            <h4>套餐计费</h4>
            <span v-if="form.type.includes('套餐计费')">
            <el-form-item label="套餐描述">
                <el-input style="width:350px;" v-model="form.base_package.desc" placeholder="请输入套餐描述"></el-input>

            </el-form-item>
            <el-form-item label="套餐规则" >
              
              <div>
                <div style="margin-top:5px;">
                  预支付
                  <el-input-number placeholder="" v-model="form.base_package.deposit" type="number"></el-input-number>
                  元
                </div>
                <div style="margin-top:5px;">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;前
                  <el-input-number style="width:150px" placeholder="" v-model="form.base_package.duration"></el-input-number>
                  小时，收取
                  <el-input-number placeholder="" v-model="form.base_package.price"></el-input-number>
                  元，
                  <span style="text-decoration:underline;">{{form.base_package.duration}}</span>
                  小时后，每小时收费
                  <el-input-number placeholder="" style="width:150px;" v-model="form.base_package.extra_hour_price"></el-input-number>元
                </div>
              </div>
            </el-form-item>
            </span>

            <h4>按时长计费</h4>
            <el-form-item label="时长规则" v-if="form.type.includes('按时长计费')">
                    <div  style="margin-top:5px;">
                        <el-input-number v-model="form.packages[0].duration" style="width:200px" placeholder="" />小时，<el-input-number v-model="form.packages[0].price" placeholder="" />元
                        <el-input style="width:200px" v-model="form.packages[0].desc" placeholder="请输入规则描述"></el-input>
                        <el-checkbox v-model="form.packages[0].is_enable" label=""></el-checkbox>

                    </div>
                    <div  style="margin-top:5px;">
                        <el-input-number v-model="form.packages[1].duration" style="width:200px" placeholder="" />小时，<el-input-number v-model="form.packages[1].price" placeholder="" />元
                        <el-input style="width:200px" v-model="form.packages[1].desc" placeholder="请输入规则描述"></el-input>
                        <el-checkbox v-model="form.packages[1].is_enable" label=""></el-checkbox>

                    </div>
                    <div  style="margin-top:5px;">
                        <el-input-number v-model="form.packages[2].duration" style="width:200px" placeholder="" />小时，<el-input-number v-model="form.packages[2].price" placeholder="" />元
                        <el-input style="width:200px" v-model="form.packages[2].desc" placeholder="请输入规则描述"></el-input>
                        <el-checkbox v-model="form.packages[2].is_enable" label=""></el-checkbox>


                    </div>
            </el-form-item> -->

          </template>

          
      </el-form>
      <div slot="footer" class="dialog-footer">
                <el-button @click="form.visible = false">取 消</el-button>
                <el-button type="primary" :disabled="form.disabled" :loading="form.disabled" @click="createSubmit">确 定</el-button>
          </div>
    </el-dialog>





  </div>
</template>

<script>
// import { deepClone } from '@/utils/index'
import _ from 'lodash'
export default {
  name: "agent",
  data () {
    return {
      goods:[],
      form:{
        action:'create',
        visbile:false,
        type:[],
        name:'',
        market:[
          {goods_id:'',status:false}
        ],
        base_package:{
          deposit:0,
          duration:0,
          extra_hour_price:0,
          price:0,
          desc:''
        },
        packages:[
          {
            duration:0,
            price:0,
            is_enable:false
          },
          {
            duration:0,
            price:0,
            is_enable:false
          },
          {
            duration:0,
            price:0,
            is_enable:false
          }
        ],

      },
      agent: {
        list: [
            {
              avatar:'',
              name:'',
              level:{
                clr:1
              }
            }
        ],
        page: 1,
        pagesize: 20,
        total: 0,
        loading: false,
        guid: null,
        remove: false
      },
      level: 1,
      levelArray: [
        {
          guid: "",
          title: "我的"
        }
      ],
      device: {
        list: [],
        page: 1,
        pagesize: 20,
        total: 0,
        visible: false,
        loading: false,
        agent: null
      },
      detail: {
        data: null,
        visible: false
      },
      record: {
        visible: false,
        list: [],
        page: 1,
        pagesize: 20,
        total: 0,
        type: "30d",
        loading: false,
        guid: null
      },
      userIncome: {
        list: [],
        loading: false
      },
      search: {
        device: {
          list: [],
          page: 1,
          pagesize: 20,
          total: 0,
          loading: false,
          keyword: '',
          visible: false
        },
        agent: {
          list: [],
          loading: false,
          keyword: '',
          visible: false,
          remove: false
        }
      },
      listQuery:{
        device:'',
        keyword:''
      },
      loading:false,
      list:[],
      goods:[],
      lane:{
        visbile:false,
        loading:false,
        list:[],
      },
      wifi:{
        visbile:false,
        loading:false,
        page:1,
        page_size:20,
        zone_id:0,
        listQuery:{
          page:1,
          page_size:20,
          total:0
        },
        list:[],
        form:{
          visible:false,
          data:{
            name:'',
            password:''
          },
        },
        baseData:{
            name:'',
            password:''
        }
      }
    };
  },
  created () {
    this.getList();
    this.getGoodsList();
    // this.agent.loading = true;

  },
  methods: {
    async wifiClick(row){
      // console.log('wifiClick row',row);
      this.wifi.visbile = true;
      // console.log('this.wifi.form.data',this.wifi.form.data);
      this.wifi.form.zone_id = row.id;
      this.wifi.listQuery.zone_id = row.id;
      console.log('wifiClick',this.wifi.form.data);
      await this.getWifiList();
    },
    // addLaneClick(){

    // },
    addWifiClick(){
      this.wifi.form.data = _.cloneDeep(this.wifi.baseData);
      this.wifi.form.visible = true;
      console.log('addWifiClick',this.wifi.form);
    },
    editWifiClick(row){
      this.wifi.form.visible = true;
      this.wifi.form.data = row;
    },
    delWifiClick(row){
      this.$confirm('确定要删除该wifi吗?','提示').then(res=>{
        this.$post('/api/wifi/del',{ids:row.id}).then(res=>{
          this.$message({message:'操作成功',type:'success'});
          this.getWifiList();
        });
      })
      
    },
    submitWifi(){
      console.log('this.wifi.form.data',this.wifi.form.data);
      this.$refs['wifiForm'].validate((valid)=>{
        if(valid){
          if(!this.wifi.form.data.id){
            this.$post('/api/wifi/add',{
              name:this.wifi.form.data.name,
              password:this.wifi.form.data.password,
              zone_id:this.wifi.form.zone_id
            }).then(res=>{
              console.log('submitWifi');
              console.log('res--',res);
              this.wifi.form.visible = false;
              this.$message({message:'操作成功',type:'success'});
              this.getWifiList();
            }).catch(err=>{
              console.log('submitWifi error');
            });
          }else{
            this.$post('/api/wifi/update',{
              id:this.wifi.form.data.id,
              name:this.wifi.form.data.name,
              password:this.wifi.form.data.password,
              zone_id:this.wifi.form.zone_id
            }).then(res=>{
              this.wifi.form.visible = false;
              this.$message({message:'操作成功',type:'success'});
              this.getWifiList();
            }).catch(err=>{
              console.log('submitWifi error');
            });
          }
          
        }
      });
      
    },
    async getWifiList(){
      this.wifi.loading = true;
      await this.$get('/api/wifi/list',{
        zone_id:this.wifi.form.zone_id
      }).then(res=>{
        this.wifi.list = res.data;
        this.wifi.loading = false;

      });
    },
    addZone(){
      this.form.visbile = true;
    },
    handleChange(){

    },
    wifiPageChange(val){
      this.listQuery.page = val;
      this.getWifiList();
    },
    agentChange(){

    },
    // goodsChange(index){
    //   console.log('goodsChange index',index);
    //   let goods_id = this.form.market[index].goods_id;
    //   console.log('goods_id',goods_id);
    //   let goods = {};
    //   for(let i in this.goods){
    //     console.log('this.goods[i]',this.goods[i]);
    //     if(this.goods[i].id == goods_id){
    //       goods = this.goods[i];
    //     }
    //   }
    //   console.log('goods-',goods);
    //   this.form.market[index].price = goods.price;
    // },
    searchAgentClick(){},
    getList () {
      this.loading = true;
      this.$get('/api/agentZone/list',{
        agent_id:''
      }).then(res=>{
        this.loading = false;
        this.list = res.data;
      }).catch(res=>{
        this.loading = false;
      })
    },
    getGoodsList(){
      this.$get('/api/goods/list',{
        page:1,
        page_size:99
      }).then(res=>{
        this.goods = res.data;
        console.log('this.goods',this.goods);
      })
    },
    createClick(){
      this.form = {
        action:'create',
        visbile:true,
        type:[],
        name:'',
        mobile:'',
        market:[
          {goods_id:'',status:false}
        ],
        base_package:{
          deposit:0,
          duration:0,
          extra_hour_price:0,
          price:0,
          desc:''
        },
        packages:[
          {
            duration:0,
            price:0,
            is_enable:false
          },
          {
            duration:0,
            price:0,
            is_enable:false
          },
          {
            duration:0,
            price:0,
            is_enable:false
          }
        ],

      };
    },
    async editClick(item){
      // this.form.action = 'update';
      // this.form.visbile = true;
      console.log('item',item);
      let info = await this.getInfo(item.id);
      let type = [];
      if(info.extra.enable_base == 1){
          type.push('套餐计费');
      }
      if(info.extra.enable_package == 1){
          type.push('按时长计费');
      }
      
      info.extra.base_package.duration = info.extra.base_package.duration / 3600;
      this.form = {
        id:info.id,
        ...info.extra,
        name:info.name,
        mobile:info.mobile,
        sup_mobile:info.sup_mobile,
        action:'update',
        visbile:true,
        type:type,
        market:info.market,
        disabled:false
      };

      let packages = [];
      for(let i =0;i<3;i++){
        if(this.form.packages[i]){
          this.form.packages[i].duration = this.form.packages[i].duration / 3600; // 转成小时制
          packages[i] = this.form.packages[i];
          packages[i].is_enable = this.form.packages[i].is_enable == 1 ?true:false;
        }else{
          packages[i] = {
            duration:0,
            price:0,
            is_enable:false
          };
        }
      }
      this.form.packages = packages;
      console.log('form11',this.form);
      // console.log('this.form.type',this.form.type);
      console.log('this.form.packages',this.form.packages);
      console.log('this.form.base_package',this.form.base_package);
      // console.log('this.form-',this.form);
    },
    async getInfo(id){
      let info = await this.$post('/api/agentZone/info',{id}).then(res=>{
        return res.info;
      });
      return info;
    },
    deleteClick(row){
        this.$confirm('是否删除该场地','提示',{
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(()=>{
          this.$post('/api/agentZone/del',{
            ids:row.id
          }).then(res=>{
              this.$message({
                message: "删除成功",
                type: "success",
                center: true
              });
              location.reload();
          })
            
        });
    },
    createSubmit(){
      // console.log('this.form-',this.form);
      // return;
      console.log('this.$refs',this.$refs['form']);
      // return;
      this.$refs['form'].validate((valid)=>{
        if(valid){
          let form = _.cloneDeep(this.form);

          // for(let i in form.market){ // 验证虚拟货道
          //   let item = form.market[i];
          //   if(item.goods_id == ''){
          //        this.$message(
          //         {
          //             message: "货道"+(parseInt(i)+1)+'不能为空',
          //             type: "warning",
          //             center: true
          //         });
          //         return;
          //   }
          //   form.market[i].status = item.status?1:0;
          // }

          let param = {
              name:form.name,
              market:form.market,
              mobile:form.mobile,
              sup_mobile:form.sup_mobile,
              extra:{
                enable_base: form.type.includes('套餐计费')?1:0,
                enable_package: form.type.includes('按时长计费')?1:0,
                base_package:{
                  is_enable:form.type.includes('套餐计费')?1:0,
                  desc:form.base_package.desc,
                  deposit:form.base_package.deposit,
                  price:form.base_package.price,
                  duration:form.base_package.duration > 0 ? form.base_package.duration * 3600:0, // 小时换算成秒
                  extra_hour_price:form.base_package.extra_hour_price,
                },
                packages:form.packages
              }
            };

          for(let i in form.packages){
            let item = form.packages[i];
            param.extra.packages[i].price = item.price;
            param.extra.packages[i].duration = item.duration * 3600;
            param.extra.packages[i].desc = item.desc;
            if(!this.form.type.includes('按时长计费')){
              param.extra.packages[i].is_enable = 0;
              
            }else{
              param.extra.packages[i].is_enable = item.is_enable ? 1:0;
            }
          }



          this.form.disabled = true;
          
          console.log('param',param);
          // return;
          if(this.form.action == 'create'){
            this.$post('/api/agentZone/add',param).then(res=>{
              console.log('res',res);
              this.form.disabled = false;
              this.form.visible = false;
              this.$message(
              {
                  message: "添加成功",
                  type: "success",
                  center: true
              });
              location.reload();
            }).catch(res=>{
              this.form.disabled = false;
            });

          }else{
            param.id = this.form.id;
            this.$post('/api/agentZone/update',{...param}).then(res=>{
              console.log('res',res);
              this.form.disabled = false;
              this.form.visible = false;
              this.$message(
              {
                  message: "编辑成功",
                  type: "success",
                  center: true
              });
              location.reload();
            }).catch(res=>{
              console.log('');
              this.form.disabled = false;
            });
          }
        }
        
      })
      
      
    },
    minusClick(index){
      this.form.market = this.form.market.splice(index);
    },
    plusClick(){
      this.form.market.push({goods_id:'',status:false});
    }
    
  }
};
</script>

<style lang="scss" scoped>
.btns-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
}

.breadcrumb-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #eee;
  padding: 10px;

  .breadcrumb-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    .name {
      font-size: 14px;
      cursor: pointer;
    }

    .name:hover {
      text-decoration: underline;
    }

    .el-icon-arrow-right {
      font-size: 14px;
      color: #9d9d9d;
      margin-left: 5px;
    }
  }

  .breadcrumb-item:not(:first-child) {
    margin-left: 5px;
  }

  .breadcrumb-item:first-child .name {
    font-weight: 500;
  }
}

.table-row:hover {
  .el-icon-edit-outline {
    display: block;
  }
}

.row-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .username {
    margin-left: 10px;
  }

  .el-icon-edit-outline {
    margin-left: 10px;
    display: none;
    color: #9d9d9d;
    cursor: pointer;
  }
}

.level {
  padding: 5px 10px;
  color: #fff;
}

.qrcode {
  width: 150px;
  cursor: pointer;
}

.qrcode:active {
  opacity: 0.7;
}

.fa-qrcode:hover {
  color: #ccc;
}

.fa-bluetooth {
  margin-left: 10px;
  color: #1870d2;
}

.fa-bluetooth:hover {
  opacity: 0.7;
}

.group-text {
  padding: 5px 10px;
  color: #fff;
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: 0.5em;
}

.circle:hover {
  opacity: 0.7;
}

.el-table {
  .online {
    background-color: #67c23a;
  }

  .offline {
    background-color: #f56c6c;
  }
}

.detail-view {
  width: 100%;
  display: flex;
  flex-direction: column;

  .row-view {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    padding: 10px 0;

    .title {
      width: 120px;
      text-align: right;
    }

    .content {
      width: calc(100% - 150px);
      margin-left: 30px;
      color: #888;
      display: flex;
      flex-direction: row;
      align-items: center;

      .goods-img {
        width: 50px;
        height: 50px;
      }

      .goods-name {
        margin-left: 20px;
      }

      .goods-price {
        color: #f56c6c;
        margin-left: 20px;
      }
    }

    .online {
      color: #67c23a;
    }

    .offline {
      color: #f56c6c;
    }
  }
}

.top-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    font-size: 14px;
  }
}

.online-view {
  display: flex;
  flex-direction: row;
  align-items: center;

  .online-point {
    width: 10px;
    height: 10px;
  }

  .online-title {
    font-size: 14px;
    margin-left: 10px;
  }

  .online {
    background-color: #67c23a;
  }

  .offline {
    background-color: #ccc;
  }
}

.status-online {
  color: #67c23a;
}

.status-offline {
  color: #ccc;
}

</style>