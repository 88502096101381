<template>
  <div class="content-view" v-title :data-title="$t('运营人员')">
    <div class="top-view">
      <el-button type="success" @click="createStaffClick">{{ $t('add_staff') }}</el-button>
      <el-input style="width:350px;margin-left: 20px;" :placeholder="$t('Please_enter_keywords_for_search')"
        v-model="staff.keyword" clearable @clear="getStaffList" @keyup.enter.native="getStaffList">
        <el-button slot="append" icon="el-icon-search" @click="getStaffList"></el-button>
      </el-input>
    </div>
    <el-table :data="staff.list" style="margin-top:10px;" v-loading="staff.loading">
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column prop="name" :label="$t('name')" min-width="150"></el-table-column>
      <el-table-column prop="mobile" :label="$t('phone')" min-width="150"></el-table-column>
      <el-table-column :label="$t('操作')" min-width="300">
        <template slot-scope="scope">
          <el-button type="success" size="mini" @click="deviceClick(scope.row)">{{ $t('device') }}</el-button>
          <!-- <el-button type="warning" size="mini" @click="statsClick(scope.row)">统计</el-button> -->
          <el-button type="warning" size="mini" @click="repleClick(scope.row)">{{ $t('Replenishment_records')
            }}</el-button>
          <el-button type="primary" size="mini" @click="editStaffClick(scope.row)">{{ $t('edit') }}</el-button>
          <el-button type="danger" size="mini" @click="deleteStaffClick(scope.row.id)">{{ $t('delete') }}</el-button>
        </template>
      </el-table-column>
    </el-table>




    <el-dialog :title="$t('Replenishment_records')" :visible.sync="reple.show" width="800px">
      <div class="block">
        <!-- <span class="demonstration">月</span> -->
        <el-date-picker v-model="reple.month" type="month" :placeholder="$t('Default_this_month')" clearable
          value-format="yyyy-MM" @change="repleDateChange">
        </el-date-picker>
      </div>
      <el-table :data="reple.list" style="margin-top:10px;" v-loading="reple.loading">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="device_name" :label="$t('device_name')" min-width="150"></el-table-column>
        <el-table-column prop="num" :label="$t('number')" min-width="150"></el-table-column>
        <el-table-column prop="detail" :label="$t('detail')" min-width="150"></el-table-column>
        <el-table-column prop="create_time" :label="$t('Replenishment_date')" min-width="150"></el-table-column>
      </el-table>
    </el-dialog>















    <el-dialog :title="staffForm.action == 'create' ? $t('add_staff') : $t('edit_staff')"
      :visible.sync="staffForm.visible" width="500px" @closed="staffFormClosed">
      <el-form :model="staffForm">
        <el-form-item :label="$t('name')" :label-width="staffForm.formLabelWidth">
          <el-input v-model="staffForm.name" :placeholder="$t('plz_name')" clearable></el-input>
        </el-form-item>
        <el-form-item :label="$t('phone')" :label-width="staffForm.formLabelWidth">
          <el-input v-model="staffForm.mobile" :placeholder="$t('plz_phone')" clearable></el-input>
        </el-form-item>
        <el-form-item :label-width="staffForm.formLabelWidth">
          <template slot="label">
            <span>{{ $t('password') }}</span>
            <span v-if="staffForm.action == 'update'">
              <el-tooltip :content="$t('password_modify')" placement="right">
                <i class="el-icon-info" style="color:#E6A23C"></i>
              </el-tooltip>
            </span>

          </template>
          <el-input v-model="staffForm.password"
            :placeholder="staffForm.action == 'create' ? $t('plz_newPassword') : $t('password_modify')"
            clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="staffForm.visible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="staffFormClick">{{ $t('save') }}</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="$t('statistics')" :visible.sync="stats.visible" width="700px" @closed="statsClosed">
      <el-tabs type="border-card" v-model="stats.activeName" @tab-click="changeTab">
        <!-- <el-tab-pane label="出货统计" name="fill">
          <div>
            <span>选择日期：</span>
            <el-date-picker v-model="stats.fill.date" type="month" placeholder="选择日期" value-format="yyyy-MM" :editable="false" :clearable="false" @change="fillDateChange"></el-date-picker>
          </div>
          <el-table :data="stats.fill.list" show-summary v-loading="stats.fill.loading" style="margin-top:10px;">
            <el-table-column type="index"></el-table-column>
            <el-table-column prop="date" label="日期" align="center"></el-table-column>
            <el-table-column prop="count" label="补货次数" align="center"></el-table-column>
          </el-table>
        </el-tab-pane> -->
        <el-tab-pane :label="$t('Revenue_statistics')" name="income">
          <el-table :data="stats.income.list" v-loading="stats.income.loading">
            <el-table-column type="index"></el-table-column>
            <el-table-column prop="date" :label="$t('date')"></el-table-column>
            <el-table-column :label="$t('income')">
              <template slot-scope="scope">
                ￥{{ scope.row.income }}
              </template>
            </el-table-column>
            <el-table-column prop="withdraw" :label="$t('Withdrawal')">
              <template slot-scope="scope">
                ￥{{ scope.row.withdraw }}
              </template>
            </el-table-column>
            <el-table-column prop="fee" :label="$t('commission')">
              <template slot-scope="scope">
                ￥{{ scope.row.fee }}
              </template>
            </el-table-column>
            <el-table-column prop="balance" :label="$t('Retained')">
              <template slot-scope="scope">
                ￥{{ scope.row.balance }}
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <!-- <el-tab-pane label="补货记录" name="shipment">
          <el-table :data="stats.shipment.list" v-loading="stats.shipment.loading">
            <el-table-column type="index"></el-table-column>
            <el-table-column prop="date" label="日期"></el-table-column>
            <el-table-column prop="income" label="收入"></el-table-column>
            <el-table-column prop="withdraw" label="提现"></el-table-column>
            <el-table-column prop="fee" label="手续费"></el-table-column>
            <el-table-column prop="balance" label="留存"></el-table-column>
          </el-table>
        </el-tab-pane> -->
        <el-tab-pane :label="$t('Revenue_detail')" name="record">
          <el-table :data="stats.record.list" v-loading="stats.record.loading">
            <el-table-column type="index"></el-table-column>
            <el-table-column :label="$t('type')">
              <template slot-scope="scope">
                {{ scope.row.type == 1 ? $t('refund') : scope.row.type == 2 ? $t('Withdrawal') : $t('income') }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('describe')">
              <template slot-scope="scope">
                {{ $t('device') }}{{ scope.row.order_extra.device.imei }}{{ $t('Generate order, total price') }}{{
        scope.row.order_amount }}
              </template>
            </el-table-column>

            <el-table-column prop="create_time" :label="$t('date')">
            </el-table-column>
            <el-table-column :label="$t('commission1')">
              <template slot-scope="scope">
                <span style="color:#00D268;">{{ scope.row.x_val >= 0 ? '+' : '' }}{{ scope.row.x_val }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <el-dialog :title="$t('Equipment_in_operation')" width="800px" :visible.sync="keeperDevice.visible">
      <div class="top-view">
        <el-button type="success" @click="createKeeperDeviceClick">{{ $t('add_device') }}</el-button>
        <el-input style="width:350px;margin-left: 20px;" :placeholder="$t('Please_enter_keywords_for_search')" v-model="keeperDevice.keyword" clearable
          @clear="keeperDeviceListSearch" @keyup.enter.native="keeperDeviceListSearch">
          <el-button slot="append" icon="el-icon-search" @click="keeperDeviceListSearch"></el-button>
        </el-input>
      </div>
      <!-- {{keeperDevice.list}} -->
      <el-table :data="keeperDevice.list" v-loading="keeperDevice.loading">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column property="device_imei" :label="$t('device')" min-width="150"></el-table-column>
        <el-table-column :label="$t('Commission_sharing_method')" min-width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.extra?.profit?.mode == 1 ? $t('Proportional_sharing') : $t('Fixed_amount') }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('add_staff')" min-width="100">
          <template slot-scope="scope">
            <div style="display:flex;flex-direction: column;">
              <div>{{$t('售卖')}}:{{ scope.row.extra?.profit?.mode == 1 ? (scope.row.extra?.profit?.val + '%') : ('¥' +
        scope.row.extra?.profit?.val) + $t('元') }}</div>
              <!-- <div>充电:{{scope.row.extra?.charge_profit?.mode == 1 ? (scope.row.extra?.charge_profit?.val + '%') : ('¥' + scope.row.extra?.charge_profit?.val) + '元'}}</div>
              <div>虚拟货道:{{scope.row.extra?.market_profit?.mode == 1 ? (scope.row.extra?.market_profit?.val + '%') : ('¥' + scope.row.extra?.market_profit?.val) + '元'}}</div> -->
            </div>

          </template>
        </el-table-column>
        <el-table-column :label="$t('操作')" min-width="200">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editKeeperDeviceClick(scope.row)">{{$t('编辑')}}</el-button>
            <el-button type="danger" size="mini" @click="deleteKeeperDeviceClick(scope.row)">{{$t('删除')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background layout="prev, pager, next" hide-on-single-page :current-page="keeperDevice.page"
        :page-size="keeperDevice.pagesize" :total="keeperDevice.total"
        @current-change="keeperDevicePageChange"></el-pagination>
    </el-dialog>
    <el-dialog :title="$t('Distribution_Equipment')" :visible.sync="assign.visible" width="1000px">
      <div class="choose-view">
        <div class="choose-item" v-for="(item, index) in assign.choose" :key="index">
          <span>{{ item.name }}</span>
          <i class="el-icon-error" @click="deleteChooseItem(index)"></i>
        </div>
      </div>
      <el-table :data="assign.list" style="margin-top: 10px;" v-loading="assign.loading">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column property="name" :label="$t('name')" min-width="150"></el-table-column>
        <el-table-column prop="imei" :label="$t('device_id')" min-width="160"></el-table-column>
        <el-table-column :label="$t('Venue')" min-width="200">
          <template slot-scope="scope">
            <span v-if="scope.row.zone">{{ scope.row.zone }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('select')" min-width="100">
          <template slot-scope="scope">
            <el-button type="success" size="mini" v-if="!scope.row.se" @click="chooseClick(scope.row)">{{ $t('select') }}</el-button>
            <el-button type="info" size="mini" plain v-else @click="cancelClick(scope.row)">{{ $t('cancel') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer">
        <el-button @click="assign.visible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="assignSubmitClick">{{ $t('submit') }}</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="$t('Parameter_settings')" :visible.sync="setting.visible">
      <el-tabs v-model="setting.tab">
        <el-tab-pane :label="$t('sell')" name="0"></el-tab-pane>
        <!-- <el-tab-pane label="充电" name="1"></el-tab-pane>
        <el-tab-pane label="虚拟货道" name="2"></el-tab-pane> -->
      </el-tabs>
      <el-form :model="setting.form" :label-width="setting.formLabelWidth">
        <div v-show="setting.tab == 0">
          <el-form-item>
            <template slot="label">
              {{ setting.array[setting.tab] }}{{ $t('Commission_sharing_method') }}
            </template>
            <el-radio-group v-model="setting.form.extra.profit.mode">
              <!-- <el-radio :label="0">{{ $t('Fixed_amount') }}</el-radio> -->
              <el-radio :label="1">{{ $t('Proportional_sharing') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('commission_rate')">
            <el-input v-model="setting.form.extra.profit.val" placeholder="" style="width:100px;">
              <span slot="suffix" style="padding: 0 10px;color: #000;width:100px;">{{ setting.form.mode == 0 ? '元' :
        '%' }}</span>
            </el-input>
          </el-form-item>
        </div>
        <div v-show="setting.tab == 1">
          <el-form-item>
            <template slot="label">
              {{ setting.array[setting.tab] }}{{ $t('Commission_sharing_method') }}
            </template>
            <el-radio-group v-model="setting.form.extra.charge_profit.mode">
              <el-radio :label="0">{{ $t('Fixed_amount') }}</el-radio>
              <el-radio :label="1">{{ $t('Proportional_sharing') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('commission_rate')">
            <el-input v-model="setting.form.extra.charge_profit.val" placeholder="" style="width:100px;">
              <span slot="suffix" style="padding: 0 10px;color: #000;width:100px;">{{ setting.form.mode == 0 ? $t('元') :
        '%' }}</span>
            </el-input>
          </el-form-item>
        </div>
        <div v-show="setting.tab == 2">
          <el-form-item>
            <template slot="label">
              {{ setting.array[setting.tab] }}{{ $t('Commission_sharing_method') }}
            </template>
            <el-radio-group v-model="setting.form.extra.market_profit.mode">
              <el-radio :label="0">{{ $t('Fixed_amount') }}</el-radio>
              <el-radio :label="1">{{ $t('Proportional_sharing') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('commission_rate')">
            <el-input v-model="setting.form.extra.market_profit.val" placeholder="" style="width:100px;">
              <span slot="suffix" style="padding: 0 10px;color: #000;width:100px;">{{ setting.form.mode == 0 ? $t('元') :
        '%' }}</span>
            </el-input>
          </el-form-item>
        </div>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="setting.visible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="settingSaveClick">{{ $t('save') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "staff",
  data() {
    return {


      reple: {
        id: '',
        month: '',
        show: false,
        loading: false,
        list: [],
      },





      staff: {
        list: [],
        loading: false,
        keyword: ''
      },
      staffForm: {
        visible: false,
        dialogTitle: "",
        formLabelWidth: "120px",
        id: null,
        name: "",
        mobile: "",
        action: 'create',
        password: '',
      },
      keeperDevice: {
        visible: false,
        list: [],
        keeperid: 0
      },
      setting: {
        form: {
          way: '',
          type: '',
          commission: '',
          kind: '',
          mode: 0,
          val: '',
          extra: {
            profit: {
              mode: 1,
              val: 0
            },
            charge_profit: {
              mode: 0,
              val: 0
            },
            market_profit: {
              mode: 0,
              val: 0
            }
          }
        },
        array: [this.$tt.t('售卖'), this.$tt.t('充电'), this.$tt.t('虚拟货道')],
        visible: false,
        tab: '0',
        formLabelWidth: '',
      },
      assign: {
        visible: false,
        list: [],
        page: 1,
        total: 0,
        activeName: 'device',
        choose: []
      },
      device: {

      },
      stats: {
        visible: false,
        activeName: 'fill',
        fill: {
          date: '',
          list: [],
          page: 1,
          loading: false,
        },
        income: {
          list: [],
          loading: false,
        },
        shipment: {
          list: [],
          loading: false,
        },
        record: {
          list: [],
          loading: false,
        }
      },
      currentId: 0
    };
  },
  created() {
    this.getStaffList();
  },
  methods: {
    repleClick(row) {
      // console.log("🚀 ~ repleClick ~ month:", this.reple.month)

      let id = row !== undefined ? row.id : this.reple.id;
      // console.log("🚀 ~ repleClick ~ row:", row)
      if (row !== undefined) {
        this.reple.id = row.id;
      }


      console.log("🚀 ~ repleClick ~ this.reple.id:", this.reple.id)
      // this.reple.id = row.id;

      this.reple.show = true;

      this.reple.loading = true;

      this.$get('/api/keeper/keeperReple', {
        id: id,
        month: this.reple.month
      }).then(res => {
        // console.log("🚀 ~ repleClick ~ res:", res)
        this.reple.loading = false;
        this.reple.list = res;
      }).catch(error => {

      });
    },
    repleDateChange() {
      this.repleClick()
    },









    getStaffList() {
      this.$get('/api/agent/keeperList', {
        keyword: this.staff.keyword
      }).then(res => {
        console.log('res--', res);
        this.staff.list = res.data;
      });
    },
    getDeviceList() {
      this.$get('/api/agent/keeperList').then(res => {
        this.staff.list = res.data;
      });
    },
    createStaffClick() {
      this.staffForm.action = "create";
      this.staffForm.visible = true;
    },
    editStaffClick(item) {
      this.staffForm.action = "update";
      this.staffForm.visible = true;
      this.staffForm.id = item.id;
      this.staffForm.name = item.name;
      this.staffForm.mobile = item.mobile;
      this.staffForm.password = '';
    },
    staffFormClick() {
      if (!this.staffForm.name) {
        this.$message({
          message: $t("请输入姓名"),
          type: "warning",
          center: true
        });
        return;
      } else if (!this.staffForm.mobile) {
        this.$message({
          message: $t("请输入手机号码"),
          type: "warning",
          center: true
        });
        return;
      } else if (this.staffForm.action == 'create' && !this.staffForm.password) {
        this.$message({
          message: $t("请输入初始密码"),
          type: "warning",
          center: true
        });
        return;
      }

      if (this.staffForm.action == 'create') {
        this.$post('/api/agent/addKeeper', {
          mobile: this.staffForm.mobile,
          name: this.staffForm.name,
          password: this.staffForm.password,
        }).then(res => {
          this.$message({
            message: $t('操作成功'),
            type: "success",
            center: true
          });
          this.staffForm.visible = false;
          this.getStaffList();
        }).catch(err => { })
      } else {
        let param = {
          mobile: this.staffForm.mobile,
          name: this.staffForm.name,
          id: this.staffForm.id
        };
        if (this.staffForm.password != '') {
          param.password = this.staffForm.password;
        }
        this.$post('/api/agent/editKeeper', {
          mobile: this.staffForm.mobile,
          name: this.staffForm.name,
          password: this.staffForm.password,
          id: this.staffForm.id
        }).then(res => {
          this.$message({
            message: $t('操作成功'),
            type: "success",
            center: true
          });
          this.staffForm.visible = false;
          this.getStaffList();
        })
      }

    },
    staffFormClosed() {
      this.staffForm.id = null;
      this.staffForm.name = "";
      this.staffForm.mobile = "";
    },
    deleteStaffClick(id) {
      this.$confirm($t("是否删除该运营人员?"), $t("提示"), {
        confirmButtonText: $t("确定"),
        cancelButtonText:  $t("取消"),
        type: "warning"
      })
        .then(() => {
          this.deleteStaff(id);
        })
        .catch(() => { });
    },
    deleteStaff(id) {
      let params = {
        op: "deleteKeeper",
        id
      };
      this.$api.staff.deleteKeeper(params).then(res => {
        if (res.data.status) {
          this.$message({
            message: res.data.data.msg,
            type: "success",
            center: true
          });
          this.getStaffList();
        } else {
          this.$alert(res.data.data.msg, $t("提示"));
        }
      });
    },
    statsClick(item) {

      this.stats.visible = true;
      this.stats.activeName = "income";
      this.stats.user = item;
      // this.stats.fill.date = this.$moment().format("YYYY-MM");
      this.getFillStat(item.id); // 出货统计
      this.getMonthStat(item.id); // 收入统计
    },
    fillDateChange() {
      this.getFillStat();
    },
    changeTab() {
      console.log('stats.activeName', this.stats.activeName);
      if (this.stats.activeName == 'fill') {
        this.getFillStat();
      } else if (this.stats.activeName == 'income') {
        this.getMonthStat();
      } else if (this.stats.activeName == 'record') {
        console.log('this.stats.activeName', this.stats.activeName);
        this.getRecordList();
      }
    },
    getFillStat(id) {
      this.stats.fill.loading = true;
      this.$get('/api/keeper/order', {
        page: this.stats.fill.page,
        simple: true,
        month: this.stats.fill.date,
        target_id: id
      }).then(res => {
        this.stats.fill.list = res.data;
      }).finally(res => {
        this.stats.fill.loading = false;
      })
    },
    getRecordList() {
      // this.stats.record.list = [
      //   {
      //     type:1,
      //     order_extra:{
      //       device:{
      //         imei:'2024012323'
      //       }
      //     },
      //     order_amount:100,
      //     create_time:'2024-01-23',
      //     x_val:0.1,
      //   },
      // ];
      console.log('getRecordList');
      this.stats.record.loading = true;
      this.$get('/api/keeper/balanceDetail', {
        page: this.stats.record.page
      }).then(res => {
        this.stats.record.list = res.data;
      }).finally(res => {
        this.stats.record.loading = false;
      })
    },
    getMonthStat(id) { // 收入统计
      this.stats.income.loading = true;
      this.$get('/api/keeper/profit', {
        page: this.stats.income.page,
        target_id: id
      }).then(res => {
        this.stats.income.list = res.data;
      }).finally(res => {
        this.stats.income.loading = false;
      });
    },
    statsClosed() {
      this.stats.user = null;
      this.stats.fill = {
        date: null,
        list: [],
        loading: false
      };
      this.stats.income = {
        list: [],
        loading: false
      };
    },
    deviceClick(item) {
      console.log('deviceClick--', item);
      // this.keeperDevice.keeperid = item.id;
      this.keeperDevice.visible = true;
      this.keeperDevice.keeperid = item.id;
      this.currentId = item.id;
      this.getKeeperDeviceList(item.id);
    },
    keeperDeviceListSearch() {
      this.keeperDevice.page = 1;
      this.getKeeperDeviceList();
    },
    getKeeperDeviceList() {
      console.log('getKeeperDeviceList');
      this.$get('/api/agent/keeperDeviceList', {
        keeper_id: this.keeperDevice.keeperid
      }).then(res => {
        console.log('res---', res.data);
        this.keeperDevice.list = res.data;
      })
    },
    keeperDevicePageChange(val) {
      this.keeperDevice.page = val;
      this.getKeeperDeviceList();
    },
    editKeeperDeviceClick(item) {
      this.setting.visible = true;
      console.log('editKeeperDeviceClick--', item);
      this.setting.form = {

        currentId: item.id,
        mode: item.extra?.profit?.mode ? item.extra.profit.mode : 0,
        val: item.extra?.profit?.val ? item.extra.profit.val : 0,
        currentDeviceId: item.device_id,
        extra: {
          profit: {
            mode: item?.extra?.profit?.mode ? item.extra.profit.mode : 0,
            val: item?.extra?.profit?.val ? item.extra.profit.val : 0
          },
          charge_profit: {
            mode: item.extra?.charge_profit?.mode ? item.extra.charge_profit.mode : 0,
            val: item.extra?.charge_profit?.val ? item.extra.charge_profit.val : 0
          },
          market_profit: {
            mode: item.extra?.market_profit?.mode ? item.extra.market_profit.mode : 0,
            val: item.extra?.market_profit?.val ? item.extra.market_profit.val : 0
          }
        }
      };


    },
    deleteKeeperDeviceClick(item) {
      this.$confirm($t("是否删除该设备?"), $t("提示"), {
        confirmButtonText: $t("确定"),
        cancelButtonText: $t("取消"),
        type: "warning"
      })
        .then(() => {
          this.deleteKeeperDevice(item.device_id);
        })
        .catch(() => { });
    },
    deleteKeeperDevice(id) {
      this.$post('/api/agent/unbindKeeperDevice', {
        keeper_id: this.currentId,
        device_id: id
      }).then(res => {
        this.getKeeperDeviceList();
      })
    },
    createKeeperDeviceClick() {
      this.assign.visible = true;
      this.assign.loading = false;
      this.assign.list = [];
      this.assign.page = 1;
      this.assign.total = 0;
      this.assign.activeName = 'device';
      this.assign.choose = [];
      this.getDeviceList();
    },
    assignSearch() {
      this.assign.page = 1;
      this.getAssignList();
    },
    getDeviceList() { // 代理商下级设备
      // let userInfo = sessionStorage.getItem('agent_id');
      this.$get('/api/device/list').then(res => {
        console.log('getDeviceList', res);
        this.assign.list = res.data;
      })
    },
    getAssignList() {
      this.$get('/api/agent/list').then(res => {
        console.log('res--', res.data);
        this.assign.list = res.data;
      });
      // this.assign.loading = true;
      // let params = {
      //   op: this.assign.activeName + "List",
      //   page: this.assign.page,
      //   pagesize: this.assign.pagesize,
      //   keyword: this.assign.keyword
      // };
      // this.$api.staff.getAssignList(params).then(res => {
      //   this.assign.loading = false;
      //   if (res.data.status) {
      //     let tmpList = res.data.data.list;
      //     tmpList.map(e => {
      //       const index = this.assign.choose.findIndex(el => {
      //         if (this.assign.activeName == 'device') {
      //           return e.device.id == el.device.id
      //         } else {
      //           return e.id == el.id
      //         }
      //       })
      //       if (index != -1) {
      //         e.se = true;
      //       } else {
      //         e.se = false;
      //       }
      //       return e;
      //     })
      //     this.assign.list = tmpList;
      //     this.assign.total = res.data.data.total;
      //   } else {
      //     this.$alert(res.data.data.msg, "提示");
      //   }
      // })
      //   .catch(() => {
      //     this.assign.loading = false;
      //   });
    },
    assignTabClick(e) {
      if (e.index == 0) {
        this.assign.activeName = 'device';
      } else {
        this.assign.activeName = 'group';
      }
      this.assign.page = 1;
      this.assign.choose = [];
      this.assign.keyword = '';
      this.getAssignList();
    },
    assignPageChange(val) {
      this.assign.page = val;
      this.getAssignList();
    },
    chooseClick(item) {
      item.se = true;
      console.log('item', item);
      this.assign.choose.push(item);
    },
    cancelClick(item) {
      item.se = false;
      const index = this.assign.choose.findIndex(e => {
        if (this.assign.activeName == 'device') {
          return e.device.id == item.device.id
        } else {
          return e.id == item.id
        }
      })
      if (index != -1) {
        this.assign.choose.splice(index, 1);
      }
    },
    deleteChooseItem(index) {
      const item = this.assign.choose[index];
      const i = this.assign.list.findIndex(e => {
        return e.id == item.id
      })
      console.log('i--', i);
      if (i != -1) {
        this.assign.list[i].se = false;
      }
      this.assign.choose.splice(index, 1);
    },
    assignSubmitClick() {
      if (this.assign.choose.length == 0) {
        this.$message({
          message: $t('请选择') + (this.assign.activeName == 'device' ? $t('设备') : $t('分组')),
          type: "warning",
          center: true
        });
      } else {
        this.setting.form = {
          keeperid: this.keeperDevice.keeperid,
          way: 0,
          type: 1,
          commission: '',
          kind: false,
          currentDeviceId: null,
          extra: {
            profit: {
              mode: 0,
              val: 0
            },
            charge_profit: {
              mode: 0,
              val: 0
            },
            market_profit: {
              mode: 0,
              val: 0
            }
          }
        }
        this.setting.visible = true;

      }
    },
    async settingSaveClick() {
      // if (!this.setting.form.val) {
      //   this.$message({
      //     message: '请输入佣金比例',
      //     type: "warning",
      //     center: true
      //   });
      //   return
      // }
      // if (this.assign.visible) {
      //   this.assign.choose.forEach((e, i) => {
      //       params['devices[' + i + ']'] = e.device.id;
      //   });
      // } else {
      //   params['devices[0]'] = this.setting.form.currentDeviceId;
      // }
      // console.log('settingSaveClick this.assign.choose',this.assign.choose);
      // return;
      if (this.assign.visible) { // 分配设备
        let device_ids = this.assign.choose.map(res => {
          return res.id;
        })
        if (device_ids.length == 0) {
          this.$message({ message: $t('未选择设备'), type: 'warning' });
          return;
        }

        let param = {
          keeper_id: this.keeperDevice.keeperid,
          extra: {
            profit: {
              val: this.setting.form.extra.profit.val,
              mode: this.setting.form.extra.profit.mode
            },
            charge_profit: {
              val: this.setting.form.extra.charge_profit.val,
              mode: this.setting.form.extra.charge_profit.mode,
            },
            market_profit: {
              mode: this.setting.form.extra.market_profit.mode,
              val: this.setting.form.extra.market_profit.val,
            }
          },
          device_ids: device_ids
        };

        await this.$post('/api/agent/batchKeeperSettings', param).then(res => {
          this.$message({ message: $t('设置成功'), type: 'success' });
        }).catch(res => { });
        // console.log('device_ids',device_ids);return;
        // console.log('this.setting.form',this.setting.form);return;
      } else {
        let param = {
          id: this.setting.form.currentId,
          extra: {
            profit: {
              val: this.setting.form.extra.profit.val,
              mode: this.setting.form.extra.profit.mode
            },
            charge_profit: {
              val: this.setting.form.extra.charge_profit.val,
              mode: this.setting.form.extra.charge_profit.mode,
            },
            market_profit: {
              mode: this.setting.form.extra.market_profit.mode,
              val: this.setting.form.extra.market_profit.val,
            }
          }
        };

        await this.$post('/api/agent/editKeeperDevice', param).then(res => {
          this.$message({ message: $t('设置成功'), type: 'success' });
        }).catch(res => { });

      }
      this.setting.visible = false;
      this.assign.visible = false;
      this.getKeeperDeviceList();

      // console.log('bindList',bindList);

      // this.$api.staff.assignDevicesToKeeper(params).then(res => {
      //   if (res.data.status) {
      //     this.$message({
      //       message: res.data.data.msg,
      //       type: "success",
      //       center: true
      //     });
      //     this.setting.visible = false;
      //     if (this.assign.visible) {
      //         this.assign.visible = false;
      //     }
      //     this.getKeeperDeviceList();
      //   } else {
      //     this.$alert(res.data.data.msg, "提示");
      //   }
      // });
    }
  }
};
</script>

<style lang="scss" scoped>
.top-view {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.el-icon-sort {
  cursor: pointer;
}

.el-icon-sort:hover {
  opacity: 0.7;
}

.group-text {
  padding: 5px 10px;
  color: #fff;
}

.clr-view {
  width: 20px;
  height: 20px;
}

.clr-view:hover {
  opacity: 0.7;
}

.choose-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .choose-item {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin-left: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .el-icon-error {
      margin-left: 10px;
      color: #ccc;
      font-size: 14px;
      cursor: pointer;
    }

    .el-icon-error:hover {
      color: #9d9d9d;
    }
  }
}
</style>