<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'app',
}
</script>

<style>
body {
  color: #515a6e;
  font-size: 18px;
  padding: 0;
  margin: 0;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  width: 100vw;
  height: 100vh;
}

.content-view {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 5px;
}

.top-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.el-pagination {
  margin-top: 20px;
  text-align: end;
}
</style>
